<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h3 class="display-2 font-weight-bold">Feature Flags</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center justify-center"  xs="12" sm="12" md="12" lg="6">
        <v-row>
          <v-col xs="12" sm="12" md="12" lg="6">

          </v-col>
          <v-col xs="12" sm="12" md="12" lg="6">
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex align-center justify-center" xs="12" sm="12" md="12" lg="6">
        <v-row>
          <v-col cols="6" class="d-flex align-center">

          </v-col>
          <v-col cols="6" class="d-flex align-center justify-end">

            <turf-btn
              @click="showAddFlagFormModal = true"
              :dark="true"
              :dense="true"
              class="sort-button"
              actionBtn
            >
              <v-icon small>mdi-plus</v-icon>
              New Feature Flag
            </turf-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

      <v-list three-line dark class="text-left">
        <template v-for="(feature, idx) in features">
          <v-list-item :key="feature.id+'-'+idx">
            <v-list-item-content>
              <v-list-item-title>{{feature.name}}</v-list-item-title>
              <v-list-item-subtitle>{{feature.description}}</v-list-item-subtitle>
            </v-list-item-content>
            <turf-btn @click="onSave(feature.id)">Save Changes</turf-btn>
          </v-list-item>

          <v-card
            outlined
            :key="feature.id"
            v-if="model[feature.id].value"
          >
            <v-card-title>
              Select one or more customers
            </v-card-title>
            <v-card-subtitle class="d-flex align-center">
              Select All
              <v-checkbox class="ml-3" @change="onSelectAllCustomers($event, feature.id)"></v-checkbox>
              <v-spacer />
            </v-card-subtitle>
            <v-card-text>
              <v-list
                max-height="400"
                class="overflow-y-auto"
              >
                <v-list-item-group
                  v-model="model[feature.id].customers"
                  active-class="primary"
                  multiple
                  v-if="customers && customers.length"
                >
                  <template v-for="(customer, index) in customers">
                    <v-list-item :key="customer.id" :value="customer.id">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-text="customer.name || customer.id"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < customers.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </template>
      </v-list>
      <v-dialog persistent max-width="700" v-model="showAddFlagFormModal">
        <feature-flag-form
          @onCancel="showAddFlagFormModal = false"
          @onSubmit="handleAddFlagFormSubmit"
      ></feature-flag-form>
      </v-dialog>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import { mapGetters, mapState } from 'vuex';
import camelCase from 'camelcase';
import FeatureFlagForm from '@/components/FeatureFlagForm.vue';

export default {
  name: 'FeatureSettings',
  components: {
    FeatureFlagForm
  },
  data: () => ({
    model: {},
    showAddFlagFormModal: false,
  }),
  computed: {
    ...mapGetters(['features', 'customers']),
    ...mapState(['auth']),
    disableSaveBtn() {
      return (id) => {
        const curCust = this.model[id];
        const prevCust = this.features.find(f => f.id === id);
        
        const isEqual = Array.isArray(curCust.customers) && 
                        Array.isArray(prevCust.customers) &&
                        curCust.customers.length === prevCust.customers.length &&
                        curCust.customers.every((val, index) => val === prevCust.customers[index]);

        // toggle active
        if (curCust && curCust.value !== curCust.value) {
          if (!curCust.customers.length && !prevCust.customers.length) {
            return true;
          }
          return !(curCust.value !== prevCust.value);
        // customer selected?
        } else if (!curCust.customers.length) {
          return true;
        // customer selection changed?
        } else {
          return isEqual;
        }
      }
    }
  },
  watch: {
    features(cur) {
      
      if (cur && cur.length) {
        this.model = cur.reduce(function(o, val) { o[val.id] = val; return JSON.parse(JSON.stringify(o)) }, {})
      }
    }
  },
  async created() {
    try {
      await this.$store.dispatch('GET_MEETING_MANAGER_FEATURE_FLAGS');
      await this.$store.dispatch('GET_CUSTOMERS');
    } catch (err) {
      this.$store.dispatch('TOAST_ERROR', {
        message: 'Unable to retrieve data'
      });
    }
  },
  methods: {
    async onSave(featureId) {
      const triggered_by = this.auth && this.auth.user && this.auth.user.email;
      const last_triggered = firebase.firestore.Timestamp.now();
      
      if (!this.model[featureId].value) {
        this.model[featureId].customers = [];
      } else {
        this.model[featureId] = {
          ...this.model[featureId],
          ...{
            triggered_by,
            last_triggered
          }
        };
      }

      try {
        await this.$store.dispatch('SET_FEATURE_FLAG', {
          featureId,
          feature: this.model[featureId]
        });
      } catch(err) {
        console.error(err);
      }
    },
    async handleAddFlagFormSubmit(formData) {
      try {
        let data = {
          value: true,
          triggered_by: this.auth && this.auth.user && this.auth.user.email,
          name: formData.name,
          last_triggered: firebase.firestore.Timestamp.now(),
          description: formData.description,
          id: camelCase(formData.name)
        }
        await this.$store.dispatch('ADD_FEATURE_FLAG', {
          feature: data
        });
      } catch(err) {
        console.error(err);
      } finally {
        this.showAddFlagFormModal = false;
      }
    },
    onSelectAllCustomers(active, featureId) {
      if (active) {
        const customerIds = this.customers.map(customer => customer.id);
        this.model[featureId].customers = customerIds;
      } else {
        this.model[featureId].customers = [];
      }
    },
    onRevertChange(featureId) {
      const customersEnabled = this.features.find(f => f.id).customers;
      this.model[featureId].customers = customersEnabled;
    }
  }
};
</script>

<style></style>
