<template>
  <v-card flat dark>
    <v-col>
      <v-row class="d-flex justify-space-between px-4">
        <h2>Customers</h2>
        <turf-btn small @click="showCustomerForm">
          <v-icon>
            mdi-plus
          </v-icon>
        </turf-btn>
      </v-row>
      <v-data-table dark :headers="headers" :items="tableData">
        <template v-slot:item.options="{ item }">
          <div>
            <turf-btn class="mr-2" small @click="showCustomerForm(item)">
              <v-icon>
                mdi-pencil-outline
              </v-icon>
            </turf-btn>
            <turf-btn small @click="deleteRecord(item.id)">
              <v-icon>
                mdi-close
              </v-icon>
            </turf-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog width="500" v-model="showForm">
        <v-card dark class="py-4">
          <v-card-title primary-title>
            {{ formTitle }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Customer Name"
              v-model="customerName"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="mx-4 d-flex justify-end">
            <turf-btn class="mr-2" text @click="showForm = false">
              Cancel
            </turf-btn>
            <turf-btn color="success" :disabled="!customerName" @click="handleSubmit">
              Publish
            </turf-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="500" v-model="showDeleteConfirmation">
        <v-card dark>
          <v-card-title primary-title>
            Permanently Delete This Mapping?
          </v-card-title>
          <v-card-text>
            If you delete a customer record, you are also erasing any associated Calendar Data.
            Deleting a customer permanently destroys the record.
          </v-card-text>
          <v-card-actions class="mx-4 d-flex justify-end">
            <turf-btn
              text
              class="mr-2"
              @click="showDeleteConfirmation = false"
            >
              Cancel
            </turf-btn>
            <turf-btn color="warning" @click="handleDeleteRecord">
              Delete
            </turf-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-card>
</template>

<script>
import adminControls from '../service/adminControls.js';
export default {
  data() {
    return {
      headers: [
        {
          value: 'id',
          align: ' d-none'
        },
        {
          text: 'Customer',
          value: 'customerName'
        },
        {
          text: 'Options',
          value: 'options',
          align: 'right'
        }
      ],
      customers: [],
      customerName: '',
      showForm: false,
      showDeleteConfirmation: false,
      selectedDocId: null
    };
  },
  firestore() {
    return {
      customers: this.$firestore.collection('customers')
    };
  },
  computed: {
    formTitle() {
      return this.selectedDocId ? 'Edit Customer' : 'Create New Customer';
    },
    tableData() {
      return this.customers.map(doc => {
        return {
          customerName: doc.name || '',
          id: doc.id
        };
      });
    }
  },
  methods: {
    handleSubmit() {
      if (this.selectedDocId) {
        adminControls.editCustomer(this.selectedDocId, {
          name: this.customerName
        });
        this.showForm = false;
      } else {
        adminControls.createCustomer({
          customerName: this.customerName
        });
        this.showForm = false;
      }
    },
    showCustomerForm(data) {
      if (data.id && data.customerName) {
        this.selectedDocId = data.id;
        this.customerName = data.customerName;
      } else {
        this.selectedDocId = null;
        this.customerName = '';
      }
      this.showForm = true;
    },
    deleteRecord(id) {
      this.showDeleteConfirmation = true;
      this.selectedDocId = id;
    },
    handleDeleteRecord() {
      adminControls.deleteCustomer(this.selectedDocId);
      this.showDeleteConfirmation = false;
    }
  }
};
</script>

<style></style>
