import firebase from 'firebase/app';
import 'firebase/firestore';
import store from '@/store';

async function deleteCustomerSubCollection(collectionPath, batchSize = 50) {
  const db = firebase.firestore();
  const customer = store.getters.getCustomerId;
  const collectionRef = db
    .collection('customers')
    .doc(customer)
    .collection(collectionPath);
  const query = collectionRef.orderBy('__name__').limit(batchSize);

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, resolve).catch(reject);
  });
}

async function deleteQueryBatch(db, query, resolve) {
  const snapshot = await query.get();

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    // When there are no documents left, we are done
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = db.batch();
  snapshot.docs.forEach(doc => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  // Recurse on the next process tick, to avoid
  // exploding the stack.
  process.nextTick(() => {
    deleteQueryBatch(db, query, resolve);
  });
}

async function batchUpdateUserCollection(
  customerUserCollection,
  formattedRecordArray = []
) {
  const db = firebase.firestore();
  let batch;
  if (formattedRecordArray.length < 50) {
    batch = db.batch();
    formattedRecordArray.forEach(record => {
      const encodedEmail = btoa(record.email);
      const ref = customerUserCollection.doc(encodedEmail);
      batch.set(ref, { ...record });
    });
    return batch.commit();
  }
  const chunk = formattedRecordArray.splice(50);
  batch = db.batch();
  chunk.forEach(record => {
    const encodedEmail = btoa(record.email);
    const ref = customerUserCollection.doc(encodedEmail);
    batch.set(ref, { ...record });
  });
  await batch.commit();
  return batchUpdateUserCollection(customerUserCollection, formattedRecordArray);
}

async function softDeleteUserCollection(refArray = []) {
  const db = firebase.firestore();
  let batch;
  if (refArray.length < 50) {
    batch = db.batch();
    refArray.forEach(ref => {
      batch.update(ref, { isActive: false })
    })
    return batch.commit()
  }
  const chunk = refArray.splice(50);
  batch = db.batch();
  chunk.forEach(ref => {
    batch.update(ref, { isActive: false });
  });
  await batch.commit();
  return softDeleteUserCollection(refArray)
}

async function bulkdUnassignDevices(deviceIds = [], customerId = '') {
  const db = firebase.firestore();
  let batch = db.batch();
  if (!customerId) return new Error('Customer Id Missing')
  deviceIds.forEach(async id => {
    let ref = db
      .collection('customers')
      .doc(customerId)
      .collection('displays')
      .doc(id)

    batch.update(ref, { space_id: '', appType: '' })
  })

  await batch.commit()
}

export { 
  deleteCustomerSubCollection, 
  batchUpdateUserCollection, 
  softDeleteUserCollection, 
  bulkdUnassignDevices, 
};
