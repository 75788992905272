const defaultState = () => ({
  allSpaces: [],
  allDevices: [],
  assignedDevices: []
});


const spaces = {
  state: defaultState(),
  mutations: {
    SET_ALL_SPACES: (state, value) => {
      state.allSpaces = value.spaces;
    },
    SET_ALL_DEVICES: (state, value) => {
      state.allDevices = value.devices;
    },
    SET_ASSIGNED_DEVICES: (state, assignedDevices) => {
      state.assignedDevices = assignedDevices;
    },
    UPDATE_SPACE_DEVICES: (state, value) => {
      const space = state.allSpaces.find(x => x.spaceId === value.spaceId);
      space.devices = [...space.devices, ...value.deviceIds];
    },
    REMOVE_SPACE_DEVICE: (state, value) => {
      const space = state.allSpaces.find(x => x.spaceId === value.spaceId);
      space.devices = space.devices.filter(x => x !== value.deviceId);
    },
    RESET_STATE: (state) => {
      Object.assign(state, defaultState());
    }
  },
  actions: {
    createAssignedDevices({ commit, state }) {
      const spaces = [...state.allSpaces];
      const devicesIds = [...state.allDevices].map(x => x.deviceId);

      let assignedDevices = {};
      spaces.forEach(space => {
        if (!space.devices.length) return;
        space.devices.forEach(device => {
          assignedDevices[device] = space.spaceId;
        });
      });

      devicesIds.forEach(deviceId => {
        const assignedDeviceIds = Object.keys(assignedDevices);
        if (assignedDeviceIds.indexOf(deviceId) === -1) {
          assignedDevices[deviceId] = "Unassigned";
        } else {
          return;
        }
      });

      commit("SET_ASSIGNED_DEVICES", assignedDevices);
    },
    async addDisplayToSpace(
      { commit, dispatch },
      { spaceId = "", deviceIds = [] }
    ) {
      await commit("UPDATE_SPACE_DEVICES", { spaceId, deviceIds });
      await dispatch("createAssignedDevices");
    },
    async deleteDisplayFromSpace(
      { commit, dispatch },
      { spaceId = "", deviceId = "" }
    ) {
      await commit("REMOVE_SPACE_DEVICE", { spaceId, deviceId });
      await dispatch("createAssignedDevices");
    }
  },
  getters: {
    getAllSpaces: state => state.allSpaces,
    getSpaceBySpaceId: state => id =>
      state.allSpaces.find(x => x.spaceId === id),
    getAllDevices: state => state.allDevices,
    getDevicesBySpaceId: (state, getters) => spaceId => {
      const space = getters.getSpaceBySpaceId(spaceId);
      return space.devices;
    },
    getDeviceAssignment: state => deviceId => state.assignedDevices[deviceId],
    getUnassignedDevices: state =>
      Object.keys(state.assignedDevices).filter(
        deviceId => state.assignedDevices[deviceId] === "Unassigned"
      )
  }
};

export default spaces;
