<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h3 class="display-2 font-weight-bold">Directory</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <sorting-controls
            :items="sortOptions"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            @setSortOption="setSortOption"
          ></sorting-controls>
         <div class="d-flex align-center">
          <v-switch
            dark
            v-if="isLinked"
            class="mr-5"
            v-model="autoSyncVal"
            :value="autoSyncUpdate"
            @change="onAutoSyncChange"
            label="Enable Auto Sync"
          ></v-switch>
          <turf-btn actionBtn @click="handleUploadClick" class="mr-4">Upload List CSV</turf-btn>
        </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dark
          :loading="pageLoading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :headers="headers"
          :items="getAllEmployees"
          :items-per-page="minItemsLength"
          class="elevation-1"
          :style="{backgroundColor: 'transparent !important'}"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options':[minItemsLength],
            'show-current-page': true,
            ...footerPropsObj
          }"
        >
        <template #footer.prepend>
          <v-spacer/>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <v-dialog width="700" v-model="showUploadDialog">
      <v-card dark>
        <v-card-title primary-title class="ml-5 d-flex flex-column align-start pt-6 pb-0">
          <h3>Upload CSV</h3>
          <p class="caption text-wrap"><strong>Note:</strong> Please upload mobile phone numbers only in order for employees to receive text notifications</p>
        </v-card-title>
        <v-card-text>
          <UploadUsersCSV @onClose="onUploadClose" :sync-directory-enabled="getCustomerData && getCustomerData.sync_directory" @onComplete="onUploadComplete"></UploadUsersCSV>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="autoSyncDialog"
      max-width="800"
    >
      <v-card dark>
        <v-card-title>
          <span><b>Enable</b> directory auto-sync</span>
        </v-card-title>
        <v-card-text>
          Directory auto-sync allows Skykit Turf to automatically receive updates from your company's directory daily. <br> 
          <b>Note:</b> If you have manually uploaded a directory to Skykit Turf, directory auto-sync will override this.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="autoSyncDialog = false, autoSyncVal = false"
          >
            Cancel
          </v-btn>
          <turf-btn
            color="primary"
            @click="onSyncDirectory"
          >
            Enable Auto-Sync
          </turf-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="cancelAutoSyncDialog"
      max-width="700"
    >
      <v-card dark>
        <v-card-title>
          <span>Are you sure you want to <b>disable</b> the directory auto-sync?</span>
        </v-card-title>
        <v-card-text>
          <span><b>Disabling</b> directory auto-sync will remove Skykit Turf’s ability to receive updates from your company's directory </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="cancelAutoSyncDialog = false, autoSyncVal = true"
          >
            Cancel
          </v-btn>
          <turf-btn
            color="primary"
            @click="onSyncDirectory"
          >
            Disable Auto-Sync
          </turf-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="showUploadDialog2"
      max-width="700"
    >
      <v-card dark>
        <v-card-title>
          Are you sure you want to upload a CSV?
        </v-card-title>
        <v-card-text>
          <span>Uploading a CSV will <b>disable</b> the directory auto-sync and override the current directory in Skykit Turf.</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="showUploadDialog2 = false"
          >
            Cancel
          </v-btn>
          <turf-btn
            color="primary"
            @click="showUploadDialog2 = false, showUploadDialog = true"
          >
            Upload CSV
          </turf-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import UploadUsersCSV from '@/components/UploadUsersCSV';
import adminControls from '@/service/adminControls.js';
import SortingControls from '@/components/SortingControls.vue';

export default {
  name: 'CheckIn',
  components: {
    UploadUsersCSV,
    SortingControls
  },
  data() {
    return {
      tab: 'Employees',
      items: [{ tab: 'Employees' }],
      showUploadDialog: false,
      autoSyncVal: false,
      autoSyncDialog: false,
      cancelAutoSyncDialog: false,
      showUploadDialog2: false,
      sortBy: 'email',
      sortDesc: false,
      options: {},
      pageLoaded: {},
      headers: [
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email'
        },
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName'
        },
        {
          text: 'Middle Name',
          align: 'start',
          sortable: false,
          value: 'middleName'
        },
        {
          text: 'Last Name',
          align: 'start',
          sortable: false,
          value: 'lastName'
        },
        {
          text: 'Phone Number',
          align: 'start',
          sortable: false,
          value: 'phoneNumber'
        }
      ],
      minItemsLength: 50
    };
  },
  computed: {
    ...mapGetters(['getAllEmployees', 'getCustomerData', 'pageLoading']),
    autoSyncUpdate() {
      return get(this.getCustomerData, 'settings.booking.sync_directory')
    },
    isLinked() {
      return this.isDirectoryLinked()
    },
    sortOptions() {
      return this.headers.reduce((options, header) => {
        const { text, value } = header
        if (text && value) {
          options.push({ text, value })
        }
        return options
      }, [])
    },
    numOfEmployees() {
      return get(this.getCustomerData, 'numOfEmployees', 0);
    },
    footerPropsObj() {
      if (this.autoSyncVal) {
        return {
            pagination: {
            pageStart: this.options.page === 1 ? 0 : ((this.minItemsLength * this.options.page) - this.minItemsLength) - 1,
            pageStop: this.options.page === 1 ? this.minItemsLength : this.minItemsLength * this.options.page,
            itemsPerPage: this.minItemsLength,
            itemsLength: this.numOfEmployees
          }
        } 
      } else {
        return {
          itemsPerPage: this.minItemsLength,
        };
      }
    }
  },
  watch: {
    autoSyncUpdate: {
      immediate: true,
      handler(cur) {
        this.autoSyncVal = cur;
      }
    },
    options: {
        handler (cur, prev) {
          if (!isEqual(cur, prev)) {
            // if pages aren't the same and page has not been loaded
            if ((cur.page !== prev.page) && (cur.page > prev.page) && !this.pageLoaded[cur.page]) {
              this.$store.dispatch('GET_MORE_EMPLOYEES', {limit: this.options.itemsPerPage, sortBy: this.sortBy, desc: this.sortDesc});
              this.pageLoaded[cur.page] = true;
            }
          }
        },
        deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('BIND_EMPLOYEES', {limit: this.options.itemsPerPage, sortBy: this.sortBy, desc: this.sortDesc});
  },
  methods: {
    setSortOption(name, value) {
      if (name === 'sortBy') {
        this.sortBy = value
      } else if (name === 'sortDesc') {
        this.sortDesc = !this.sortDesc
      }
      this.pageLoaded = {};
      this.$store.dispatch('BIND_EMPLOYEES', {limit: this.options.itemsPerPage, sortBy: this.sortBy, desc: this.sortDesc});
    },
    isDirectoryLinked() {
      return get(this.getCustomerData, 'type') === 'google' || get(this.getCustomerData, 'type') === 'microsoft'
    },
    handleUploadClick() {
      if (!this.autoSyncVal) {
        this.showUploadDialog = true;
      } else {
        if (this.isDirectoryLinked()) {
          this.showUploadDialog2 = true;
        } else {
          this.showUploadDialog = true;
        }
      }
    },
    /**
     * @param {boolean} the switch state
     */
    onAutoSyncChange(val) {
      this.autoSyncDialog = val;
      this.autoSyncVal = !!val;

      if (!val) {
        this.cancelAutoSyncDialog = true;
      }
    },
    onSyncDirectory: debounce(async function() {
      try {
        const customerId = get(this.getCustomerData, 'id');

        await adminControls.editCustomer(customerId, {
          settings: {
            booking: {
              sync_directory: this.autoSyncVal
            }
          }
        });
      } catch(err) {
        this.$store.dispatch('TOAST_ERROR', {
          message: 'Error trying to Auto Sync directory.'
        });
      } finally {
        this.autoSyncDialog = false;
        this.cancelAutoSyncDialog = false;
      }
    }, 500),
    async onUploadClose() {
      const customerId = get(this.getCustomerData, 'id');

      this.showUploadDialog = false;

      await adminControls.editCustomer(customerId, {
        settings: {
          booking: {
            sync_directory: this.autoSyncVal
          }
        }
      });
    },
    async onUploadComplete({ numOfEmployees }) {
      try {
        const customerId = get(this.getCustomerData, 'id');

        this.autoSyncVal = false;

        await adminControls.editCustomer(customerId, {
          numOfEmployees
        });

        // resync employees
        this.$store.dispatch('BIND_EMPLOYEES', {limit: numOfEmployees, sortBy: this.sortBy, desc: this.sortDesc});
      } catch(err) {
        console.error(`Unable to update employee count (${err.message})`)
      }
    }
  }
};
</script>

<style></style>
