<template>
  <v-btn
    v-bind="{ ...$props, ...$attrs }"
    @click="click"
    :default="$vuetify.breakpoint.lgAndUp"
    :x-large="$vuetify.breakpoint.xs || btnLarge"
    :large="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
    :color="buttonColor"
    :class="`all-button ${btnLarge ? 'px-4 py-6' : ''}`"
    dark
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'TurfBtn',
  props: {
    rounded: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean
    },
    text: {
      type: Boolean
    },
    fab: {
      type: Boolean
    },
    actionBtn: {
      type: Boolean
    },
    btnLarge: {
      type: Boolean
    },
  },
  computed: {
    buttonColor() {
      if (this.outlined || this.text) {
        return '#ffffff';
      } else if (this.fab || this.actionBtn) {
        return '#6e2ed2';
      } else if (this.$attrs.color === 'error') {
        return 'error'
      }
      return '#6e2ed2';
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
