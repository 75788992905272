<template>
  <v-card flat dark>
    <v-col>
      <v-row class="d-flex justify-space-between px-4">
        <h2>Tenant-Customer Mapppings</h2>
        <turf-btn small @click="showTenatCustomerForm">
          <v-icon>
            mdi-plus
          </v-icon>
        </turf-btn>
      </v-row>
      <v-data-table dark :headers="headers" :items="tableData">
        <template v-slot:item.options="{ item }">
          <div>
            <turf-btn class="mr-2" small @click="showTenatCustomerForm(item)">
              <v-icon>
                mdi-pencil-outline
              </v-icon>
            </turf-btn>
            <turf-btn small @click="deleteRecord(item.id)">
              <v-icon>
                mdi-close
              </v-icon>
            </turf-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog width="500" v-model="showForm">
        <v-card dark class="py-4">
          <v-card-title primary-title>
            {{ formTitle }}
          </v-card-title>
          <v-card-text>
            <v-select
              :items="customerSelects"
              v-model="selectedCustomerId"
              label="Select Customer by Name"
            ></v-select>
            <v-text-field label="Tenant Id" v-model="tenantId"></v-text-field>
          </v-card-text>
          <v-card-actions class="mx-4 d-flex justify-end">
            <turf-btn class="mr-2" text @click="showForm = false">
              Cancel
            </turf-btn>
            <turf-btn color="success" @click="onSubmit">
              Publish
            </turf-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="500" v-model="showDeleteConfirmation">
        <v-card dark>
          <v-card-title primary-title>
            Permanently Delete This Mapping?
          </v-card-title>
          <v-card-text>
            Deleting a tenant-customer mapping permanently destroys the record.
          </v-card-text>
          <v-card-actions class="mx-4 d-flex justify-end">
            <turf-btn
              class="mr-2"
              color="error"
              @click="showDeleteConfirmation = false"
            >
              Cancel
            </turf-btn>
            <turf-btn color="warning" @click="handleDeleteRecord">
              Delete
            </turf-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-card>
</template>

<script>
import adminControls from '../service/adminControls.js';
export default {
  data() {
    return {
      headers: [
        {
          text: 'Customer',
          value: 'customerName'
        },
        {
          text: 'Tenant',
          value: 'tenantId'
        },
        {
          text: 'Options',
          value: 'options',
          align: 'right'
        }
      ],
      documents: [],
      customers: [],
      showForm: false,
      showDeleteConfirmation: false,
      tenantId: '',
      selectedDocId: null,
      selectedCustomerId: null
    };
  },
  firestore() {
    return {
      documents: this.$firestore.collection('tenant_customer'),
      customers: this.$firestore.collection('customers')
    };
  },
  computed: {
    formTitle() {
      return this.selectedDocId
        ? 'Edit Tenant-Customer Mapping'
        : 'Create New Tenant-Customer Mapping';
    },
    tableData() {
      return this.documents.map(doc => {
        const docData =
          this.customers.find(x => x.id === doc.customer_id) || {};
        return {
          customerId: doc.customer_id,
          customerName: docData.name || '',
          id: doc.id,
          tenantId: doc.tenant_id
        };
      });
    },
    customerSelects() {
      return this.customers.map(customer => ({
        value: customer.id,
        text: customer.name
      }));
    }
  },
  methods: {
    onSubmit() {
      if (this.selectedDocId) {
        adminControls.editTenantCustomer({
          customerId: this.selectedCustomerId,
          tenantId: this.tenantId,
          recordId: this.selectedDocId
        });
      } else {
        adminControls.createTenantCustomer({
          customerId: this.selectedCustomerId,
          tenantId: this.tenantId
        });
      }
      this.showForm = false;
    },
    showTenatCustomerForm(data = {}) {
      const { customerId = '', id = '', tenantId = '' } = data;
      if (customerId && id && tenantId) {
        this.tenantId = tenantId;
        this.selectedDocId = id;
        this.selectedCustomerId = customerId;
      } else {
        this.tenantId = '';
        this.selectedDocId = null;
        this.selectedCustomerId = null;
      }
      this.showForm = true;
    },
    deleteRecord(id) {
      this.showDeleteConfirmation = true;
      this.selectedDocId = id;
    },
    handleDeleteRecord() {
      adminControls.deleteTenantCustomer(this.selectedDocId);
      this.showDeleteConfirmation = false;
    }
  }
};
</script>

<style></style>
