const getLocalhost = () => location.hostname.includes("localhost") ? 'localhost' : false;

const getEnv = () => {
  switch (process.env.VUE_APP_ENV) {
    case 'development':
    case 'local':
       return 'local';
    case 'integration': return 'int';
    case 'qa': return 'qa';
    case 'stage': return 'stage';
    case 'production': return 'prod';
  }
};

const productDomains = (p) => {
  let product = {
    imgUrl: '',
    url: '',
    name: ''
  };

  const env = getLocalhost() || getEnv();

  switch (p) {
    case "beam":
      switch (env) {
        case "int":
          product.url = "https://beam-int.skykit.com";
          break;
        case "qa":
          product.url = "https://beam-qa.skykit.com";
          break;
        case "stage":
          product.url = "https://beam-stage.skykit.com";
          break;
        case "prod":
          product.url = "https://beam.skykit.com";
          break;
        case 'localhost':
        default:
          product.url = 'https://localhost.skykit.com:7999';
          break;
      }
      break;
    case "cui":
      switch (env) {
        case "int":
          product.url = "https://control-ui-int.skykit.com/";
          break;
        case "qa":
          product.url = "https://control-ui-qa.skykit.com/";
          break;
        case "stage":
          product.url = "https://control-ui-stage.skykit.com/";
          break;
        case "prod":
          product.url = "https://control-ui.skykit.com/";
          break;
        case 'localhost':
        default:
          product.url = 'https://localhost.skykit.com:8082';
          break;
      }
      break;
  }

  if (p === 'beam') {
    product.imgUrl = '/assets/beam_logo.svg';
    product.name = 'Skykit Beam';
  } else if (p === 'cui') {
    product.imgUrl = '/assets/control_logo.svg';
    product.name = 'Skykit Control';
  }

  return product;
};

export { productDomains };
