<template>
  <v-main>
    <v-row> <h3>Admin Controls</h3> </v-row>
    <v-row>
      <v-col cols="11">
        <v-tabs v-model="tab" dark>
          <v-tab v-for="item in items" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="11">
        <v-tabs-items dark v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab">
            <component v-bind:is="item.content"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import TenantCustomerControls from '@/components/TenantCustomerControls';
import CustomerControls from '@/components/CustomerControls';

export default {
  name: 'AdminView',
  data() {
    return {
      tab: null,
      items: [
        { tab: 'Customers', content: CustomerControls },
        { tab: 'Tenant-Customer Mapping', content: TenantCustomerControls },
      ]
    };
  }
};
</script>

<style></style>
