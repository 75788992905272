<template>
  <v-menu
    dark
    v-if="getAvailableProducts && getAvailableProducts.length"
    :close-on-content-click="false"
    :nudge-width="150"
    left
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon color="white">mdi-apps</v-icon>
      </v-btn>
    </template>
    <v-list class="d-flex">
      <v-list-item
        v-for="productName in getAvailableProducts"
        :key="productName"
      >
        <div
          class="product-item app-button pa-2 text-center"
          @click="navigateToProduct(productName)"
        >
          <p class="mt-4 mb-1">
            <img :src="productDomains(productName).imgUrl" :id="productName" />
          </p>
          <p class="subtitle-1">
            {{ productDomains(productName).name }}
          </p>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { productDomains } from "@/configs/domain";

export default {
  name: "ProductSelection",
  data: () => ({
    productDomains,
  }),
  computed: {
    ...mapGetters(["getAvailableProducts"]),
  },
  methods: {
    navigateToProduct: (productName) => {
      location.href = productDomains(productName)?.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  width: 150px;
  cursor: pointer;
}
</style>
