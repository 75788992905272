import { firestoreAction } from 'vuexfire';
import firebase from 'firebase/app';
import 'firebase/firestore';

const state = {
    customerLogs: []
};
const getters = {
    customerLogs: state => state.customerLogs
};
const actions = {
    ADD_CUSTOMER_ACTIVITY: ({ rootState }, { message, createdAt }) => {
        const customerId = rootState.customer.customerId || rootState.customer.id;
        const tenantCode = rootState.auth.tenantCode;
        return firebase.firestore().collection('customers').doc(customerId).collection('console_logs').add({ message, tenantCode, createdAt });
    },
    GET_CUSTOMER_LOGS: firestoreAction(({ bindFirestoreRef }, customerId) => {
        bindFirestoreRef('customerLogs',firebase.firestore().collection('customers').doc(customerId).collection('console_logs'));
    }),
    // eslint-disable-next-line no-unused-vars
    SET_LOGIN_EVENT: (_, {customerId, name, email, firebaseUid}) => {
        return firebase.firestore().collection('action_log_queue')
            .add({
                type: 'event',
                data: JSON.parse(JSON.stringify({
                    customerId,
                    name,
                    email,
                    firebaseUid,
                    id: 'undefined'
                })),
                action: 'login',
                level: 'info',
                metaData: {
                    customerId,
                    deviceKey: '',
                    source: 'console',
                }
            })
            .catch(() => { });
      },
};

const mutations = {
    RESET_CUSTOMER_LOGS: (state) => {
        state.customerLogs = [];
    }
};

export default {
    mutations, 
    state,
    getters,
    actions
};
