<template>
  <v-card dark class="pa-4">
    <v-card-title primary-title class="mb-8">
      <div class="modal-header">
        <v-btn class="close-button" icon @click="onCancel"
            ><v-icon>mdi-close</v-icon></v-btn
        >
        <h1 class="mb-4">Add a new Space</h1>
        <template v-if="step === 2">
          Create one or more {{ formData.spaceType === 'desk' ? 'Desk' : 'Room' }} spaces by selecting calendar resources from the list below.
        </template>
        <template v-if="step === 3">
          Ensure the information below is correct before creating your {{ formData.spaceType === 'desk' ? 'Desk' : 'Room' }} spaces.
          <br />
          You can also make changes later.
        </template>
      </div>
    </v-card-title>
    <!-- step 1: space type select -->
    <template v-if="step === 1">
      <v-card-text>
        <v-form v-model="isValid" ref="form">
          <v-row v-if="hasBookingEnabled">
            <v-col cols="12">
              <h3 class="mb-4">What kind of Space do you want to create?</h3>
              <v-select
                v-model="formData.spaceType"
                outlined
                :items="spaceTypes"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="formData.spaceType === 'check-in' && hasCheckinEnabled">
            <v-col cols="12">
              <h3 class="mb-4">Name your space</h3>
              <v-text-field
                v-model="formData.name"
                outlined
                :rules="rules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="formData.spaceType === 'check-in' && hasCheckinEnabled">
            <!-- building name -->
            <v-col cols="5">
              <h3 class="mb-4">Building</h3>
              <OpenSelect
                :field-config="turfMetaEditabled.building"
                v-model="formData.turf_meta.building_name"
                :options="[...getFieldsOptions('turf_meta.building_name'), ...getFieldsOptions('building_name')]"
                clearable
              />
            </v-col>
            <!-- floor -->
            <v-col cols="3">
              <h3 class="mb-4">Floor</h3>
              <OpenSelect
                :field-config="turfMetaEditabled.floor"
                v-model="formData.turf_meta.floor"
                :options="[...getFieldsOptions('turf_meta.floor'), ...getFieldsOptions('floor')]"
                clearable
              />
            </v-col>
            <!-- zone -->
            <v-col cols="4">
              <h3 class="mb-4">Zone</h3>
              <OpenSelect
                :field-config="turfMetaEditabled.zone"
                v-model="formData.turf_meta.zone"
                :options="[...getFieldsOptions('turf_meta.zone'), ...getFieldsOptions('zone')]"
                clearable
              />
            </v-col>

            <v-col cols="12" class="mt-n5 body-1">
              <strong>Note</strong>: You can create new options for Building, Floor, and Zone by typing in the selection field.
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end px-5 pb-5 pt-0">
        <turf-btn 
          @click="onSubmit"
          :disabled="!isValid"
          :loading="loading"
          color="success"
          btn-large
          v-if="formData.spaceType === 'check-in'"
        >Add Space</turf-btn>
        <turf-btn 
          @click="gotoResourcesSelect"
          :disabled="!formData.spaceType"
          color="success"
          btn-large
          v-else
        >Continue</turf-btn>
      </v-card-actions>
    </template>
    <!-- step 2: resources select -->
    <template v-if="step === 2">
      <v-card-text>
        <v-row>
          <v-col class="d-flex align-center justify-center"  xs="12" sm="12" md="12" lg="6">
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="6">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dark
                  dense
                  class="search"
                ></v-text-field>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="6">
                <sorting-controls 
                  :items="sortOptions" 
                  :sortBy="sortBy" 
                  :sortDesc="sortDesc" 
                  @setSortOption="setSortOption" 
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="availableResourcesHeaders"
              :items-per-page="10"
              :items="availableResources"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="elevation-1 space-table"
              dark
              item-key="id"
              show-select
              v-model="selectedResources"
            >
              <template v-slot:item.isActive="{ item }">
                {{ item.isActive ? 'Active' : 'Inactive' }}
              </template>
              <template v-slot:no-data>
                <span class="white--text">There are no calendars to display.</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end px-5 pb-5 pt-0">
        <turf-btn 
          @click="gotoSetupSpaces" 
          color="success" 
          v-text="'Continue'"
          btn-large
          :disabled="!selectedResources.length"
        />
      </v-card-actions>
    </template>
    <!-- step 3: spaces setup -->
    <template v-if="step === 3">
      <v-card-text class="pb-8">
        <v-row class="mt-n8 text-left body-1">
          <v-col cols="12">
              <strong>Note</strong>: You can create new options for Building, Floor, and Zone by typing in the selection field.
          </v-col>
        </v-row>
        <v-row class="mb-10" style="border-bottom: 1px solid rgba(255,255,255,.3)">
          <v-col cols="3"><h3>{{ formData.spaceType === 'desk' ? 'Desk' : 'Room' }} Name</h3></v-col>
          <v-col cols="3"><h3>Building</h3></v-col>
          <v-col cols="2"><h3>Floor</h3></v-col>
          <v-col cols="2"><h3>Zone</h3></v-col>
          <v-col cols="2"><h3>Active / Inactive</h3></v-col>
        </v-row>
        <v-row v-for="space of selectedResources" :key="space.id" class="mt-n9 mb-n9">
          <v-col cols="3">
            <v-text-field
              outlined
              :value="space.name"
              @change="setNewData(space, 'turf_meta.space_name', $event)"
            ></v-text-field>
          </v-col>
          <!-- building name -->
          <v-col cols="3">
            <OpenSelect
              :field-config="turfMetaEditabled.building"
              placeholder="Building"
              :value="space.building_name"
              :options="[...getFieldsOptions('building_name'), ...getFieldsOptions('turf_meta.building_name')]"
              @select="setNewData(space, $event.fieldKey, $event.value)"
              clearable
            />
          </v-col>
          <!-- floor -->
          <v-col cols="2">
            <OpenSelect
              :field-config="turfMetaEditabled.floor"
              placeholder="Floor"
              :value="space.floor"
              :options="[...getFieldsOptions('floor'), ...getFieldsOptions('turf_meta.floor')]"
              @select="setNewData(space, $event.fieldKey, $event.value)"
              clearable
            />
          </v-col>
          <!-- zone -->
          <v-col cols="2">
            <OpenSelect
              :field-config="turfMetaEditabled.zone"
              placeholder="Zone"
              :value="space.zone"
              :options="[...getFieldsOptions('zone'), ...getFieldsOptions('turf_meta.zone')]"
              @select="setNewData(space, $event.fieldKey, $event.value)"
              clearable
            />
          </v-col>
          <!-- active/inactive -->
          <v-col cols="2">
            <BinarySelect 
                outlined
                :true-value="true"
                :false-value="false"
                true-label="Active"
                false-label="Inactive"
                placeholder="Active / Inactive"
                v-model="space.active"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between px-5 pb-5 pt-0">
        <turf-btn 
          @click="gotoResourcesSelect"
          outlined
          btn-large
        >Back</turf-btn>
        <turf-btn 
          @click="saveSpaces"
          color="success"
          btn-large
          :loading="loading"
        >Create spaces</turf-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import OpenSelect from "./OpenSelect.vue";
import BinarySelect from './BinarySelect.vue';
import resourcesMixin from '../mixins/resources';
import spaceMetaEdit from '../mixins/spaceMetaEdit';
import set from 'lodash/set';
import turfCore from '@skykit-dev/skykit-turf-core';
import customerMixin from '../mixins/customer';

const defaultFormData = () => ({
  name: '',
  spaceType: '',
  turf_meta: {
    building_name: '',
    floor: '',
    zone: '',
  }
});

export default {
  name: 'space-creation',
  mixins: [resourcesMixin, spaceMetaEdit, customerMixin],
  components: {
    OpenSelect,
    BinarySelect,
  },
  data() {
    return {
      isValid: false,
      sortBy: 'name',
      loading: false,
      formData: defaultFormData(),
      step: 1,
      selectedResources: [],
      spaceTypes: this.getSpaceTypesByType(),
      rules: [v => !!v || 'This Field is Required!']
    };
  },
  mounted() {
    // has only checkin enabled
    if (this.hasCheckinEnabled && !this.hasBookingEnabled) {
      this.formData.spaceType = 'check-in';
    }
    // has only booking enabled
    if (!this.hasCheckinEnabled && this.hasBookingEnabled) {
      // only desk booking is enabled
      if (this.hasDeskBookingEnabled && !this.hasSpaceBookingEnabled) {
        this.formData.spaceType = 'desk';
        this.gotoResourcesSelect();
      }
      // only space booking is enabled
      if (!this.hasDeskBookingEnabled && this.hasSpaceBookingEnabled) {
        this.formData.spaceType = 'room';
        this.gotoResourcesSelect();
      }
    }
  },
  computed: {
    availableResources() {
      return JSON.parse(JSON.stringify(this.resources // deep copy to avoid side effects
        .filter(r => !r.turf_meta?.is_assigned))) // filter not assigned 
        .map(space => {
          space.turf_meta.space_name = space.name || '';
          space.turf_meta.building_name = space.building_name || '';
          space.turf_meta.floor = space.floor || '';
          space.turf_meta.zone = space.zone || '';
          space.active = true;
          return space;
        });
    },
    availableResourcesHeaders() {
      const headers = [...this.headers];
      return headers;
    },
    saveDisabled() {
      return this.selectedResources.filter(space => space.turf_meta.space_name)
    },
  },
  methods: {
    onCancel() {
      this.$emit('onCancel');
      this.reset();
    },
    onSubmit() {
      this.loading = true;
      this.formData.turf_meta.space_name = this.formData.name;
      this.$emit('onSubmit', this.formData);

      this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
        message: `Created a new Space (Check-in) named "${this.formData.name}"`,
        createdAt: new Date()
      })
    },
    reset() {
      this.$refs.form?.resetValidation();
      this.gotoFirstStep();
      Object.assign(this.formData, defaultFormData());
      this.loading = false;
    },
    gotoFirstStep() {
      this.step = 1;
      this.$emit('on-modal-width', 800);
    },
    gotoResourcesSelect() {
      this.step = 2;
      this.$emit('on-modal-width', 1240);
    },
    gotoSetupSpaces() {
      this.step = 3;
      this.$emit('on-modal-width', 1240);
    },
    setNewData(space, key, value) {
      if (value === undefined) {
        return;
      }
      set(space, key, value);
    },
    saveSpaces() {
      this.loading = true;
      this.selectedResources.forEach(space => {
        const spaceData = {
          active: space.active,
          archive: false,
          space_type: this.formData.spaceType,
          name: space.name,
          turf_meta: {
            ...space.turf_meta,
            is_assigned: true,
          },
        };

        try {
            turfCore.customer.space.update(space.id, spaceData);

            this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
              message: `Created a new Space (${this.formData.spaceType}) using ${space.name} resource`,
              createdAt: new Date()
            })
        } catch (error) {
            this.$store.dispatch('TOAST_ERROR', {
                message: 'Error updating spaces, some spaces may not have been updated successfully.'
            })
        }
      });

      this.onCancel();
    }
  }
};
</script>
