import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            customerId: state => state.customer?.id,
            customerSettings: state => state.customer?.settings,
            hasCheckinEnabled: state => state.customer?.settings?.check_in?.is_enable || false,
            hasDeskBookingEnabled: state => state.customer?.settings?.booking?.desk?.is_enable || false,
            hasSpaceBookingEnabled: state => state.customer?.settings?.booking?.space?.is_enable || false,
            hasBookingEnabled: state => (state.customer?.settings?.booking?.space?.is_enable || state.customer?.settings?.booking?.desk?.is_enable) || false,
        }),
    }
}