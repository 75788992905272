<template>
  <v-card dark>
    <v-card-title primary-title>
      <h2 class="headline font-weight-bold text-left mb-4">
        Create A New Feature Flag
      </h2>
    </v-card-title>
    <v-card-text>
      <v-form v-model="isValid" ref="form">
        <div class="d-flex">
          <v-text-field
            v-model="formData.name"
            outlined
            label="Feature Flag Name"
            :rules="rules"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            v-model="formData.description"
            outlined
            label="Feature Flag Description"
            :rules="rules"
          ></v-text-field>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-end px-5 pb-5 pt-0">
      <turf-btn @click="onCancel" text>Cancel</turf-btn>
      <turf-btn @click="onSubmit" :disabled="!isValid" color="success">
        Add Flag
      </turf-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const defaultFormData = () => ({
  name: '',
});
export default {
  name: 'FeatureFlagForm',
  data() {
    return {
      isValid: false,
      formData: defaultFormData(),
      rules: [v => !!v || 'This Field is Required!']
    };
  },
  methods: {
    onCancel() {
      this.$emit('onCancel');
      this.$refs.form.resetValidation();
      Object.assign(this.formData, defaultFormData());
    },
    onSubmit() {
      let data  = {
        name: this.formData.name,
        description: this.formData.description
      }
      this.$emit('onSubmit', data);
      this.$refs.form.resetValidation();
      Object.assign(this.formData, defaultFormData());
    }
  }
};
</script>

<style></style>
