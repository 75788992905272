var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('h3',{staticClass:"display-2 font-weight-bold"},[_vm._v("Reports")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-left font-weight-bold mb-6"},[_vm._v(" Check-In / Check-Out Activity ")]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('sorting-controls',{attrs:{"items":_vm.sortOptions,"sortBy":_vm.sortBy,"sortDesc":_vm.sortDesc},on:{"setSortOption":_vm.setSortOption}}),_c('download-csv',{attrs:{"data":_vm.csvData}},[_c('turf-btn',{attrs:{"actionBtn":""}},[_vm._v("Export CSV")])],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",style:({ backgroundColor: 'transparent !important' }),attrs:{"dark":"","loading":_vm.pageLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"headers":_vm.activityLogHeaders,"items":_vm.data,"items-per-page":50,"options":_vm.options,"footer-props":{
          'items-per-page-options':[50],
          'show-current-page': true,
          pagination: {
            pageStart: _vm.options.page === 1 ? 0 : _vm.getActivityCount < 100 ? _vm.getActivityCount - 50 : _vm.options.page * 50,
            pageStop: _vm.options.page === 1 ? 50 : _vm.getActivityCount + 50,
            itemsPerPage: 50,
            itemsLength: _vm.getActivityCount
          }
        }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.checkedOutAt",fn:function({ item }){return [(item.checkedOutAt)?_c('span',[_vm._v(" "+_vm._s(item.checkedOutAt)+" ")]):_c('turf-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.handleCheckout(item.id)}}},[_vm._v(" Checkout ")])]}},{key:"item.location",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.get(item, 'location.friendly_name') || '')+" ")]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer')]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }