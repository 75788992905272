<template>
    <div>
        <turf-btn
            class="mr-2"
            dense
            :disabled="!spaceIds.length"
            @click="print"
        >
            <!-- TODO -->
            <svg id="Raw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" style="width: 22px;">
                <rect width="256" height="256" fill="none"/>
                <rect x="48.00244" y="48" width="60" height="60" rx="8" stroke-width="24" :stroke="spaceIds.length ? '#fff' : 'rgba(255, 255, 255, .4)'" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
                <rect x="48.00244" y="148" width="60" height="60" rx="8" stroke-width="24" :stroke="spaceIds.length ? '#fff' : 'rgba(255, 255, 255, .4)'" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
                <rect x="148.00244" y="48" width="60" height="60" rx="8" stroke-width="24" :stroke="spaceIds.length ? '#fff' : 'rgba(255, 255, 255, .4)'" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
                <line x1="148.00244" y1="148" x2="148.00244" y2="172" fill="none" :stroke="spaceIds.length ? '#fff' : 'rgba(255, 255, 255, .4)'" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"/>
                <polyline points="148.002 208 184.002 208 184.002 148" fill="none" :stroke="spaceIds.length ? '#fff' : 'rgba(255, 255, 255, .4)'" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"/>
                <line x1="184.00244" y1="164" x2="208.00244" y2="164" fill="none" :stroke="spaceIds.length ? '#fff' : 'rgba(255, 255, 255, .4)'" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"/>
            </svg>
        </turf-btn>

        <div style="display: none" v-if="spaceIds.length">
            <iframe name="qrprintpage" ></iframe>
            <div id="printable-qr">
                <div class="multiprint">
                    <template v-for="(spaceId, i) of spaceIds" >
                        <div v-if="i && !(i % 4)" :key="`pb-${spaceId}`" style="clear: left; page-break-after: always;height: 60px;">&nbsp;</div>
                        <div v-if="i && !(i % 4)" :key="`pb-${spaceId}`" style="margin-bottom: 60px;">&nbsp;</div>
                        <div class="qr-printable-class" :key="`qr-${spaceId}`">
                            <div class="data" v-if="spaceData[spaceId]">
                                <h1>{{ spaceData[spaceId].turf_meta.space_name }}</h1>
                                <div class="metadata">
                                    <span v-if="spaceData[spaceId].turf_meta.building_name">
                                        {{ spaceData[spaceId].turf_meta.building_name }}<span v-if="spaceData[spaceId].turf_meta.zone || spaceData[spaceId].turf_meta.floor">, </span>
                                    </span>
                                    <span v-if="spaceData[spaceId].turf_meta.floor">
                                        floor {{ spaceData[spaceId].turf_meta.floor }}<span v-if="spaceData[spaceId].turf_meta.zone">, {{ spaceData[spaceId].turf_meta.zone }}</span>
                                    </span>
                                    <span v-else-if="spaceData[spaceId].turf_meta.zone">{{ spaceData[spaceId].turf_meta.zone }}</span>
                                </div>
                            </div>
                            <QrcodeVue
                                :value="qrData(spaceId)"
                                :size="size"
                                class="qr"
                                render-as="svg"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import get from "lodash/get";
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import VueHtmlToPaper from "vue-html-to-paper";
import { mapState } from "vuex";
import { getSpaceShortUrl } from '@/utils/checkInUtils';

const spaceShortUrlStorageKeyPrefix = 'space-short-url';

Vue.use(VueHtmlToPaper, {
    name: 'qrprintpage', // the name of the iframe window
    specs: ['fullscreen=no', 'titlebar=no', 'scrollbars=no'],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    styles: ['/assets/qrstyles.css'], // the printable QR styles page
});

export default {
    name: 'bulk-desk-qr',
    components: {
        QrcodeVue,
    },
    data: () => ({

    }),
    props: {
        spaceIds: Array,
        size: {
            // the QR size
            type: Number,
            default: 600,
        },
    },
    methods: {
        async print() {
            await this.$htmlToPaper('printable-qr');
        },
        async getSpaceShortUrl() {
            this.qrShortUrl = localStorage.getItem(`${spaceShortUrlStorageKeyPrefix}-${this.spaceId}`);

            if (!this.qrShortUrl) {
                this.qrShortUrl = await getSpaceShortUrl(this.spaceId);
            }
            if (this.qrShortUrl) {
                localStorage.setItem(`${spaceShortUrlStorageKeyPrefix}-${this.spaceId}`, this.qrShortUrl );
            } 
        },
        qrData(spaceId) {
            const baseUrl = process.env.VUE_APP_SHORT_LINK_BASE_URL;
            const data = {
                customerId: this.$store.getters.getCustomerId,
                spaceId,
                type: 'desk',
            };
            const id = btoa(JSON.stringify(data));
            return `${baseUrl}${id}`;
        },
    },
    computed: {
        ...mapState(['bookingSpaces']),
        spaceData() {
            return this.spaceIds.reduce((acc, spaceId) => {
                const space = this.bookingSpaces.find(space => {
                    return space.id === spaceId;
                });
                return {
                    ...acc,
                    [spaceId]: space,
                }
            }, {});
        },
    },
};
</script>

<style lang="scss">
.qr-container {
    align-content: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    iframe {
        height: 0;
        width: 0;
    }

    #printable-qr {
        align-self: center;
        background: #fff;
        border-radius: 4px;
        padding: 10px;

        .data {
            display: none;
        }

        .qr,
        svg {
            height: 200px !important;
            width: 200px !important;
        }
    }
}
</style>