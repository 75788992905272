import firebase from 'firebase/app';
import 'firebase/firestore';
import store from '../store';

const getAllCustomerMappings = async () => {
  const result = await firebase
    .firestore()
    .collection('tenant_customer')
    .get();
  return result.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      customerId: data.customer_id,
      tenantId: data.tenant_id
    };
  });
};

// Customer collection methods
const createCustomer = async ({ customerName = '' }) => {
  const themeId = store.getters.getDefaultTheme.id;
  return await firebase
    .firestore()
    .collection('customers')
    .add({
      name: customerName,
      theme_id: themeId
    });
};

const editCustomer = async (recordId, data) => {
  return await firebase
    .firestore()
    .collection('customers')
    .doc(recordId)
    .set({
      ...data
    }, {
      merge: true
    });
};

const deleteCustomer = async (customerId = '') => {
  return await firebase
    .firestore()
    .collection('customers')
    .doc(customerId)
    .delete();
};

// Tenant-Customer collection methods
const createTenantCustomer = async ({ customerId = '', tenantId = '' }) => {
  return await firebase
    .firestore()
    .collection('tenant_customer')
    .add({
      customer_id: customerId,
      tenant_id: tenantId
    });
};

const editTenantCustomer = async ({
  customerId = '',
  tenantId = '',
  recordId = ''
}) => {
  return await firebase
    .firestore()
    .collection('tenant_customer')
    .doc(recordId)
    .update({
      customer_id: customerId,
      tenant_id: tenantId
    });
};

const deleteTenantCustomer = async (recordId = '') => {
  return await firebase
    .firestore()
    .collection('tenant_customer')
    .doc(recordId)
    .delete();
};

export default {
  getAllCustomerMappings,
  createCustomer,
  editCustomer,
  deleteCustomer,
  createTenantCustomer,
  editTenantCustomer,
  deleteTenantCustomer,
};
