<template>
  <span v-if="color">
    <h6 class="subtitle-1 font-weight-black text-left mt-4 mb-3">{{ title }}</h6>
    <v-dialog v-model="isOpen" width="500">
      <template v-slot:activator="selectTextColor">
        <turf-btn width="100%" height="40px" class="justify-start" outlined dark v-on="selectTextColor.on">
          <v-avatar
            class="mr-2"
            :color="colorString"
            height="18"
            width="18"
            min-width="0"
          ></v-avatar>
          {{ colorString }}
        </turf-btn>
      </template>

      <v-card dark>
        <v-color-picker
          :value="color"
          @input="handleChange"
          hide-mode-switch
          mode="hexa"
          dark
          width="500"
          flat
        ></v-color-picker>
        <v-card-actions class="justify-end">
          <turf-btn
            @click="handleSave"
            :disabled="color === colorString"
            color="green"
            dark
          >
            Save
          </turf-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: "ColorPicker",
  props: ["title", "colorString", "open", "updateColor"],
  data: function() {
    return {
      color: "",
      isOpen: false
    };
  },
  watch: {
    open: function(newValue) {
      if (newValue) {
        this.color = this.colorString;
      }
      this.isOpen = newValue;
    }
  },
  created: function() {
    this.color = this.colorString;
  },
  methods: {
    handleSave() {
      this.updateColor(this.title, this.color);
      this.isOpen = false;
      this.$emit('colorSave')
    },
    handleChange(value) {
      this.color = value;
    }
  }
};
</script>

<style scoped>
</style>
