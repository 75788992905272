import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import SpacesView from '@/views/Spaces.vue';
import CalendarsView from '@/views/Calendars.vue';
import SpaceDetailView from '@/views/SpaceDetail.vue';
import DisplaysView from '@/views/Displays.vue';
import SettingsView from '@/views/Settings.vue';
import AdminView from '@/views/Admin.vue';
import CheckInView from '@/views/CheckIn.vue';
import ReportsView from '@/views/Reports.vue';
import FeatureSettingsView from '@/views/FeatureSettings.vue';
import CustomerLogsView from '@/views/CustomerLogs.vue';
import LandingPage from '@/views/LandingPage.vue';
import MainPage from '@/views/MainPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/spaces',
    component: MainPage, 
    children: [
      {
        path: '',
        name: 'Spaces',
        component: SpacesView
      },
      {
        path: '/calendars',
        name: 'Calendars',
        component: CalendarsView
      },
      {
        path: '/space/:id',
        name: 'Space Detail',
        component: SpaceDetailView
      },
      {
        path: '/displays',
        name: 'Displays',
        component: DisplaysView
      },
      {
        path: '/settings',
        name: 'Settings',
        component: SettingsView
      },
      {
        path: '/directory',
        name: 'CheckIn',
        component: CheckInView
      },
      {
        path: '/reports',
        name: 'Reports',
        component: ReportsView
      },
      {
        path: '/admin',
        name: 'Admin',
        component: AdminView
      },
      {
        path: '/redirect',
        redirect: '/spaces'
      },
      {
        path: '/feature-settings',
        name: 'FeatureSettingsView',
        component: FeatureSettingsView,
        beforeEnter: (to, from, next) => {
          const isLoggedIn = store.getters.isLoggedIn;
          const isPlatformAdmin = store.getters.isPlatformAdmin;
    
          if (isLoggedIn && isPlatformAdmin) {
            next();
          }
        }
      },
      {
        path: '/customer-logs',
        name: 'CustomerLogs',
        component: CustomerLogsView,
        beforeEnter: (to, from, next) => {
          const isLoggedIn = store.getters.isLoggedIn;
          const isPlatformAdmin = store.getters.isPlatformAdmin;
    
          if (isLoggedIn && isPlatformAdmin) {
            next();
          }
        }
      },
    ]
  },
  {
    path: '*',
    redirect: 'Spaces'
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;
  const isPlatformAdmin = store.getters.isPlatformAdmin;

  if (isLoggedIn) {
    if (to.fullPath === "/admin") {
      return isPlatformAdmin ? next() : next({path: '/spaces'});
    } else {
      return next();
    }
  } else {
    return next();
  }
});

export default router;
