import Vue from "vue";
import Vuex from "vuex";
import get from 'lodash/get';
import VuexPersistence from 'vuex-persist'
import auth from "./auth";
import spaces from "./spaces";
import toast from "./toast";
import checkIn from "./checkIn";
import feature from "./feature";
import customerActivity from './customerActivity';
import { vuexfireMutations, firestoreAction } from "vuexfire";
import { parseFileNameFromStorageUrl } from '../utils/themeUtils'
import firebase from "firebase/app";
import "firebase/firestore";
import turfCore from '@skykit-dev/skykit-turf-core';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

const defaultState = () => ({
  customer: {},
  bookingSpaces: [],
  checkInSpaces: [],
  customerDisplays: [],
  theme: {},
  defaultTheme: {},
  customerLogs: [],
  disableApp: false
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    auth,
    spaces,
    toast,
    checkIn,
    feature,
    customerActivity
  },
  state: defaultState(),
  mutations: {
    ...vuexfireMutations,
    RESET_STATE: (state) => {
      Object.assign(state, defaultState());
    },
    SET_CUSTOMER: (state, customerData) => state.customer = customerData,
    SET_BOOKING_SPACES: (state, bookingSpaces) => state.bookingSpaces = bookingSpaces,
    SET_CHECKIN_SPACES: (state, checkInSpaces) => state.checkInSpaces = checkInSpaces,
    SET_DISPLAYS: (state, customerDisplays) => state.customerDisplays = customerDisplays,
    SET_DEFAULT_THEME: (state, defaultTheme) => state.defaultTheme = defaultTheme,
    SET_CUSTOMER_THEME: (state, theme) => state.theme = theme,
    SET_DISABLE_APP_STATE: (state, val) => state.disableApp = val
  },
  actions: {
    SET_FIRESTORE_DATA: firestoreAction(({ dispatch, commit }, customerId) => {
      turfCore.customer.getDefaultTheme().then((themeData) => {
        commit('SET_DEFAULT_THEME', themeData);
      });

      // bind customer displays
      turfCore.customer.display.subscribe((displays) => {
        commit('SET_DISPLAYS', displays);
      });

      // bind customer
      turfCore.customer.subscribe((customerData) => {
        commit('SET_CUSTOMER', customerData);
        dispatch('BIND_THEME_INFO', customerData.theme_id);
      });
      
      // bind rooms
      turfCore.customer.space.subscribeToSpaces((spaces) => {
        commit('SET_BOOKING_SPACES', spaces);
      });
      
      // bind checkin_locations
      turfCore.customer.space.subscribeToCheckinLocations((spaces) => {
        commit('SET_CHECKIN_SPACES', spaces);
      });

      // get customer theme
      turfCore.customer.getTheme(customerId).then(theme => {
        commit('SET_CUSTOMER_THEME', theme);
      });

      return;
    }),
    BIND_THEME_INFO: firestoreAction(async ({ bindFirestoreRef }, themeId) => {
      return bindFirestoreRef("theme", firebase.firestore().collection("themes").doc(themeId));
    }),
    SET_DISABLE_APP: ({ commit }, action) => {
      commit('SET_DISABLE_APP_STATE', action);
    },
  },
  getters: {
    getThemeData: state => state.theme || {},
    getCustomerData: state => state.customer,
    getSpaceList: state => [...state.bookingSpaces, ...state.checkInSpaces],
    getDefaultTheme: state => state.defaultTheme,
    getCustomerDisplays: state =>
      state.customerDisplays.map(x => ({
        ...x,
        id: x.id,
        displayId: x.serial_number || x.id
      })),
    getSpaceById: (_state, getters) => id => getters.getSpaceList.find(space => space.id === id),
    getDisplaysBySpaceId: state => spaceId => {
      return state.customerDisplays
        .filter(display => display.space_id === spaceId)
        .map(x => ({ ...x, id: x.id, displayId: x.serial_number || x.id }));
    },
    getAvailableDisplays: state => {
      return state.customerDisplays
        .filter(display => !display.space_id)
        .map(x => ({ ...x, id: x.id, displayId: x.serial_number || x.id }));
    },
    getSpacesAndDeviceNumber: (state, getters)  => (spaceType = '') => {
      let spaces;
      switch(spaceType) {
        case 'booking':
          spaces = state.bookingSpaces
          break;
        case 'checkin':
          spaces = state.checkInSpaces
          break;
        default:
          spaces = getters.getSpaceList;
      }

      const spaceTypeMap = {
        'check-in': 'Check-In',
        'room': 'Room',
        'meeting-manager': 'Room',
        'desk': 'Desk',
      }

      return spaces.reduce((agg, space) => {
        const spaceType = space.space_type || space.spaceType;
        if (
          (spaceType === 'check-in' && space.archive)
          || (!get(space, 'turf_meta.is_assigned') && get(space, 'turf_meta.archive') !== true && spaceType !== 'check-in') // if is not checkin_location and not assigned to a space
        ) {
          return agg;
        }
        const numOfDisplays = getters.getDisplaysBySpaceId(space.id).length;
        agg.push({
          spaceId: space.id,
          spaceName: space.turf_meta?.space_name || space.friendly_name,
          name: space.turf_meta?.space_name || space.friendly_name,
          buildingName: space.turf_meta?.building_name || space.building_name,
          floor: space.turf_meta?.floor || space.displayfloor_name,
          zone: space.turf_meta?.zone || space.zone,
          noOfDisplays: numOfDisplays,
          isActive: space.active ? 'Active' : 'Inactive',
          spaceType,
          readableSpaceType: spaceTypeMap[spaceType] || '',
          readableDisplayCount: numOfDisplays === 1 ? '1 Display' : `${numOfDisplays} Displays`
        });
        return agg;
      }, []);
    },
    getLogoFileName: state => {
      const logoStorageUrl = state.theme.logo
      return parseFileNameFromStorageUrl(logoStorageUrl)
    },
    getBackgroundFileName: state => {
      const backgroundImageStorageUrl = state.theme.background_image
      return parseFileNameFromStorageUrl(backgroundImageStorageUrl)
    },
    getDisableApp: state => state.disableApp
  }
});
