<template>
  <div>
    <v-card dark elevation="4">
      <v-card-title>
      <h5 class="headline font-weight-bold text-left">
        Integrations
      </h5>

      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <calendar-integration-item
              v-for="integration in integrations"
              :key="integration.type"
              :title="integration.title"
              :type="integration.type"
              :is-linked="integration.type == getCustomerData.type"
              :linked-email="getLinkedEmail"
              @onLink="handleLink"
              @onUnlink="handleUnlink"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="confirmDeleteDialog" width="780">
      <v-card dark class="pa-6">
        <v-card-title>
          <v-icon color="error" class="mr-3">
            mdi-alert-rhombus-outline
          </v-icon>
          <span>Are you sure you want to unlink this {{ calendarName }} integration?</span>
        </v-card-title>
        <v-card-text class="pa-6 ml-9">
          By unlinking, you will remove all calendars synced with Turf, and any Spaces using those calendars will be deleted.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <turf-btn
            dark
            text
            class="text-capitalize mr-2 "
            @click="confirmDeleteDialog = false"
          >
            No, keep the current integration
          </turf-btn>
          <turf-btn
            class="text-capitalize"
            color="error"
            @click="confirmUnlink"
          >
            Yes, unlink the current integration
          </turf-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="msGrantDialog" width="500">
      <v-card dark class="px-4 pt-6 pb-4">
        <v-card-text class="d-flex">
          <v-icon x-large color="error">
            mdi-alert-rhombus-outline
          </v-icon>
          <h3 class="ml-5">
            Microsoft has failed to grant full permissions. Please try again in a few seconds...
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <turf-btn
            class="mr-2"
            @click="cancelGrantRetry"
            text
          >
            CANCEL
          </turf-btn>
          <turf-btn
            dark
            @click="retryMsLogin"
            :disabled="!!msRetrySecs"
            v-text="msRetrySecs > 0 ? `RETRY IN ${msRetrySecs} ...` : 'RETRY'"
          ></turf-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import clone from 'lodash/clone';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { loadScript } from '../utils/loadScript';
import CalendarIntegrationItem from './CalendarIntegrationItem';
import * as msal from '@azure/msal-browser';

// TODO: Refactor config into it's own file.
import {
  MSFT_CALENDAR_TITLE,
  MSFT_CALENDAR_TYPE,
  GGL_CALENDAR_TITLE,
  GGL_CALENDAR_TYPE
} from '../constants';

const integrations = {
  GGL_CALENDAR_TYPE: {
    title: GGL_CALENDAR_TITLE,
    type: GGL_CALENDAR_TYPE
  },
  MSFT_CALENDAR_TYPE: {
    title: MSFT_CALENDAR_TITLE,
    type: MSFT_CALENDAR_TYPE
  }
};

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  clientId: process.env.VUE_APP_GAPI_CLIENT_ID,
  scopes: [
    'https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly',
    'https://www.googleapis.com/auth/admin.directory.user.readonly',
    'https://www.googleapis.com/auth/calendar'
  ],
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
  ]
};

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default {
  name: 'CalendarPicker',
  components: {
    CalendarIntegrationItem
  },
  data: () => ({
    confirmDeleteDialog: false,
    customerData: {},
    integrations: integrations,
    msGrantDialog: false,
    msRetrySecs: 25, // seconds
    curCalendarSelection: null,
  }),
  mounted: function() {
    this.customerData = { ...this.getCustomerData };
    if (this.isGoogleReady()) {
      // google api is already exists
      // init immediately
      this.onApiLoad();
    } else if (!this.scriptLoadingStarted) {
      // load google api and the init
      this.scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, setTimeout(this.onApiLoad, 500));
    } else {
      // is loading
    }
  },
  computed: {
    ...mapGetters(['getCustomerData', 'getCustomerId', 'isPlatformAdmin']),
    getLinkedEmail() {
      return this.isPlatformAdmin && get(this.customerData, 'meeting_manager_email', '') || '';
    },
    calendarName() {
      if (this.curCalendarSelection === 'google') {
        return 'Google Workspace';
      } else if (this.curCalendarSelection === 'microsoft') {
        return 'Microsoft 365';
      } else {
        return '';
      }
    }
  },
  watch: {
    msGrantDialog(v) { // reset timer
      this.msRetrySecs = 25;
      if (v) {
        this.startTimer();
      }
    }
  },
  methods: {
    cancelGrantRetry() {
      this.msGrantDialog = false;
    },
    startTimer() {
      const interval = setInterval(() => {
        if (this.msRetrySecs) {
          this.msRetrySecs--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    retryMsLogin() {
      this.cancelGrantRetry();
      this.microsoftLogin();
    },
    isGoogleReady() {
      return !!window.gapi;
    },
    isGoogleAuthReady() {
      return !!window.gapi.auth2;
    },
    onApiLoad() {
      window.gapi.load('client:auth2');
    },
    async googleLogin() {
      await window.gapi.client.init({
        apiKey: config.apiKey,
        clientId: config.clientId,
        discoveryDocs: config.discoveryDocs,
        scope: this.googleConsentRequestScope().join(' ')
      });
      let auth = await window.gapi.auth2.getAuthInstance();
      let offline = await auth.grantOfflineAccess();

      let googleUser = auth.currentUser.get();
      let googleProfile = googleUser.getBasicProfile();
      if (googleProfile) {
        let newCustomerData = {
          client_id: googleProfile.getId(),
          meeting_manager_email: googleProfile.getEmail(),
          type: GGL_CALENDAR_TYPE,
          access_code: offline.code
        }
        await this.handleSaveCalendarConnection(newCustomerData);

        this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
          message: `Linked Google calendar with ${googleProfile.getEmail()}`,
          createdAt: new Date()
        })
      } else {
        this.$store.dispatch('TOAST_ERROR', {
          message: 'Error getting authoirzation from Google Calendar. Please try again.'
        })
      }
    },

    async microsoftLogin() {
      try {
        this.msGrantDialog = false;

        await msalInstance.initialize();
        
        const loginResponse = await msalInstance.loginPopup({
          scopes: ['https://graph.microsoft.com/.default'],
          prompt: 'consent'
        });

        const grantedScopes = (get(loginResponse, 'scopes') || []).map(s => s.split('/').pop());

        if (!grantedScopes.includes('Directory.Read.All') || !grantedScopes.includes('Place.Read.All')) {
          this.msGrantDialog = true;
          return;
        }

        let customer_email = get(loginResponse, 'account.username');
        // TODO: Might want to query graph api to validate the domain. locating the correct query prove elusive at this moment.
        let domain = customer_email.split('@').pop();
        let newCustomerData = {
          meeting_manager_email: customer_email,
          domain: domain,
          type: MSFT_CALENDAR_TYPE
        };

        await this.handleSaveCalendarConnection(newCustomerData);

        this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
          message: `Linked Microsoft calendar with ${customer_email}`,
          createdAt: new Date()
        })
      } catch (e) {
        console.error(e);
        this.$store.dispatch('TOAST_ERROR', {
          message: 'Error getting authoirzation from Microsoft Calendar. Please try again.'
        })
      }
    },

    handleLink(type) {
      if (this.customerData.type && this.customerData.type !== type) {
        let otherType =
          type === GGL_CALENDAR_TYPE ? MSFT_CALENDAR_TYPE : GGL_CALENDAR_TYPE;
        this.handleUnlink(otherType);
      }
      if (type === MSFT_CALENDAR_TYPE) {
        this.microsoftLogin();
      } else if (type === GGL_CALENDAR_TYPE) {
        this.googleLogin();
      }
    },
    handleUnlink(calendarType) {
      this.curCalendarSelection = calendarType;
      this.confirmDeleteDialog = true;
    },
    async confirmUnlink() {
      const customerType = clone(this.customerData.type);
      const FieldValue = firebase.firestore.FieldValue;
      await firebase
       .firestore()
       .collection('customers')
       .doc(this.getCustomerId)
       .update({
         client_id: FieldValue.delete(),
         meeting_manager_email: FieldValue.delete(),
         domain: FieldValue.delete(),
         type: FieldValue.delete(),
         access_code: FieldValue.delete()
       });
      this.customerData = { ...this.getCustomerData };
      this.confirmDeleteDialog = false;
      this.curCalendarSelection = null;
      this.$emit('setCalendarData');

      if (customerType === 'microsoft') {
        await msalInstance.logoutPopup();
      } else if (customerType === 'google') {
        if (window.gapi.auth2.getAuthInstance()) {
          window.gapi.auth2.getAuthInstance().signOut();
        }
      }

      this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
        message: `Unlinked ${customerType} calendar`,
        createdAt: new Date()
      })
    },
    /**
     * @param {object} newCustomerData
     * @param {string} newCustomerData.access_code
     * @param {string} newCustomerData.client_id
     * @param {string} newCustomerData.meeting_manager_email
     * @param {string} newCustomerData.type
     */
    async handleSaveCalendarConnection(newCustomerData) {
      let customerData = newCustomerData || {};

      await firebase
        .firestore()
        .collection('customers')
        .doc(this.getCustomerId)
        .update(customerData);

      this.customerData = { ...this.getCustomerData };
      this.$emit('setCalendarData');
    },
    googleConsentRequestScope() {
      return [
        'https://www.googleapis.com/auth/calendar.events',
        'https://www.googleapis.com/auth/calendar.readonly',
        'https://apps-apis.google.com/a/feeds/calendar/resource/',
        // Note: To allow for non-admin access using google admin directory api, viewType attr is required to set to 'domain_public' in the Cloud Service calls
        'https://www.googleapis.com/auth/admin.directory.user.readonly'
      ];
    }
  }
};
</script>

<style></style>
