import firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/firestore";
import store from "@/store";
import logDebug from '@/utils/logDebug';

export default {
    install: (Vue) => {
      const fb = firebase.initializeApp({
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        appId: process.env.VUE_APP_FIREBASE_APP_ID
      });

      const auth = fb.auth();

      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

      if (process.env.VUE_APP_ENV === 'local') {
        firebase.firestore().settings({
          host: 'localhost:9000',
          ssl: false
        });
      }
      
      Vue.prototype.$firebaseAuth = {
        async signIn(customToken) {
          return await firebase.auth().signInWithCustomToken(customToken).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === 'auth/invalid-custom-token') {
              console.error('The token you provided is not valid.', errorMessage);
            } else {
              console.error(error);
            }
          });
        },
        async getClaims() {
          const tokenResult = await firebase.auth()?.currentUser?.getIdTokenResult();
          return tokenResult?.claims;
        },
        async getJwtToken() {
          const tokenResult = await firebase.auth()?.currentUser?.getIdToken();
          return tokenResult;
        }
      };

      Vue.prototype.$firestore = fb.firestore()
     
      auth.onAuthStateChanged(async user => {
        if (user) {
          logDebug(`user is logged in`);
          logDebug(`user is ${user?.email}`);
          store.dispatch('UPDATE_LOGIN_STATE', 'login');
        } else if (store.getters.getLoginState != 'pending-auth') {
          await store.dispatch('UPDATE_LOGIN_STATE', 'login');
        }
      });
    }
  }
  
