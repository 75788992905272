<template>
  <div>
      <v-autocomplete
        v-bind="{ ...$attrs, ...$props }"
        :items="items"
        :search-input.sync="newItem"
        @change="onSelect"
        outlined
        :placeholder="placeholder || fieldConfig.placeholder"
        class="mb-n2"
        :value="value"
      />
  </div>
</template>

<script>
export default {
  name: 'open-select',
  data: () => ({
    newItem: '',
  }),
  props: {
    options: Array,
    fieldConfig: Object,
    value: String,
    placeholder: String,
  },
  methods: {
    onSelect(value) {
      this.$emit('select', { 
        fieldKey: this.fieldConfig.key, 
        value 
      });
      this.$emit('input', value);
    }
  },
  computed: {
    items() {
      return this.newItem
        ? [this.newItem, ...this.options]
        : this.options;
    }
  },
}
</script>

<style>

</style>