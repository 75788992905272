<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h3 class="display-2 font-weight-bold">Spaces</h3>
      </v-col>
    </v-row>
    <v-row v-if="spaces.length">
      <v-col class="d-flex align-center justify-center"  xs="12" sm="12" md="12" lg="6">
        <v-row>
          <v-col xs="12" sm="12" md="12" lg="6">
            <v-text-field
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dark
              dense
              class="search"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" lg="6">
            <sorting-controls :items="sortOptions" :sortBy="sortBy" :sortDesc="sortDesc" @setSortOption="setSortOption"></sorting-controls>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex align-center justify-center" xs="12" sm="12" md="12" lg="6">
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-end">
            <BulkDeskQr :spaceIds="selectedDeskIds" v-if="deskBookingBetaV2Enabled" />
            <turf-btn
              class="mr-2"
              :disabled="!spacesToDeleteSelection.length"
              dense
              @click="showBulkEditModal = true"
            >
              <v-icon color="#ffffff">mdi-pencil</v-icon>
            </turf-btn>
            <turf-btn
              class="mr-2"
              v-if="spaces.length"
              :disabled="!spacesToDeleteSelection.length"
              dense
              color="error"
              @click="deleteDialog = true"
            >
              <v-icon>mdi-delete</v-icon>
            </turf-btn>
            <turf-btn
              @click="showSpaceCreationModal = true"
              :dark="true"
              :dense="true"
              class="sort-button"
              actionBtn
              v-if="hasCheckinEnabled || hasBookingEnabled"
            >
              <v-icon small>mdi-plus</v-icon>
              New Space
            </turf-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dark
          v-if="spaces.length"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :headers="headers"
          :items="spaces"
          :items-per-page="10"
          :search="search"
          @click:row="gotoSpace"
          class="elevation-1 space-table row-pointer"
          show-select
          v-model="spacesToDeleteSelection"
          item-key="spaceId"
        >
        </v-data-table>
        <div 
          v-else-if="hasCheckinEnabled || hasBookingEnabled"
          style="padding: 100px 0; font-size:1.3em;" 
          class="white--text ui-link"
          @click="showSpaceCreationModal = true"
        >Click here to create Spaces</div>
      </v-col>
    </v-row>
    <v-dialog persistent :max-width="spaceCreationModalWidth" v-model="showSpaceCreationModal">
      <space-creation
        v-if="showSpaceCreationModal"
        @onCancel="showSpaceCreationModal = false"
        @onSubmit="onCheckinSpaceCreate"
        @on-modal-width="changeSpaceCreationModalWidth"
      ></space-creation>
    </v-dialog>
    <v-dialog
      v-if="spacesToDeleteSelection && spacesToDeleteSelection.length"
      persistent
      v-model="deleteDialog"
      max-width="700"
    >
      <v-card dark class="pa-8">
        <v-card-title primary-title class="mt-n2">
            <div class="modal-header">
                <h2 class="headline font-weight-bold text-left mb-4">Are you sure you want to delete selected spaces?</h2>
            </div>
        </v-card-title>
        <v-card-text>
          This will also unassign all the displays assigned to them.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            btn-large
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
          <turf-btn
            color="error"
            btn-large
            @click="onDeleteSpace"
          >
            Delete
          </turf-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="600" v-model="showBulkEditModal">
      <SpacesBulkEdit 
        v-if="showBulkEditModal" 
        @on-close="showBulkEditModal = false" 
        :space-ids="spaceIds" 
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SpaceCreation from '@/components/SpaceCreation.vue';
import 'firebase/firestore';
import turfCore from '@skykit-dev/skykit-turf-core';
import { convertArrayToReadable } from '@/utils/coreUtils';
import SpacesBulkEdit from '../components/SpacesBulkEdit.vue';
import BulkDeskQr from '../components/BulkDeskQr.vue';
import resourcesMixin from '../mixins/resources';
import customerMixin from '../mixins/customer';
import featureFlags from '@/mixins/featureFlags';

export default {
  name: 'SpacesComp',
  mixins: [resourcesMixin, customerMixin, featureFlags],
  components: {
    SpaceCreation,
    SpacesBulkEdit,
    BulkDeskQr,
  },
  data: () => ({
    sortBy: 'spaceName',
    showSpaceCreationModal: false,
    showBulkEditModal: false,
    selectedFilterOption: 'All Spaces',
    spaceCreationModalWidth: 800,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'delete'
      },
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'spaceName'
      },
      {
        text: 'Space Type',
        align: 'start',
        sortable: false,
        value: 'readableSpaceType'
      },
      {
        text: 'Building',
        align: 'start',
        sortable: false,
        value: 'buildingName'
      },
      {
        text: 'Floor',
        align: 'start',
        sortable: false,
        value: 'floor'
      },
      {
        text: 'Zone',
        align: 'start',
        sortable: false,
        value: 'zone'
      },
      {
        text: '# Of Displays',
        align: 'start',
        sortable: false,
        value: 'readableDisplayCount'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'isActive'
      }
    ],
    deleteDialog: false,
    spaceToDelete: null,
    spacesToDeleteSelection: [],
  }),
  computed: {
    ...mapGetters(['getSpacesAndDeviceNumber', 'isLoggedIn']),
    getDeleteSpaceNames() {
      const list = this.spacesToDeleteSelection.map(space => space.spaceName);

      return convertArrayToReadable(list);
    },
    spaceIds() {
      return this.spacesToDeleteSelection.map(space => space.spaceId);
    },
    spaces() {
      return this.getSpacesAndDeviceNumber();
    },
    selectedDeskIds() {
      // only enable bulk print button if all selected spaces are desks
      const onlyDesks = this.spacesToDeleteSelection.every(space => space.spaceType === 'desk');
      if (!onlyDesks) {
        return [];
      }

      return this.spacesToDeleteSelection
        .filter(space => space.spaceType === 'desk')
        .map(space => space.spaceId);
    },
  },
  watch: {
    isLoggedIn(cur) {
      if (cur) {
        this.$store.dispatch('GET_MEETING_MANAGER_FEATURE_FLAGS');
      }
    }
  },
  methods: {
    changeSpaceCreationModalWidth(width) {
      this.spaceCreationModalWidth = width;
    },
    async onCheckinSpaceCreate(spaceData) {
      const data = {
        friendly_name: spaceData.name,
        name: spaceData.name,
        friendly_description: '',
        active: true,
        turf_meta: spaceData.turf_meta || {},
      };

      try {
        const newId = await turfCore.customer.space.createCheckinLocation(data);
        this.$router.push({ name: 'Space Detail', params: { id: newId } });
        this.showSpaceCreationModal = false;
      } catch(err) {
        console.error('Unable to create checkin spaceror:', err);
        this.$store.dispatch('TOAST_ERROR', { message: 'Unable to create checkin space' });
      }
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.spaceToDelete = item;
    },
    async onDeleteSpace() {
      try {
        this.spacesToDeleteSelection.forEach(space => {
          if (space.spaceType === 'check-in') {
            turfCore.customer.space.deleteCheckinLocation(space.spaceId);
          } else {
            turfCore.customer.space.deleteSpace(space.spaceId);
          }
        });

      } catch(err) {
        this.$store.dispatch('TOAST_ERROR', {
          message: 'Unable to unpair one or more spaces'
        });
      } finally {
        this.deleteDialog = false;
        this.spaceToDelete = null;
        this.spacesToDeleteSelection = [];
      }
    },
    gotoSpace({ spaceId }) {
      this.$router.push({path: `/space/${spaceId}`});
    },
  },
};
</script>

<style lang="scss">
.row-pointer .v-data-table__wrapper tbody tr :hover {
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.space-table {
  width: 100%;
  background-color: transparent !important;
}
.space-table > table > tr {
  font-size: 60px;
   background-color: none;
}
.left-align {
  padding-left: 50px;
  text-align: left;
}
.search-bar {
  display: flex;
  align-items: center;
  max-width: 70%;
}
.sort-button {
  margin-right: 15px;
}
.pointer {
  cursor: pointer;
}
.truncate {
  width: 45vw;
}
</style>
