<template>
  <div class="d-flex justify-center align-center">
    <v-select
      dense
      dark
      outlined
      hide-details
      :items="items"
      :value="sortBy"
      @change="v => $emit('setSortOption', 'sortBy', v)"
    ></v-select>
    <v-btn dark icon @click="$emit('setSortOption', 'sortDesc')"><v-icon>{{ sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  name: 'SortingControls',
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    sortBy: {
      type: String,
      default: '',
      required: true
    },
    sortDesc: {
      type: Boolean,
      default: true,
      required: true
    }
  }
};
</script>

<style></style>
