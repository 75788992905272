import { TrackJS } from 'trackjs';


/**
 * Dynamically inject a script
 * @param url
 * @returns {Promise<unknown>}
 */
const loadScript = url => {
  return new Promise((resolve, reject) => {
    if (!url) {
      return reject('Unable to load an invalid URL');
    }

    // check if script has already been loaded
    const scripts = document.getElementsByTagName('script');
    let loaded = false;

    if (scripts && scripts.length) {
      for(let idx in scripts) {
        if(!loaded && url === scripts[idx].src) {
          loaded = true;
        }
      }
    }

    if (loaded) {
      return resolve();
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    script.onreadystatechange = () => {
      if (this.readyState == 'complete') {
        resolve();
      }
    };
    script.onerror = (err) => {
      if (TrackJS) {
        TrackJS.track(`Unable to dynamically load a script: ${url}`);
      }
      reject(err);
    };
    script.onload = () => resolve();
    document.body.appendChild(script);
  });
};

export {
  loadScript
}
