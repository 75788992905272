<template>
  <v-main class="setting-body">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col offset="1" cols="6" class="d-flex">
            <h3 class="display-2 font-weight-bold align-self-start text-left">
              Settings
            </h3>
          </v-col>
          <v-col
            cols="4"
            class="d-flex justify-end"
            :class="{ 'special-row': isFormDirty }"
          >
            <turf-btn
              @click="handleSaveCustomerData"
              :disabled="!isFormDirty || !isFormValid"
              class="mr-2"
              dark
              color="success"
            > 
              Save 
            </turf-btn
            >
            <turf-btn @click="handleResetData" dark text >Cancel</turf-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12">
        <v-row>
          <v-col offset="1" cols="10">
            <general-settings :customerData.sync="customerData"> </general-settings>
          </v-col>
        </v-row>
        <v-row  class="mt-n8">
          <v-col offset="1" cols="10">
            <calendar-picker @setCalendarData="handleResetData">
            </calendar-picker>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-n3">
      <v-col offset="1" cols="10">
        <v-card elevation="4" dark class="pa-4">
          <h5 class="headline font-weight-bold text-left">Custom Styling</h5>
          <v-row>
            <v-col cols="6">
              <span class="d-flex flex-column">
                <div>
                  <h6 class="subtitle-1 font-weight-black text-left">
                    Logo
                  </h6>
                  <p class="text-left body-2 mb-2">File size should be less than 2mb.</p>
                </div>
                <v-file-input
                  dark
                  outlined
                  :placeholder="logoFileName"
                  class="flex-grow-1"
                  ref="logoFile"
                  :error-messages="uploadLogoErrorMessage"
                  prepend-icon=""
                  v-model="logoFile"
                >
                  <template v-slot:prepend-inner>
                    <turf-btn small class="mr-2" @click="handleClickUploadLogo">
                      Choose File
                    </turf-btn>
                  </template>
                </v-file-input>
              </span>
              <br />
              <span class="d-flex  flex-column">
                <div>
                  <h6 class="text-left subtitle-1 font-weight-black">
                    Background Image
                  </h6>
                  <p class="text-left body-2 mb-2">File size should be less than 2mb. We recommend 1280px*800px for landscape and 800px*1200px portrait orientation.</p>
                </div>
                <v-file-input
                  dark
                  outlined
                  class="flex-grow-1"
                  :placeholder="backgroundFileName"
                  :error-messages="uploadBackgroundErrorMessage"
                  v-model="backgroundFile"
                  ref="backgroundFile"
                  prepend-icon=""
                >
                  <template v-slot:prepend-inner>
                    <turf-btn
                      small
                      class="mr-2"
                      @click="handleClickUploadBackground"
                    >
                      Choose File
                    </turf-btn>
                  </template>
                </v-file-input>
              </span>      
            </v-col>
            <v-col cols="6">
              <color-picker
                v-if="themeData.primary_color"
                :open="textColorDialog"
                title="Primary Color"
                :colorString="themeData.primary_color"
                :updateColor="updateColor"
                @colorSave="textColorDialog = false"
              >
              </color-picker>
              <br />
              <color-picker
                v-if="themeData.background_color"
                :open="backgroundColorDialog"
                title="Background Color"
                :colorString="themeData.background_color"
                :updateColor="updateColor"
                @colorSave="backgroundColorDialog = false"
              ></color-picker>
              <br />
              <color-picker
                v-if="themeData.font_color"
                :open="backgroundColorDialog"
                title="Font Color"
                :colorString="themeData.font_color"
                :updateColor="updateColor"
                @colorSave="backgroundColorDialog = false"
              >
              </color-picker>
              <br />
              <turf-btn
                dense
                dark
                text
                :disabled="isEqual(this.themeData, this.getDefaultTheme)"
                @click="handleRevertTheme"
                class="mt-5"
              >
                Apply Default Theme
              </turf-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col offset="1" cols="10">
        <space-booking-settings  v-if="customerData.settings" :customerData="customerData" :enable-space-reclaim="spaceReclaimFeatureEnabled" />
      </v-col>
    </v-row>
    <v-row v-if="deskbookingFeatureEnabled">
      <v-col offset="1" cols="10">
        <desk-booking-settings  v-if="customerData.settings" :customerData="customerData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col offset="1" cols="10">
        <check-in-settings
          v-if="customerData.settings"
          ref="checkInSettings"
          v-model="customerData.settings.check_in"
          :customerData="customerData"
          :confirmationFile="confirmationFile"
          @upload="handleConfirmationUpload"
          :uploadConfirmationFileErrorMessage="uploadConfirmationFileErrorMessage"
          :handleClickUploadConfirmationImage="handleClickUploadConfirmationImage"
          :handleClearConfirmationImage="handleClearConfirmationImage"
        ></check-in-settings>
      </v-col>
    </v-row>
    <v-snackbar
      color="success"
      bottom
      left
      :value="uploadSuccess"
      :timeout="timeoutLength"
    >
      Your file(s) was successfully uploaded!
    </v-snackbar>
  </v-main>
</template>

<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import CalendarPicker from '../components/CalendarPicker';
import ColorPicker from '../components/ColorPicker';
import GeneralSettings from '../components/GeneralSettings';
import CheckInSettings from '../components/CheckInSettings';
import DeskBookingSettings from '../components/DeskBookingSettings.vue';
import SpaceBookingSettings from '../components/SpaceBookingSettings';
import { mapGetters } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { loadScript } from '../utils/loadScript';
import { uploadAsset } from '../service/upload';
import { isValidEmail } from '@/utils/coreUtils';
import {
  createNewTheme,
  parseFileNameFromStorageUrl
} from '../utils/themeUtils';
const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

export default {
  name: 'SettingsComp',
  data: () => ({
    timeoutLength: 4000,
    customerData: {},
    themeData: {},
    logoFile: null,
    backgroundFile: null,
    confirmationFile: null,
    textColorDialog: false,
    backgroundColorDialog: false,
    textColor: '#000000',
    backgroundColor: '#000000',
    defaultThemeApplied: false,
    uploadSuccess: false,
    uploadLogoErrorMessage: '',
    uploadBackgroundErrorMessage: '',
    uploadConfirmationFileErrorMessage: '',
    color: '#000000',
    scriptLoadingStarted: false
  }),
  components: {
    CalendarPicker,
    'color-picker': ColorPicker,
    GeneralSettings,
    CheckInSettings,
    DeskBookingSettings,
    SpaceBookingSettings,
  },
  mounted: function() {
    this.handleResetData();
    if (this.isGoogleReady()) {
      // google api is already exists
      // init immediately
      this.onApiLoad();
    } else if (!this.scriptLoadingStarted) {
      // load google api and the init
      this.scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, this.onApiLoad);
    } else {
      // is loading
    }
  },
  async created() {
    try {
      this.customerData = cloneDeep(this.getCustomerData);
    } catch (err) {
      this.$store.dispatch('TOAST_ERROR', {
        message: 'Unable to retrieve data'
      });
    }
  },
  computed: {
    ...mapGetters([
      'getCustomerData',
      'getCustomerId',
      'getThemeData',
      'getDefaultTheme',
      'getLogoFileName',
      'getBackgroundFileName',
      'features'
    ]),
    logoFileName() {
      return parseFileNameFromStorageUrl(this.themeData.logo);
    },
    backgroundFileName() {
      return parseFileNameFromStorageUrl(this.themeData.background_image);
    },
    isFormDirty() {
      if (!isEqual(this.customerData?.settings, this.getCustomerData?.settings)
        || !isEqual(this.themeData, this.getThemeData)
        || this.customerData?.admin_contact_email !== this.getCustomerData?.admin_contact_email
        || this.customerData?.company_name !== this.getCustomerData?.company_name
      ) {
        return true;
      } else if (
        !!this.logoFile ||
        !!this.backgroundFile ||
        !!this.confirmationFile
      ) {
        return true;
      }
      return false;
    },
    isFormValid() {
      if(this.customerData?.admin_contact_email && !isValidEmail(this.customerData?.admin_contact_email)) {
        return false;
      }

      return true;
    },
    deskbookingFeatureEnabled() {
      let featureFlags = this.features.reduce(function(o, val) { o[val.id] = val; return JSON.parse(JSON.stringify(o)) }, {});
      if (!!featureFlags['deskBooking'] && !!this.getCustomerId) {
        return featureFlags['deskBooking'].customers.includes(this.getCustomerId);
      }
      return false;
    },
    spaceReclaimFeatureEnabled() {
      let featureFlags = this.features.reduce(function(o, val) { o[val.id] = val; return JSON.parse(JSON.stringify(o)) }, {});
      if (!!featureFlags['spaceReclaim'] && !!this.getCustomerId) {
        return featureFlags['spaceReclaim'].customers.includes(this.getCustomerId);
      }
      return false;
    }
  },
  watch: {
    logoFile: function(cur) {
      if (!cur) {
        this.uploadLogoErrorMessage = '';
      }
    },
    backgroundFile: function(cur) {
      if (!cur) {
        this.uploadBackgroundErrorMessage = '';
      }
    },
    getThemeData: function() {
      this.themeData = { ...this.getThemeData };
    }
  },
  methods: {
    isEqual: isEqual,
    isGoogleReady() {
      return !!window.gapi;
    },
    isGoogleAuthReady() {
      return !!window.gapi.auth2;
    },
    onApiLoad() {
      window.gapi.load('client:auth2');
    },
    async handleSaveCustomerData() {
      const newCustomerData = {
        company_name: this.customerData.company_name,
        admin_contact_email: this.customerData.admin_contact_email || '',
        settings: {
          booking: {
            adhoc_booking: this.customerData.settings.booking.adhoc_booking || true,
            ondemand_booking_enabled: this.customerData.settings.booking.ondemand_booking_enabled || false,
            checkin_confirmation_enabled: (this.customerData.settings.booking.ondemand_booking_enabled && this.customerData.settings.booking.checkin_confirmation_enabled) || false,
            space_autorelease_timer: this.customerData.settings.booking.space_autorelease_timer || 10,
            meeting_description_required: this.customerData.settings.booking.meeting_description_required || false,
            organizer_required: this.customerData.settings.booking.organizer_required || false,
            desk: this.customerData.settings.booking.desk,
          },
          check_in: {
            ...(this.customerData.settings.check_in || {})
          }
        }
      };

      if (
        !isEqual(this.themeData, this.getThemeData) ||
        this.logoFile ||
        this.backgroundFile ||
        this.confirmationFile
      ) {
        // Check if default theme
        if (
          this.defaultThemeApplied &&
          !this.logoFile &&
          !this.backgroundFile &&
          !this.confirmationFile
        ) {
          newCustomerData.theme_id = this.getDefaultTheme.id;
          await this.$store.dispatch(
            'BIND_THEME_INFO',
            this.getDefaultTheme.id
          );
        }
        // Check if theme already belongs to customer
        else if (this.themeData.name === this.getCustomerId) {
          const newThemeData = createNewTheme({
            ...this.themeData
          });
          await firebase
            .firestore()
            .collection('themes')
            .doc(this.getThemeData.id)
            .update({ ...newThemeData });
          await this.$store.dispatch('BIND_THEME_INFO', this.getThemeData.id);
        } else {
          // Else create a new one
          const newThemeData = createNewTheme({
            ...this.themeData,
            name: this.getCustomerId,
            is_default: false,
            is_public: false
          });
          const newThemeRef = await firebase
            .firestore()
            .collection('themes')
            .add(newThemeData);
          newCustomerData.theme_id = newThemeRef.id;
          await this.$store.dispatch('BIND_THEME_INFO', newThemeRef.id);
        }
      }

      await firebase
        .firestore()
        .collection('customers')
        .doc(this.getCustomerId)
        .set(newCustomerData, {
          merge: true
        });

      if (this.logoFile) {
        try {
          await uploadAsset({
            customerId: this.getCustomerId,
            file: this.logoFile,
            location: 'logo'
          });
          this.uploadSuccess = true;
          this.logoFile = null;
        } catch (err) {
          this.uploadLogoErrorMessage = err.message;
        }
      }
      if (this.backgroundFile) {
        try {
          await uploadAsset({
            customerId: this.getCustomerId,
            file: this.backgroundFile,
            location: 'background'
          });
          this.uploadSuccess = true;
          this.backgroundFile = null;
        } catch (err) {
          this.uploadBackgroundErrorMessage = err.message;
        }
      }
      if (this.confirmationFile) {
        try {
          await uploadAsset({
            customerId: this.getCustomerId,
            file: this.confirmationFile,
            location: 'confirmation'
          });
          this.uploadSuccess = true;
          this.confirmationFile = null;
        } catch (err) {
          this.uploadConfirmationFileErrorMessage = err.message;
        }
      }
      this.handleResetData();
    },
    handleResetData() {
      this.themeData = { ...this.getThemeData };
      this.customerData = cloneDeep(this.getCustomerData);
      this.defaultThemeApplied = false;
      setTimeout(() => {
        this.uploadSuccess = false;
      }, this.timeoutLength);
    },
    convertHexaTohex(color) {
      // temporary fix while there is no way to disable the alpha channel in the colorpicker component: https://github.com/vuetifyjs/vuetify/issues/9590
      if (color.toString().match(/#[a-zA-Z0-9]{8}/)) {
        return color.substr(0, 7);
      }
      return color;
    },
    updateColor(colorTitle, newColor) {
      switch (colorTitle) {
        case 'Primary Color':
          this.themeData.primary_color = this.convertHexaTohex(newColor);
          break;
        case 'Background Color':
          this.themeData.background_color = this.convertHexaTohex(newColor);
          break;
        case 'Font Color':
          this.themeData.font_color = this.convertHexaTohex(newColor);
          break;
        default:
          return;
      }
    },
    handleRevertTheme() {
      this.themeData = { ...this.getDefaultTheme };
      this.defaultThemeApplied = true;
    },
    handleClickUploadLogo() {
      const input = get(this, '$refs.logoFile.$refs.input', null)
      return input
        ? input.click()
        : console.error('Could not trigger logo upload');
    },
    handleClickUploadBackground() {
      const input = get(this, '$refs.backgroundFile.$refs.input', null)
      return input
        ? input.click()
        : console.error('Could not trigger background upload');
    },
    handleClickUploadConfirmationImage() {
      const input = get(this, '$refs.checkInSettings.$refs.confirmationFile.$refs.input', null)
      return input
        ? input.click()
        : console.error('Could not trigger confirmation upload');
    },
    handleClearConfirmationImage() {
      this.themeData.confirmation_image = '';
    },
    handleConfirmationUpload(file) {
      this.confirmationFile = file
    }
  }
};
</script>

<style>
.special-row {
  position: fixed;
  right: 103px;
  z-index: 1000;
  background: #212121;
  width: 210px;
}
.setting-body {
  padding-bottom: 50px;
}
.neg-margin {
  padding-bottom: -25px;
}
.wide-right-margin {
  margin-right: 100px;
}
</style>