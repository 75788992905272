const getPermissions = (resp) => {
    const d = resp?.identity?.permissions.map(x => x.lid);
    
    if (d && d.length) {
        return d;
    } else {
        console.debug(`User has no permissions`);
        return [];
    }
};

const hasMultipleTenants = resp => ~~resp?.identity?.user?.permits?.edges?.length > 1

export { getPermissions, hasMultipleTenants };
