<template>
  <v-card dark elevation="4">
    <v-card-title class="px-4">
      <h5 class="headline font-weight-bold text-left">
        Space Booking Settings
      </h5>
    </v-card-title>

    <div class="pa-4">
      <v-row>
        <v-col cols="12">
          <span class="d-flex flex-column">
            <!-- On-Demand Booking enable/disable -->
            <div>
              <h6 class="text-left subtitle-1 font-weight-black">
                On-Demand Booking
              </h6>
              <p class="text-left body-2">
                Users can book directly from space display.
              </p>
              <v-select
                outlined
                dense
                :value="customerData.settings.booking.ondemand_booking_enabled"
                @input="update($event, 'ondemand_booking_enabled')"
                item-text="text"
                item-value="value"
                :items="enableDisableOptions"
              ></v-select>
            </div>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <span class="d-flex flex-column">
            <div class="pl-6" v-if="customerData.settings.booking.ondemand_booking_enabled">
              <h6 class="text-left subtitle-1 font-weight-black">
                Additional Required Information
              </h6>
              <p class="text-left body-2">
               Set the required information your employees need to enter in order to book a space for a meeting.
              </p>
              <!-- not ready yet -->
              <!-- <p class="text-left body-2">
                <v-checkbox v-model="customerData.meeting_description_required" label="Meeting description" ></v-checkbox>
              </p> -->
              <p class="text-left body-2">
                <v-checkbox :value="customerData.settings.booking.organizer_required" @change="update($event, 'organizer_required')" ></v-checkbox>
              </p>
            </div>

            <!-- Space Reclaim enable/disable -->
            <div v-if="enableSpaceReclaim"> <!-- feature flag --->
              <div class="mt-6" v-if="customerData.settings.booking.ondemand_booking_enabled">
              <h6 class="text-left subtitle-1 font-weight-black">
                Space Reclaim
              </h6>
              <p class="text-left body-2">
                Spaces that aren't checked into  for meetings will be reclaimed.
              </p>
              <v-select
                outlined
                dense
                :value="customerData.settings.booking.checkin_confirmation_enabled"
                @input="update($event, 'checkin_confirmation_enabled')"
                item-text="text"
                item-value="value"
                :items="enableDisableOptions"
              ></v-select>
            </div>

              <!-- Space Reclaim auto-release -->
              <div class="mt-6" v-if="customerData.settings.booking.checkin_confirmation_enabled">
                <h6 class="text-left subtitle-1 font-weight-black">
                  Space reclaim timer
                </h6>
                <p class="text-left body-2">
                  Amount of time users have to check in after a meeting starts before a space is automatically reclaimed.
                </p>
                <v-select
                  outlined
                  dense
                  :value="customerData.settings.booking.space_autorelease_timer"
                  @change="update($event, 'space_autorelease_timer')"
                  item-text="text"
                  item-value="value"
                  :items="autoReseaseTimerOptions"
                ></v-select>
              </div>
            </div>

          </span>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'space-booking-settings',
  props: {
    enableSpaceReclaim: Boolean,
    customerData: {
      default: () => ({}),
      type: Object
    }
  },
  data() {
    return {
      enableDisableOptions: [
        { text: 'Enable', value: true, },
        { text: 'Disable', value: false, },
      ],
      autoReseaseTimerOptions: [
        { text: '5 minutes', value: 5, },
        { text: '10 minutes', value: 10, },
        { text: '15 minutes', value: 15, },
      ]
    }
  },
  methods: {
    update(e, prop) {
      const cloneObj = Object.assign({}, this.customerData);

      if (prop in cloneObj.settings.booking) {
        cloneObj.settings.booking[prop] = e;
        this.$emit('input', cloneObj);
        this.$emit('change', cloneObj);
      } else {
        console.error(`Could not update property: ${prop}`)
      }
    }
  }
};
</script>

<style></style>
