<template>
    <v-card dark class="pa-4">
        <v-card-title primary-title>
            <div class="modal-header">
                <v-btn class="close-button" icon @click="close"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
                <h2 class="headline font-weight-bold text-left mb-4">Edit</h2>
                Select the fields you want to edit
            </div>
            <div class="mt-5 body-1" style="word-break: keep-all;">
              <strong>Note</strong>: You can create new options for Building, Floor, and Zone by typing in the selection field.
            </div>
        </v-card-title>
        <v-card-text class="mt-8">
            <v-row
                v-for="field of editableFields"
                :key="field.key"
                class="pl-3"
                dense
            >
                <v-col sm="5">
                    <v-checkbox
                        :disabled="hasCheckInType && field.key === 'space_type'"
                        v-model="field.active"
                        :label="field.label"
                    ></v-checkbox>
                </v-col>
                <v-col sm="6">
                    <OpenSelect
                        v-if="field.type === 'string'"
                        :field-config="field"
                        :disabled="!field.active"
                        :options="getFieldsOptions(field.key)"
                        @select="onSelect"
                    />
                    <BinarySelect 
                        v-if="field.type === 'boolean'" 
                        outlined
                        class="mb-n2"
                        :true-value="true"
                        :false-value="false"
                        true-label="Active"
                        false-label="Inactive"
                        :placeholder="field.placeholder"
                        :disabled="!field.active"
                        @change="onSelect({ fieldKey: field.key, value: $event })"
                    />
                    <v-select 
                        v-else-if="field.type === 'select'"
                        outlined
                        class="mb-n2"
                        :items="field.options"
                        :placeholder="field.placeholder"
                        :disabled="!field.active"
                        @change="onSelect({ fieldKey: field.key, value: $event })"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-5 pb-5 pt-0">
            <h3 class="mr-5" style="opacity: .64">{{ this.spaceIds.length.toString() + (this.spaceIds.length > 1 ? ' spaces' : ' space') }} selected</h3>
            <turf-btn 
                color="success" 
                @click="onSave"
                :btn-large="true" 
                :disabled="saveDisabled"
                v-text="'update'"
            />
        </v-card-actions>
    </v-card>
</template>

<script>
import OpenSelect from "./OpenSelect.vue";
import turfCore from '@skykit-dev/skykit-turf-core';
import BinarySelect from './BinarySelect.vue';
import set from 'lodash/set';
import get from 'lodash/get';
import spaceMetaMixin from '../mixins/spaceMetaEdit';

export default {
    name: "spaces-bulk-edit",
    mixins: [spaceMetaMixin],
    components: {
        OpenSelect,
        BinarySelect,
    },
    props: {
        spaceIds: {
            tyoe: Array,
            default: () => [],
        },
    },
    computed: {
        saveDisabled() {
            // enable save button only if there is any field checked and with a value seted
            return !this.editableFields.find(f => f.active && this.selectedValues[f.key] !== undefined);
        },
        hasCheckInType() {
            const spaces = this.spaces.filter(({ id }) => this.spaceIds.includes(id));
            return !!spaces.find(({ space_type }) => space_type === 'check-in');
        },
    },
    methods: {
        close() {
            this.editableFields.map(ef => ef.active = false); // reset
            this.$emit("on-close");
        },

        /**
         * Save the new values infto selected spaces
         */
        async onSave() {
            const spaceData = { turf_meta: {} };
            
            Object.keys(this.selectedValues).forEach((fieldKey) => {
                const isFieldActive = this.editableFields.find(ef => ef.key === fieldKey)?.active;

                if (!isFieldActive) {
                    // field value was selected and then unchecked the field, so value is stil in the state but it should not change.
                    return;
                }

                const fieldValue = get(this.selectedValues, fieldKey, '');
                set(spaceData, fieldKey, fieldValue);
            });

            this.spaceIds.forEach((spaceId) => {
                const currentSpaceData = this.spaces.find(s => s.id === spaceId);
                try {
                    turfCore.customer.space.update(spaceId, spaceData, currentSpaceData.space_type);
                } catch (error) {
                    this.$store.dispatch('TOAST_ERROR', {
                        message: 'Error updating spaces, some spaces may not have been updated successfully.'
                    })
                }
            });

            this.close();
        },
    },
};
</script>
