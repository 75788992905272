<template>
  <v-main class="px-0 py-5 mx-5">
    <v-form ref="form">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-around">
          <v-file-input
            label="Upload CSV"
            class="mr-3"
            outlined
            v-model="localFile"
            @change="handleFileUpload"
            prepend-icon=""
          ></v-file-input>
          <!-- switch turned off and fixed to 'checkin_employees' -->
          <!-- <v-select
            outlined
            class="mx-3"
            v-model="csvType"
            :items="csvTypes"
            disabled
          ></v-select> -->
        </v-col>
      </v-row>
      <v-row v-if="data.length">
        <v-col cols="12">
          <p class="text-left">Number of Users found: {{ data.length }}</p>
          <p class="text-left">Please select the corresponding headers:</p>
          <div
            v-for="field in fields"
            :key="field.name"
            class="d-flex align-baseline"
          >
            <span>{{ field.name }}*</span>:
            <v-select
              :rules="[isRequiredRule(field.name)]"
              class="px-5"
              dense
              outlined
              v-model="formData[field.dataModel]"
              :items="headers"
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <turf-btn text class="mr-5" @click="handleClearData">
          Cancel
        </turf-btn>
        <turf-btn
          :disabled="!isValid"
          color="success"
          class="mr-5"
          @click="handleSaveData"
          actionBtn
        >
          Upload CSV
        </turf-btn>
      </div>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </v-form>
  </v-main>
</template>

<script>
import Papa from 'papaparse';
import isEmpty from 'lodash/isEmpty';
import 'firebase/firestore';
import { mapGetters } from 'vuex';
import axios from 'axios';

const defaultState = () => ({
  headers: [],
  data: [],
  formData: {
    emailHeader: '',
    firstNameHeader: '',
    lastNameHeader: '',
    phoneNumberHeader: ''
  },
  isLoading: false,
  localFile: null,
  fields: [
    {
      name: 'Email',
      dataModel: 'emailHeader'
    },
    {
      name: 'First Name',
      dataModel: 'firstNameHeader'
    },
    {
      name: 'Last Name',
      dataModel: 'lastNameHeader'
    },
    {
      name: 'Phone Number',
      dataModel: 'phoneNumberHeader'
    }
  ],
  csvTypes: [
    {
      text: 'Employees',
      value: 'checkin_employees'
    }
  ],
  csvType: 'checkin_employees',
  isRequiredRule: field => v => !!v || `${field} is Required!`,
  isValid: false
});

export default {
  name: 'UploadUserContent',
  data() {
    return defaultState();
  },
  props: {
    syncDirectoryEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['getCustomerId'])
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        if (!isEmpty(val)) {
          this.validateForm();
        }
      }
    }
  },
  methods: {
    handleFileUpload(file) {
      try {
        Papa.parse(file, {
          header: true,
          complete: result => {
            const { data = [], meta = {} } = result;
            
            //trim white space from record fields
            this.data = data.map(record => {
              return Object.keys(record).reduce((agg, key) => {
                agg[key] = record[key] ? record[key].trim() : ''
                return agg;
              }, {})
            });

            // Attempt to find matching fields
            this.headers = meta.fields;
            meta.fields.forEach(field => {
              const lowerField = field.toLowerCase();
              if (lowerField.includes('email')) {
                this.formData.emailHeader = field;
              } else if (
                lowerField.includes('first') &&
                lowerField.includes('name')
              ) {
                this.formData.firstNameHeader = field;
              } else if (
                lowerField.includes('last') &&
                lowerField.includes('name')
              ) {
                this.formData.lastNameHeader = field;
              } else if (lowerField.includes('phone')) {
                this.formData.phoneNumberHeader = field;
              }
            });
          }
        });
      } catch (err) {
        this.$store.dispatch('TOAST_ERROR', { message: err.message });
      }
    },
    async handleSaveData() {
      this.isLoading = true;
      try {
        axios.put(
          process.env.VUE_APP_PROCESS_JSON_URL,
          {
            data: this.data,
            type: this.csvType,
            headers: { ...this.formData },
            customerId: this.getCustomerId,
            syncDirectoryEnabled: this.syncDirectoryEnabled
          }
        );
        this.$emit('onComplete', {
          numOfEmployees: this.data.length
        });
        this.isLoading = false;
        this.handleClearData();
      } catch (err) {
        this.$store.dispatch('TOAST_ERROR', { message: err.message });
      }
    },
    handleClearData() {
      Object.assign(this.$data, defaultState());
      this.$emit('onClose');
    },
    validateForm() {
      this.isValid = this.$refs.form.validate() && this.data.length;
    }
  }
};
</script>

<style></style>
