import { v4 as uuidv4 } from 'uuid';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';

const defaultState = () => ({
  queue: [],
  active: false
});

const timeoutConstant = 5000;

const toast = {
  state: defaultState(),
  getters: {
    getToastState: state => state.queue
  },
  actions: {
    async TOAST_SUCCESS ( { commit }, data ) {
      let toast = {
        id: uuidv4(),
        active: true,
        color: 'success',
        timeout: timeoutConstant,
        ...data
      };
      commit('TOAST_ENQUEUE', toast);
      return toast.id;
    },
    async TOAST_ERROR ( { commit }, data ) {
      console.log('TOAST_ERROR', data)
      let toast = {
        id: uuidv4(),
        active: true,
        color: 'error',
        timeout: timeoutConstant,
        ...data
      };
      commit('TOAST_ENQUEUE', toast);
      return Promise.resolve(toast.id);
    },
    async TOAST_LOG ( { commit }, data ) {
      let toast = {
        id: uuidv4(),
        active: true,
        color: 'black',
        timeout: timeoutConstant,
        ...data
      };
      commit('TOAST_ENQUEUE', toast);
      return toast.id;
    },
    async TOAST_DISMISS ({ commit },  toastId) {
      commit('TOAST_DEQUEUE_MANUAL', toastId);
    },
    async TOAST_UPDATE ({ state }, data) {
      let id = await data.id;
      if (!id && !data.message) return;

      forEach(state.queue, toast => {
        if (toast.id === id) {
          toast.message = data.message;
        }
      });
    }
  },
  mutations: {
    TOAST_ENQUEUE: (state, toast) => {
      state.queue.push(toast);
    },
    TOAST_DEQUEUE: (state) => {
      if (!state.queue.length) return;
      state.queue.pop();
      state.active = state.queue.length;
    },
    TOAST_DEQUEUE_MANUAL: (state, toastId) => {
      let index = findIndex(state.queue, {'id': toastId});
      if (index < 0 || !state.queue.length) return;
      state.queue.splice(index, 1);
      state.active = state.queue.length;
    },
    RESET_STATE: (state) => {
      Object.assign(state, defaultState());
    }
  },
  

};

export default toast;
