<template>
  <v-container class="user-avatar" v-if="isLoggedIn">
    <div class="mr-2">
      <product-selection />
    </div>
    <v-menu open-on-hover bottom left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar dark v-bind="attrs" v-on="on" size="30">
          <v-img
            v-if="photoUrl"
            :src="photoUrl"
            height="30"
            width="30"
          ></v-img>
          <span
            v-else
            class="text-uppercase alt-avatar-bg"
          >
            {{ initials }}
          </span>
        </v-avatar>
      </template>

      <v-list dark>
        <v-list-item class="pr-0">
          <v-list-item-content>
            <v-list-item-subtitle>LOGGED IN AS</v-list-item-subtitle>
            <v-list-item-title>{{ email }}</v-list-item-title>
            <v-list-item-subtitle class="mt-3">TENANT</v-list-item-subtitle>
            <v-list-item-title>{{ getTenantCode }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isPlatformAdmin">
          <turf-btn class="mb-3" block @click="$router.push({name: 'FeatureSettingsView'})">
            Feature Flag Settings
          </turf-btn>
        </v-list-item>
        <v-list-item v-if="isPlatformAdmin">
          <turf-btn class="mb-3" block @click="$router.push({name: 'CustomerLogs'})">
            Customer Logs
          </turf-btn>
        </v-list-item>
        <v-list-item v-if="enableTenantChange">
          <turf-btn block @click="onChangeTenant">
            CHANGE TENANT
          </turf-btn>
        </v-list-item>
        <v-list-item>
          <turf-btn block @click="handleLogout">
            Log Out
          </turf-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import get from 'lodash/get';
import { mapGetters, mapState } from 'vuex';
import { Cookie } from '@skykit-dev/skykit_login_utils';
import { onRedirect } from '@/utils/redirect';
import ProductSelection from "@/components/ProductSelection.vue";
import Emitter from '@/utils/pubsub';

export default {
  name: 'UserAvatar',
  components: {
    ProductSelection
  },
  data: () => ({
    activePanel: null,
    enableTenantChange: false,
  }),
  created() {
    Emitter.on('enable-tenant-change', cur => this.enableTenantChange = cur);
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isPlatformAdmin']),
    ...mapState(['auth']),
    firstName() {
      const displayName = get(this.auth, 'user.displayName');
      return displayName.split(' ')[0];
    },
    initials() {
      const displayName = get(this.auth, 'user.displayName', '');
      const userEmail = get(this.auth, 'user.email');

      if (displayName) {
        return displayName.substring(0, 1);
      }
      if (userEmail) {
        return userEmail.substring(0, 1);
      }
      return 'S';
    },
    email() {
      return get(this.auth, 'user.email');
    },
    photoUrl() {
      return get(this.auth, 'user.photoURL', '')
    },
    getTenantCode() {
      return get(this.auth, 'tenantCode');
    }
  },
  methods: {
    get: get,
    async handleLogout() {
      await this.$store.dispatch('LOG_OUT');
    },

    onPanelExpanded() {
      this.isPanelExpand = !this.isPanelExpand;

      if (!this.isPanelExpand) {
        this.navMaxHeight = null;
      }
    },
    onChangeTenant() {
      Cookie.create('referrer', JSON.stringify({
      // Get top level path only incase another tenant is unable to access detail pages
        redirectUrl: `${window.location.protocol}//${window.location.host}/spaces`,
        app: 'turf'
      }));

      onRedirect(true);
    }
  }
};
</script>

<style>
.user-avatar {
  display: flex;
  justify-content: flex-end;
}
.alt-avatar-bg {
  height: 100%;
  width: 100%;
  background: #6e2ed2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
</style>
