<template>
  <div class="login flex">
    <div id="logo" class="mb-12">
      <v-img
        src="@/assets/login_logo.png"
        max-height="150"
        max-width="350"
      ></v-img>
    </div>
    <v-progress-circular
      v-if="getLoginState == 'pending' || getLoginState == 'pending-auth'"
      indeterminate
      size="45"
    ></v-progress-circular>
    <v-dialog dark v-model="getDisableApp" persistent max-width="50%">
      <v-card>
        <v-card-text class="pa-10"
          >You do not have permissions to access Skykit Turf Console. Please
          contact your administrator.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="logOut"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LandingPage",
  computed: {
    ...mapGetters(["isLoggedIn", "getLoginState", "getDisableApp"]),
  },
  methods: {
    logOut() {
      this.$store.dispatch("LOG_OUT");
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
