<template>
    <div>
        <div class="qr-container pt-5">
            <div class="mb-3">{{qrShortUrl}}</div>
            <div id="printable-qr">
                <div class="qr-printable-class">
                    <div class="data" v-if="spaceData">
                        <h1>{{ spaceName }}</h1>
                        <div class="metadata">
                            <span v-if="buildingName">
                                {{ buildingName }}<span v-if="zone || floor">, </span>
                            </span>
                            <span v-if="floor">
                                floor {{ floor }}<span v-if="zone">, {{ zone }}</span>
                            </span>
                            <span v-else-if="zone">{{ zone }}</span>
                        </div>
                    </div>
                    <!-- QR for generating download image -->
                    <QrcodeVue
                        id="qrcanvas"
                        :value="qrData"
                        :size="size"
                        class="qr-hidden"
                        v-show="false"
                    />
                    <!-- QR for display and print -->
                    <QrcodeVue
                        :value="qrData"
                        :size="size"
                        class="qr"
                        render-as="svg"
                    />
                    <iframe name="qrprintpage" style="display: none"></iframe>
                </div>
            </div>
            <div class="buttons mt-2 mb-n3">
                <v-btn
                    text
                    class="mr-3"
                    @click="download"
                    v-if="showDownloadButton"
                    >DOWNLOAD
                </v-btn>
                <v-btn text @click="print" v-if="showPrintButton">PRINT</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import get from "lodash/get";
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import VueHtmlToPaper from "vue-html-to-paper";
import { mapState, mapGetters } from "vuex";
import { getSpaceShortUrl } from '@/utils/checkInUtils';
import checkinUtils from '@skykit-dev/skykit-turf-core/utils/checkin.util';

const spaceShortUrlStorageKeyPrefix = 'space-short-url';
const checkinShortUrlStorageKeyPrefix = 'checkin-short-url';

Vue.use(VueHtmlToPaper, {
    name: 'qrprintpage', // the name of the iframe window
    specs: ['fullscreen=no', 'titlebar=no', 'scrollbars=no'],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    styles: ['/assets/qrstyles.css'], // the printable QR styles page
});

export default {
    name: 'space-qu',
    components: {
        QrcodeVue,
    },
    data: () => ({
        qrShortUrl: null,
    }),
    props: {
        type: String, // 'desk', 'checkin', ...
        spaceId: String,
        locationId: String,
        showPrintButton: {
            type: Boolean,
            default: true,
        },
        showDownloadButton: {
            type: Boolean,
            default: true,
        },
        size: {
            // the QR size
            type: Number,
            default: 600,
        },
    },
    mounted() {
        this.getQrUrl();
    },
    methods: {
        getQrUrl() {
            if (this.locationId) {
                    this.getCheckinShortUrl(this.locationId);
            } else {
                this.getSpaceShortUrl(this.spaceId);
            }
        },
        async print() {
            await this.$htmlToPaper('printable-qr');
        },
        async getSpaceShortUrl() {
            this.qrShortUrl = localStorage.getItem(`${spaceShortUrlStorageKeyPrefix}-${this.spaceId}`);

            if (!this.qrShortUrl) {
                this.qrShortUrl = await getSpaceShortUrl(this.spaceId);
            }
            if (this.qrShortUrl) {
                localStorage.setItem(`${spaceShortUrlStorageKeyPrefix}-${this.spaceId}`, this.qrShortUrl );
            } 
        },
        async getCheckinShortUrl() {
            this.qrShortUrl = localStorage.getItem(`${checkinShortUrlStorageKeyPrefix}-${this.locationId}`);

            if (!this.qrShortUrl) {
                this.qrShortUrl = await checkinUtils.getShortUrl({
                    locationId: this.locationId,
                    customerId: this.getCustomerId,
                    contactEmail: get(this.getCustomerData, 'admin_contact_email') || '',
                }).catch(() => '');
            }
            if (this.qrShortUrl) {
                localStorage.setItem(`${checkinShortUrlStorageKeyPrefix}-${this.locationId}`, this.qrShortUrl );
            } 
        },
        download() {
            const canvas = document.getElementById('qrcanvas').children[0]; // <-- the canvas object
            const pngUrl = canvas
                .toDataURL('image/png')
                .replace('image/png', 'image/octet-stream');
            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `qr-${
                this.spaceId || this.locationId || 'code'
            }.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
    },
    computed: {
        ...mapState(['bookingSpaces', 'checkInSpaces']),
        ...mapGetters(['getCustomerId', 'getCustomerData']),
        spaceData() {
            if (this.spaceId) {
                return this.bookingSpaces.find((s) => s.id === this.spaceId);
            }
            if (this.locationId) {
                return this.checkInSpaces.find((s) => s.id === this.locationId);
            }
            return null;
        },
        spaceName() {
            return get(this.spaceData, 'turf_meta.space_name') || this.spaceData?.name || '';
        },
        buildingName() {
            return get(this.spaceData, 'turf_meta.building_name') || '';
        },
        floor() {
            return get(this.spaceData, 'turf_meta.floor') || '';
        },
        zone() {
            return get(this.spaceData, 'turf_meta.zone') || '';
        },
        qrData() {
            const baseUrl = process.env.VUE_APP_SHORT_LINK_BASE_URL;
            const data = {
                customerId: this.$store.getters.getCustomerId,
                locationId: this.locationId || undefined,
                spaceId: this.spaceId || undefined,
                type: this.type || undefined,
            };
            const id = btoa(JSON.stringify(data));
            return `${baseUrl}${id}`;
        },
    },
};
</script>

<style lang="scss">
.qr-container {
    align-content: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    iframe {
        height: 0;
        width: 0;
    }

    #printable-qr {
        align-self: center;
        background: #fff;
        border-radius: 4px;
        padding: 10px;

        .data {
            display: none;
        }

        .qr,
        svg {
            height: 200px !important;
            width: 200px !important;
        }
    }
}
</style>