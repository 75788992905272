<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h3 class="display-2 font-weight-bold">Reports</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" >
        <h2 class="text-left font-weight-bold mb-6">
          Check-In / Check-Out Activity
        </h2>
        <div class="d-flex justify-space-between">
          <sorting-controls
            :items="sortOptions"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            @setSortOption="setSortOption"
          ></sorting-controls>
          <download-csv :data="csvData">
            <turf-btn actionBtn>Export CSV</turf-btn>
          </download-csv>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dark
          :loading="pageLoading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :headers="activityLogHeaders"
          :items="data"
          :items-per-page="50"
          class="elevation-1"
          :style="{ backgroundColor: 'transparent !important' }"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options':[50],
            'show-current-page': true,
            pagination: {
              pageStart: options.page === 1 ? 0 : getActivityCount < 100 ? getActivityCount - 50 : options.page * 50,
              pageStop: options.page === 1 ? 50 : getActivityCount + 50,
              itemsPerPage: 50,
              itemsLength: getActivityCount
            }
          }"
        >
          <template v-slot:item.checkedOutAt="{ item }">
            <span v-if="item.checkedOutAt">
              {{ item.checkedOutAt }}
            </span>
            <turf-btn
              v-else
              small
              color="error"
              @click="handleCheckout(item.id)"
            >
              Checkout
            </turf-btn>
          </template>
          <template v-slot:item.location="{ item }">
            {{ get(item, 'location.friendly_name') || '' }}
          </template>
          <template #footer.prepend>
            <v-spacer/>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'firebase/firestore';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import firebase from 'firebase/app';
import SortingControls from '../components/SortingControls.vue';
export default {
  name: 'ReportsComp',
  components: {
    SortingControls
  },
  data() {
    return {
      sortBy: 'checkedInAt',
      sortDesc: true,
      options: {},
      pageLoaded: {},
      activityLogHeaders: [
        {
          text: 'First Name ',
          align: 'start',
          sortable: false,
          value: 'firstName',
          width: 120
        },
        {
          text: 'Last Name ',
          align: 'start',
          sortable: false,
          value: 'lastName',
          width: 120
        },
        {
          text: 'Email ',
          align: 'start',
          sortable: false,
          value: 'email',
          width: 120
        },
        {
          text: 'Phone ',
          align: 'start',
          sortable: false,
          value: 'phoneNumber',
          width: 120
        },
        {
          text: 'Location ',
          align: 'start',
          sortable: false,
          value: 'location',
          width: 110
        },
        {
          text: 'User Type ',
          align: 'start',
          sortable: false,
          value: 'userType',
          width: 120
        },
        {
          text: 'Host',
          align: 'start',
          sortable: false,
          value: 'host',
          width: 120
        },
        {
          text: 'Checked In At ',
          align: 'start',
          sortable: false,
          value: 'checkedInAt',
          width: 140
        },
        {
          text: 'Checked Out At ',
          align: 'start',
          sortable: false,
          value: 'checkedOutAt',
          width: 155
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['getActivitiesWithData', 'getCustomerId', 'pageLoading', 'getActivityCount', 'getLoginState']),
    csvData() {
      return this.getActivitiesWithData.map(x => {
        const locationName = get(x, 'location.name') || '';
        const newRecord = { ...x, location: locationName };
        delete newRecord.id;
        return newRecord;
      });
    },
    sortOptions() {
      return this.activityLogHeaders.reduce((options, header) => {
        const { text, value } = header
        if (text && value) {
          options.push({ text, value })
        }
        return options
      }, [])
    },
    data() {
      return this.getActivitiesWithData.map(x => {
        const userType = x.userType === 'guest' ? 'Visitor' : 'Employee'
        return {...x, userType}
      })
    }
  },
  watch: {
    options: {
      handler (cur, prev) {
        if (!isEqual(cur, prev)) {
          // if pages aren't the same and page has not been loaded
          if ((cur.page !== prev.page) && (cur.page > prev.page) && !this.pageLoaded[cur.page]) {
            this.$store.dispatch('GET_MORE_ACTIVITIES', {limit: this.options.itemsPerPage, sortBy: this.sortBy, desc: this.sortDesc});
            this.pageLoaded[cur.page] = true;
          }
        }
      },
      deep: true,
    },
    getLoginState: {
      immediate: true,
      handler(cur) {
        if (cur === 'login') {
          this.$store.dispatch('BIND_ACTIVITIES', {limit: this.options.itemsPerPage, sortBy: this.sortBy, desc: this.sortDesc});
          this.$store.dispatch('GET_ACTIVITES_COUNT');
        }
      }
    }
  },
  methods: {
    get,
    setSortOption(name, value) {
      if (name === 'sortBy') {
        this.sortBy = value
      } else if (name === 'sortDesc') {
        this.sortDesc = !this.sortDesc
      }

      this.pageLoaded = {};
      this.$store.dispatch('BIND_ACTIVITIES', {limit: this.options.itemsPerPage, sortBy: this.sortBy, desc: this.sortDesc});
    },
    handleCheckout(recordId) {
      const time = firebase.firestore.Timestamp.now();
      firebase
        .firestore()
        .collection('customers')
        .doc(this.getCustomerId)
        .collection('checkin_logs')
        .doc(recordId)
        .update({ checkedOutAt: time, checkOutMethod: 'console' });
    }
  }
};
</script>

<style></style>
