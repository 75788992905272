import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueGtm from "vue-gtm";
import { firestorePlugin } from "vuefire";
import { TrackJS } from "trackjs";
import pkg from "../package.json";
import firebaseAuth from '@/plugins/firebaseAuth';
import JsonCSV from 'vue-json-csv'
import TurfBtn from '@/components/TurfBtn.vue'
import { setDebugEnabled } from '@/utils/logDebug';
import { LoginAuth } from '@skykit-dev/skykit_login_utils';

setDebugEnabled(location.hostname.includes('localhost'));

console.log(`UI_APP_VERSION: ${pkg.version}`);

if (location.host.indexOf("localhost") !== 0) {
  TrackJS.install({
    token: process.env.VUE_APP_TRACKJS_KEY,
    application: process.env.VUE_APP_TRACKJS_PROJECT_ID,
    version: pkg.version
  });
  Vue.config.errorHandler = (err, vm, info) => {
    if (vm.$options.propsData) {
      TrackJS.console.log("Props passed to component", vm.$options.propsData);
    }

    let infoMessage = `Error in component: <${vm.$options.name} />\n`;
    if (info) {
      infoMessage += `Error occurred during lifecycle hook: ${info}\n`;
    }

    TrackJS.console.log(infoMessage);
    TrackJS.track(err);
  };
}

Vue.use(firebaseAuth);
Vue.use(firestorePlugin);
Vue.config.productionTip = false;
Vue.component('downloadCsv', JsonCSV)
// set to true when using in development
const ENABLE_GA = (process.env.VUE_APP_ENV !== 'development');

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  enabled: ENABLE_GA, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  // debug: true, // Whether or not display console logs debugs (optional)
  // loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
});

Vue.component('TurfBtn', TurfBtn)

LoginAuth.setup({env: process.env.VUE_APP_ENV});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
