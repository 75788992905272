import { mapState } from "vuex";
import get from 'lodash/get';
import { getFieldUniqueValues, getSpaceTypesByType } from "../utils/spaces.util";

const turfMetaEditabled = {
    building: {
        type: "string",
        active: false,
        key: "turf_meta.building_name",
        label: "Building",
        turfMeta: true,
        placeholder: 'Building',
    },
    floor: {
        type: "string",
        active: false,
        key: "turf_meta.floor",
        label: "Floor",
        turfMeta: true,
        placeholder: 'Floor',
    },
    zone: {
        type: "string",
        active: false,
        key: "turf_meta.zone",
        label: "Zone",
        turfMeta: true,
        placeholder: 'Zone',
    }
};

export default {
    data() {
        const spaceTypes = getSpaceTypesByType('desk');

        // fields that can be bulk edited
        const editableFields = [
            turfMetaEditabled.building,
            turfMetaEditabled.floor,
            turfMetaEditabled.zone,
            {
                type: "boolean",
                active: false,
                key: "active",
                label: "Status",
                placeholder: 'Select',
            },
        ];

        if (spaceTypes.length > 1) {
            editableFields.unshift({
                type: "select", // type of form input for the property
                active: false, // active flag will activate the select box
                key: "space_type", // matches the key of the spaces list
                label: "Space Type",
                placeholder: 'Select',
                options: spaceTypes,
            });
        }

        return {
            turfMetaEditabled,
            editableFields,

            /**
             * hold the new values created in the autocomplete
             * example model:
             * {
             *   building_name: ['New building', 'Other new building'],
             * }
             */
            newValues: {},

            /**
             * hold the new values for fields
             * example model:
             * {
             *   building_name: 'Headquarters',
             *   floor: '6',
             * }
             */
            selectedValues: {},
        };
    },
    computed: {
        ...mapState({
            customerId: ({ auth }) => auth.customerId,
            spaces: ({ bookingSpaces, checkInSpaces }) => [...bookingSpaces, ...checkInSpaces],
        }),
    },
    methods: {
        /**
         * Return available space types that can be switched in a space
         * for checkin it will return an array with just check-in type.
         * For desk or room, it will return an array with room and desk types.
         * No arg will return all types
         * @param {*} spaceType 
         * @returns [{text: 'Desk', value: 'desk'}, {text: ...}]
         */
        getSpaceTypesByType,
        /**
         * Return a specific field available values. Values are pull from all spaces current value
         * and also add the new values created in the autocomplete
         */
        getFieldsOptions(fieldKey) {
            const fromSpacesList = this.spaces
                ? getFieldUniqueValues(this.spaces, fieldKey)
                : [];

            if (this.newValues[fieldKey]) {
                fromSpacesList.unshift(...this.newValues[fieldKey]);
            }

            // get new options entered
            if (this.selectedResources && this.selectedResources.forEach) {
                this.selectedResources.forEach(space => {
                    const val = get(space, fieldKey);
                    if (get(space, fieldKey) && !fromSpacesList.includes(val)) {
                        fromSpacesList.unshift(val);
                    }
                });
            }

            return fromSpacesList.sort();
        },

        /**
         * Select the current value for a specific field and add the new value
         * to the autocomplete list if not exists
         */
         onSelect({ fieldKey, value }) {
            if (!this.newValues[fieldKey]) {
                this.newValues[fieldKey] = [value];
            } else if (!this.newValues[fieldKey].includes(value)) {
                this.newValues[fieldKey].push(value);
            }
            this.newValues = { ...this.newValues };
            this.selectedValues = {
                ...this.selectedValues,
                [fieldKey]: value,
            };
        },
    },
}