<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="d-flex align-center">
        <h3 class="display-2 font-weight-bold">Customer Activity Logs</h3>
      </v-col>
    </v-row>
        <v-col
            class="d-flex"
            cols="12"
            sm="6"
        >
            <v-select
                dark
                outlined
                dense
                :items="customerData"
                item-text="text"
                item-value="value"
                label="Select a Customer"
                v-model="selectedCustomer"
            />
        </v-col>
    <v-row>
        <v-col
            cols="12"
        >
            <v-data-table
                dark
                :headers="tableHeaders"
                :items="logData"
                :items-per-page="50"
                :sort-by="['createdAt']"
                :sort-desc="true"
                class="elevation-1"
            ></v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CustomerLogs',
  data: () => ({
    model: {},
    showAddFlagFormModal: false,
    customerData: [],
    selectedCustomer: null,
    tableHeaders: [{
        text: 'Log',
        align: 'start',
        sortable: false,
        value: 'message'
    }, {
        text: 'Tenant',
        sortable: true,
        value: 'tenantCode'
    }, {
        text: 'CreatedAt',
        sortable: true,
        value: 'createdAt'
    }]
  }),
  computed: {
    ...mapGetters(['customers', 'customerLogs']),
    ...mapState(['auth']),
    logData() {
        return this.customerLogs.map(log => ({
            message: log.message,
            tenantCode: log.tenantCode,
            createdAt: log.createdAt.toDate()
        }))
    }
  },
  watch: {
      customers(cur) {
          if (cur) {
              this.customerData = cur.map(customer => ({
                  text: `${customer.company_name} | ${customer.customerId || customer.id}`,
                  value: customer.customerId || customer.id
              }))
          }
      },
      selectedCustomer(cur, prev) {
          if (cur !== prev && cur) {
              this.$store.dispatch('GET_CUSTOMER_LOGS', cur);
          }
      }
  },
  destroyed() {
      this.selectedCustomer = null;
      this.$store.commit('RESET_CUSTOMER_LOGS', []);
  },
  async created() {
    try {
      await this.$store.dispatch('GET_CUSTOMERS');
    } catch (err) {
      this.$store.dispatch('TOAST_ERROR', {
        message: 'Unable to retrieve customers'
      });
    }
  }
};
</script>

<style></style>
