<template>
    <div class="inline-edit-input-container">
        <transition name="fade">
            <v-icon 
                class="edit-icon" 
                color="#606060" 
                size="30"
                v-show="mouseOver && !inFocus" 
            >mdi-pencil</v-icon>
        </transition>
        <input 
            :placeholder="placeholder"
            :value="value" 
            @blur="onBlur"
            @focus="onFocus"
            @input="update()"
            @mouseenter="mouseOver = true"
            @mouseleave="mouseOver = false"
            ref="inpupInlineEdit" 
            type="text" 
        />
    </div>
</template>

<script>
export default {
    name: "input-inline-edit",
    props: {
        value: String,
        placeholder: String,
    },
    data: () => ({
        mouseOver: false,
        inFocus: false,
    }),
    methods: {
        update() {
            this.$emit("input", this.$refs.inpupInlineEdit.value);
        },
        onBlur() {
            this.inFocus = false;
            this.$emit('blur');
        },
        onFocus() {
            this.inFocus = true;
            this.$emit('focus');
        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.inline-edit-input-container {
    position: relative;
    width: 100%;

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s ease;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .edit-icon {
        position: absolute;
        right: 13px;
        top: 16px;
    }

    input {
        background: transparent;
        border-radius: 6px;
        border: 1px solid transparent;
        color: #fff;
        font-family: $font-rubik-medium;
        font-size: 30px;
        padding: 8px 60px 8px 12px;
        transition: border-color 300ms ease;
        width: 100%;

        &:hover {
            background: #000;
        }

        &:focus {
            border-color: $brand-color__purple-purple-heart;
            outline: none !important; // chrome outline
        }
    }
}
</style>