import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

/**
 *
 * @param commit
 * @param dispatch
 * @param customerId          the customer ID
 * @param {File} file         the File Object
 * @param {String} location   logo or background
 * @returns {Promise<void>}
 * @constructor
 */
export const uploadAsset = async ({ customerId, file, location }) => {
  try {
    const validMimeTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/svg+xml'
    ];
    const fileSize = file.size / 1024 / 1024; // 2MB
    const validLocations = ['logo', 'background', 'confirmation'];
    const storageRef = firebase.storage().ref();
    const customerRef = storageRef.child(`customers/${customerId}`);
    const fileRef = customerRef.child(`${location}/${file.name}`);

    if (!customerId) {
      throw new Error('customerId is undefined');
    }

    if (validLocations.indexOf(location) < 0) {
      throw new Error('Invalid file location');
    }

    if (fileSize > 2) {
      throw new Error('File size must be < 2MB');
    }

    if (validMimeTypes.indexOf(file.type) < 0) {
      throw new Error('Invalid file format (PNG, JPG, JPEG, SVG)');
    }

    const uploadTask = fileRef.put(file);

    uploadTask.on(
      'state_changed',
      function(snapshot) {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // TODO: just leaving it here in case we want to show progress in UI
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      function(error) {
        throw new Error(error);
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        const customer = await firebase.firestore()
          .collection('customers')
          .doc(customerId)
          .get();

        if (customer.exists) {
          const { theme_id } = customer.data();

          if (theme_id) {
            switch (location) {
              case 'logo':
                await firebase
                  .firestore()
                  .collection('themes')
                  .doc(theme_id)
                  .update({
                    logo: downloadURL
                  });
                console.log('Saved new logo to customer theme');
                break;
              case 'background':
                await firebase
                  .firestore()
                  .collection('themes')
                  .doc(theme_id)
                  .update({
                    background_image: downloadURL
                  });
                console.log('Saved new background to customer theme');
                break;
              case 'confirmation':
                await firebase
                  .firestore()
                  .collection('themes')
                  .doc(theme_id)
                  .update({
                    confirmation_image: downloadURL
                  });
                console.log(
                  'Saved new check-in confirmation image to customer theme'
                );
                break;
            }
          } else {
            console.log(`Could not find theme in customer ${customerId}`);
          }
        } else {
          throw new Error('Customer record not found')
        }
      }
    );
  } catch (err) {
    const errMsg = (err && err.message) || 'Failed to upload file';
    throw new Error(errMsg);
  }
};
