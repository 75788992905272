export const toPromise = (fn, ...args) => {
  return new Promise((resolve, reject) => {
    fn(...args, (error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
};

export const convertArrayToReadable = (arr) => {
  if (!arr) return '';
  if (arr.length < 2) return arr.pop();
  if (arr.length < 3) return arr.join(' and ');
  if (arr.length >= 3) return  arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1);
  return '';
};

export const isValidEmail = (email) => 
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);