<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h3 class="display-2 font-weight-bold">Displays</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" sm="12" md="6" lg="3">
        <v-text-field dark v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense
          outlined></v-text-field>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="3">
        <sorting-controls :items="sortOptions" :sortBy="sortBy" :sortDesc="sortDesc" @setSortOption="setSortOption">
        </sorting-controls>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table dark :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :headers="headers" :items="devices"
          :items-per-page="10" :search="search" class="elevation-1 space-table">
          <template v-slot:item.displayId="{ item }">
            <span class="title font-weight-medium text-start">
              {{ item.serialNumber || item.displayId }}
            </span>
          </template>
          <template v-slot:item.isAssigned="{ item }">
            <span class="title font-weight-medium text-start">
              {{ item.isAssigned }}
            </span>
          </template>
          <template v-slot:item.roomName="{ item }">
            <span class="title font-weight-medium text-start">
              {{ item.roomName }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SortingControls from '../components/SortingControls.vue';

export default {
  name: 'DisplaysComp',
  components: {
    SortingControls
  },
  data: () => ({
    search: '',
    sortBy: 'displayId',
    sortDesc: false,
    headers: [
      {
        text: 'Serial Number',
        align: 'start',
        sortable: false,
        value: 'displayId'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'isAssigned'
      },
      {
        text: 'Assigned Space',
        align: 'start',
        sortable: false,
        value: 'roomName'
      }
    ]
  }),
  methods: {
    setSortOption(name, value) {
      if (name === 'sortBy') {
        this.sortBy = value;
      } else if (name === 'sortDesc') {
        this.sortDesc = !this.sortDesc;
      }
    }
  },
  computed: {
    ...mapGetters(['getCustomerDisplays', 'getSpaceById']),
    devices: function() {
      return this.getCustomerDisplays.map(display => {
        const isAssigned = !!display.space_id;
        let roomName = 'Unassigned';
        if (isAssigned) {
          const space = this.getSpaceById(display.space_id);
          if (space) {
            roomName = space.turf_meta?.space_name || space.name || space.display_name;
          }
        }
        return {
          displayId: display.displayId,
          isAssigned: isAssigned && roomName !== 'Unassigned' ? 'Assigned' : 'Unassigned',
          roomName
        };
      });
    },
    sortOptions() {
      return this.headers.reduce((options, header) => {
        const { text, value } = header;
        if (text && value) {
          options.push({ text, value });
        }
        return options;
      }, []);
    }
  }
};
</script>

<style lang="scss" scoped>
.space-table {
  width: 100%;
  background-color: transparent !important;
}
.left-align {
  padding-left: 50px;
  text-align: left;
}
.search-bar {
  display: flex;
  align-items: baseline;
}
.sort-button {
  margin-right: 15px;
}
</style>
