export const createNewTheme = ({
  primary_color = "",
  background_color = "",
  background_image = "",
  confirmation_image = "",
  font_color = "",
  is_default = false,
  is_public = false,
  logo = "",
  name = "",
  created = new Date().toISOString()
}) => {
  return {
    primary_color,
    name,
    background_color,
    background_image,
    confirmation_image,
    created,
    font_color,
    is_default,
    is_public,
    updated: new Date().toISOString(),
    logo
  };
};

export const parseFileNameFromStorageUrl = (url = '') => {
  if (!url) return 'No file chosen'
  const re = /%2..*%2F(.*?)\?alt/
  const match = url.match(re)
  if (match && match[1]) {
    return decodeURI(match[1])
  } 
  console.error('Error parsing storage url: ', url)
}
