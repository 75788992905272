let isDebugEnabled = false;

const logDebug = (...args) => {
  if (!isDebugEnabled) {
    return;
  }

  const prefix = [
    '%cturf-console',
    'background: #ffa000; color: #fff; border-radius: 2px; padding: 2px 6px',
  ]
  console.log(...prefix, ...args);
}

export const setDebugEnabled = (isEnabled) => {
  isDebugEnabled = isEnabled;
};

export default logDebug