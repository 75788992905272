<template>
  <v-select 
    :items="items" 
    v-bind="$attrs"
    :value="value" 
    @change="onChange" />
</template>

<script>
export default {
    props: ['true-value', 'false-value', 'true-label', 'false-label', 'value'],
    computed: {
        items() {
            return [
                {
                    text: this.trueLabel,
                    value: this.trueValue,
                },
                {
                    text: this.falseLabel,
                    value: this.falseValue,
                },
            ];
        },
    },
    methods: {
        onChange(value) {
            this.$emit('change', value);
            this.$emit('input', value);
        }
    },
}
</script>

<style>

</style>