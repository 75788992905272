import firebase from 'firebase/app';
import { firestoreAction } from 'vuexfire';

const defaultState = () => ({
  features: [],
  customers: []
});

const featureCollection = 'feature_flags';

const auth = {
  state: defaultState(),
  actions: {
    GET_MEETING_MANAGER_FEATURE_FLAGS: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('features', firebase.firestore().collection(featureCollection));
    }),
    GET_CUSTOMERS: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('customers', firebase.firestore().collection('customers'));
    }),
    SET_FEATURE_FLAG: (_, { featureId, feature }) => {
      return firebase.firestore().collection(featureCollection).doc(featureId).update(feature);
    },
    ADD_FEATURE_FLAG: (_, { feature }) => {
      try {
        return firebase.firestore().collection(featureCollection).doc(feature.id).set(feature);
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {
    customers: state => state.customers,
    features: state => state.features
  }
};

export default auth;
