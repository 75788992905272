<template>
  <v-card class="grey darken-3 rounded mb-3">
    <v-card-text>
      <v-row>
        <v-col cols="8" class="text-left">
          <h6 class="mt-3 title" style="color: #fff">{{title}}</h6>
          <p v-if="isLinked && getLinkedEmail" class="pt-3 pr-6 subtitle-2">
            Linked {{getLinkedEmail}}
          </p>
        </v-col>
        <v-col cols="4" class="d-flex flex-row justify-end align-center">
          <p v-if="!getLinkedEmail && isLinked" class="pt-3 pr-6 subtitle-1" style="color: #fff">
            Linked
          </p>
          <turf-btn v-if="isLinked" large outlined dark @click="handleUnlink">
            Unlink
          </turf-btn>
          <turf-btn v-if="!isLinked" large color="success" dark @click="handleLink">
            Link
          </turf-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CalendarIntegrationItem",
  props: ['title', 'isLinked', 'type', 'linkedEmail'],
  computed: {
    getLinkedEmail() {
      return this.linkedEmail ? `with ${this.linkedEmail}` : '';
    }
  },
  methods: {
    handleUnlink() {
      this.$emit('onUnlink', this.type)
    },
    handleLink() {
      this.$emit('onLink', this.type)
    }
  }
}
</script>

<style>

</style>