<template>
  <div>
    <v-snackbar
        v-for="toast in getToastState"
        :key="toast.id"
        v-model="toast.active"
        left
        :timeout="toast.timeout"
        @input="onTimeoutComplete(toast.id)"
        :color="toast.color">
      {{toast.message}}
      <turf-btn
        color="white"
        text
        @click="toast.active = false, onClose(toast.id)"
      >
        <v-icon>mdi-close</v-icon>
      </turf-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ToastComp',
  computed: {
    ...mapGetters([
      'getToastState'
    ])
  },
  methods: {
    onClose(toastId) {
      this.$store.dispatch('TOAST_DISMISS', toastId);
    },
    onTimeoutComplete(toastId) {
      this.$store.dispatch('TOAST_DISMISS', toastId);
    }
  }
}
</script>