<template>
  <v-card elevation="4" dark class="pa-4">
    <h5 class="headline font-weight-bold text-left mb-4">Check-In Settings</h5>
    <v-row>
      <v-col cols="12">
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              Enable Check-in
            </h6>
            <p class="text-left body-2">
              Users will be able to check in.
            </p>
            <BinarySelect 
                outlined
                dense
                :true-value="true"
                :false-value="false"
                true-label="Enable"
                false-label="Disable"
                :value="value.is_enable"
                @input="e => $emit('input', {...value, ...{is_enable: e}})"
            />
          </div>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              Check-In Users
            </h6>
            <p class="text-left body-2">
              Select the type(s) of users that will be required to check in.
            </p>
            <v-select
              outlined
              dense
              ref="requiredUserTypes"
              @change="handleBoundChange('requiredUserTypes', $event)"
              :value="value.requiredUserTypes"
              :items="checkInUsersOptions"
            ></v-select>
          </div>
        </span>
        <!-- host required -->
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              Host Selection
            </h6>
            <p class="text-left body-2">
              Do you want your visitors to select a host when they check in?
            </p>
            <v-select
              outlined
              dense
              ref="requireHost"
              @change="onHostRequiredChange"
              :value="hostRequiredValue"
              :items="requireHostSelectOptions"
              :disabled="disableHostOptions"
            ></v-select>
          </div>
        </span>

        <!-- phone number required -->
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              Enter a phone number
            </h6>
            <p class="text-left body-2">
              Do you want to require that your visitors enter a phone number in order to
              check in?
            </p>
            <v-select
              outlined
              dense
              ref="requirePhoneNumber"
              @change="handleBoundChange('requirePhoneNumber', $event)"
              :value="value.requirePhoneNumber"
              :items="yesNoSelectOptions"
              :disabled="disableHostOptions"
            ></v-select>
          </div>
        </span>

        <!-- email required -->
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              Enter an email address
            </h6>
            <p class="text-left body-2">
              Do you want to require that your visitors enter an email address in order to
              check in?
            </p>
            <v-select
              outlined
              dense
              ref="requireEmail"
              @change="handleBoundChange('requireEmail', $event)"
              :value="value.requireEmail"
              :items="yesNoSelectOptions"
              :disabled="disableHostOptions"
            ></v-select>
          </div>
        </span>
      </v-col>
      <v-col cols="6">
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              SMS Notifications
            </h6>
            <p class="text-left body-2">
              Employees will receive SMS notifications to complete their
              check-in. They will also receive SMS notifications when their
              visitors have checked in.
            </p>
            <v-select
              outlined
              dense
              ref="enableSms"
              @change="handleBoundChange('enableSms', $event)"
              :value="value.enableSms"
              :items="smsOptions"
            ></v-select>
          </div>
        </span>
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              Touchless Check-In Process
            </h6>
            <p class="text-left body-2">
              Users will need to check in using a mobile device.
            </p>
            <v-select
              outlined
              dense
              ref="enableTouchless"
              @change="handleBoundChange('enableTouchless', $event)"
              :value="value.enableTouchless"
              :items="touchlessOptions"
            ></v-select>
          </div>
        </span>
        <!-- Confirmation Image -->
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              Check-In Confirmation Image
            </h6>
            <p class="text-left body-2">
              File size should be less than 2mb. We recommend choosing an 800px
              by 800px image.
            </p>
          </div>
          <v-file-input
            dark
            outlined
            class="flex-grow-1"
            @change="handleImageUpload"
            :placeholder="confirmationFileName"
            :error-messages="uploadConfirmationFileErrorMessage"
            ref="confirmationFile"
            prepend-icon=""
          >
            <template v-slot:prepend-inner>
              <turf-btn
                small
                class="mr-2"
                @click="handleClickUploadConfirmationImage"
              >
                Choose File
              </turf-btn>
            </template>
            <template v-if="confirmationFile" v-slot:append>
              <v-icon @click="handleClearConfirmationImage"> mdi-close </v-icon>
            </template>
          </v-file-input>
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import isEqual from 'lodash/isEqual'
import { parseFileNameFromStorageUrl } from "@/utils/themeUtils";
import { mapGetters } from 'vuex';
import BinarySelect from './BinarySelect.vue';

export default {
  name: "CheckInSettings",
  components: {
    BinarySelect
  },
  props: {
    value: {
      default: function () {
        return {
          is_enable: false,
          requiredUserTypes: {
            employees: true,
            guests: true,
          },
          requireHost: true,
          enableOptionalHost: false,
          requirePhoneNumber: true,
          requireEmail: true,
          enableSms: false,
          enableTouchless: false,
        };
      },
    },
    uploadConfirmationFileErrorMessage: {
      default: () => null,
    },
    handleClickUploadConfirmationImage: {
      default: () => () => {},
    },
    handleClearConfirmationImage: {
      default: () => () => {},
    },
    customerData: Object,
  },
  data() {
    return {
      checkInUsersOptions: [
        {
          text: "Employees and Visitors",
          value: { employees: true, guests: true },
        },
        { text: "Employees Only", value: { employees: true, guests: false } },
        { text: "Visitors Only", value: { employees: false, guests: true } },
      ],
      yesNoSelectOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      $requireHost: false,
      $enableOptionalHost: false,
      requireHostSelectOptions: [
        { text: "Require visitors to select a host when checking in", value: 1 },
        { text: "Visitors have the option to select a host when checking in", value: 2 },
        { text: "Do not require visitors to select a host when checking in", value: 0 },
      ],
      confirmationFile: null,
      smsOptions: [
        { value: true, text: 'Enable' }, 
        { value: false, text: 'Disable' }
      ],
      touchlessOptions: [
        { value: true, text: 'Enable' }, 
        { value: false, text: 'Disable' }
      ]
    };
  },
  mounted() {
    this.$requireHost = this.value.requireHost;
    this.$enableOptionalHost = this.value.enableOptionalHost;
  },
  computed: {
    ...mapGetters(['getThemeData']),
    confirmationFileName() {
      return parseFileNameFromStorageUrl(this.getThemeData.confirmation_image);
    },
    disableHostOptions() {
      return isEqual(this.value.requiredUserTypes, { employees: true, guests: false })
    },
    requiredHostEnabled() {
      return this.$refs.requireHost?.value || this.value.requireHost || false;
    },
    /**
     * return values:
     * 0: Don't require host
     * 1: Requiere host
     * 2: Host is optional
     */
    hostRequiredValue() {
      return this.value.requireHost
        ? this.value.enableOptionalHost
          ? 2
          : 1
        : 0;
    },
  },
  methods: {
    onHostRequiredChange(value) {
      switch(value) {
        case 0:
          this.$requireHost = false;
          this.$enableOptionalHost = false;
          break;
        case 1:
          this.$requireHost = true;
          this.$enableOptionalHost = false;
          break;
        case 2:
          this.$requireHost = true;
          this.$enableOptionalHost = true;
          break;
      }
      this.handleBoundChange();
    },
    handleImageUpload(file) {
      return this.$emit("upload", file);
    },
    handleBoundChange(name, value) {
      const currentData = {
        is_enable: this.value.is_enable,
        requiredUserTypes: this.$refs.requiredUserTypes.value,
        requireHost: this.$requireHost,
        enableSms: this.$refs.enableSms.value,
        enableTouchless: this.$refs.enableTouchless.value,
        requirePhoneNumber: !!this.$refs.requirePhoneNumber.value,
        enableOptionalHost: this.$enableOptionalHost,
        requireEmail: !!this.$refs.requireEmail.value,
      }
      if (name) {
        currentData[name] = value === undefined ? false : value;
      }
      this.$emit("input", currentData);
    },
  },
};
</script>
<style></style>
