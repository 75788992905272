<template>
  <v-app>
    <v-main class="main-content pt-12">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import { LoginAuth, Cookie } from "@skykit-dev/skykit_login_utils";
import turfCore from "@skykit-dev/skykit-turf-core";
import { getIdentity } from "@/service/identity";
import { getAvailableProductNames } from '@/utils/products.helper';
import { hasMultipleTenants, getPermissions } from '@/utils/identity.helper';
import Emitter from '@/utils/pubsub';

export default {
  name: "App",
  computed: {
    ...mapGetters([
      "isLoggedIn"
    ])
  },
  watch: {
    async isLoggedIn(cur, prev) {
      // prev should be null to indicate the customer first logs in
      if (!prev && cur) {
        let claims = await this.$firebaseAuth.getClaims();
        this.$store.dispatch('SET_LOGIN_EVENT', {
          customerId: claims.customer_id,
          email: claims.email,
          name: claims.name,
          firebaseUid: claims.uid
        })
      }
      if (cur) {
        if (this.$router?.currentRoute?.path === '/') {
          this.$router.push('/spaces');
        }
      }
    }
  },
  created() {
    this.$store.dispatch("UPDATE_LOGIN_STATE", "pending-auth");

    LoginAuth.verifySession(
      { cookie: document.cookie },
      async (resp) => {
        try {
          let claims = await this.$firebaseAuth.getClaims();

          if (!claims || (claims && claims.tenant_code !== resp?.tenantId)) {
            // only allow turf user
            if (!resp.customToken["turf"]) {
              console.error(`User does not have Turf claims`);
              this.$store.dispatch('SET_DISABLE_APP', true);
              return;
            }

            // check if tenant is different
            const data = await this.$firebaseAuth.signIn(
              resp.customToken["turf"]
            );

            claims = await this.$firebaseAuth.getClaims();

            this.$store.dispatch("UPDATE_USER", data.user);
          }

          const jwtToken = await this.$firebaseAuth.getJwtToken();
          const identity = await getIdentity(jwtToken, resp.tenantId);
          const permissions = getPermissions(identity);
          const hasMultiTenants = hasMultipleTenants(identity);

          if (!permissions?.find((x) => x == "meeting")) {
            console.error(
              `Tenant ${claims.tenant_code} lacks permissions for Turf. Please contact administrator.`
            );
            this.$store.dispatch('SET_DISABLE_APP', true);
          } else {
            Emitter.emit('enable-tenant-change', hasMultiTenants);

            turfCore.init(firebase, claims?.customer_id);
            this.$store.dispatch(
              "SET_PLATFORM_ADMIN",
              !!permissions?.find((x) => x == "platform-admin-enabled")
            );
            this.$store.dispatch(
              "SET_CHECKIN_FLAG",
              !!permissions?.find((x) => x == "checkin")
            );
            this.$store.dispatch("SET_FIRESTORE_DATA", claims?.customer_id);
            this.$store.dispatch("UPDATE_TENANT", claims.tenant_code);
            this.$store.commit("SET_CUSTOMER_ID", claims?.customer_id);
            this.$store.dispatch('UPDATE_AVAILABLE_PRODUCTS', getAvailableProductNames(resp.customToken, 'turf'));
          }
        } catch (err) {
          console.error(err.message);
          this.$store.dispatch('SET_DISABLE_APP', true);
        }
      },
      async (err) => {
        const httpStatus = err?.response?.status;

        console.error(`Failed to verify (${httpStatus}): ${err}`);

        if (httpStatus === 401 || // 1.1 session cookie expired
            !this.isLoggedIn && httpStatus === 403) { // 1.2 Not authenticated and no session cookie
          // adds referrer to catch redirect navigation to turf while unauthorized
          Cookie.create(
            "referrer",
            JSON.stringify({
              redirectUrl: location.origin,
              app: "turf",
            })
          );
        }

        await this.logOut();
      }
    );
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("LOG_OUT");
    },
  },
};
</script>

<style>
.main-content {
  color: white;
}
.v-main {
  background: linear-gradient(180deg, #212121 0%, #000000 100%);
  text-align: center;
  height: 100%;
}
</style>