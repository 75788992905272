<!-- Includes the header, side nave, and child router -->
<template>
  <v-app>
    <v-hover v-slot:default="{ hover }">
      <v-navigation-drawer
        dark
        app
        v-model="drawer"
        permanent
        width="200"
        mini-variant
        expand-on-hover
        v-if="$route.fullPath !== '/login'"
        color="#181818"
      >
        <v-list-item class="px-2 py-4">
          <router-link class="reduced-link mr-2" to="/">
            <v-list-item-icon>
              <turf-logo></turf-logo>
            </v-list-item-icon>
          </router-link>
          <router-link to="/">
            <v-list-item-title class="pt-2">
              <turf-type-logo></turf-type-logo>
            </v-list-item-title>
          </router-link>
        </v-list-item>
        <v-list dense v-if="!getDisableApp">
          <router-link
            v-for="item in items"
            :key="item.path"
            :to="{ path: item.path, query: item.query }"
          >
            <v-list-item
              link
              v-bind:class="{ 'selected-path': item.path === $route.path }"
            >
              <v-list-item-icon class="my-4 mr-6">
                <component v-bind:is="item.icon"></component>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-1 font-weight-bold">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <v-divider></v-divider>
          <router-link v-if="hasCheckInFeature" :to="{ path: '/directory' }">
            <v-list-item
              link
              v-bind:class="{ 'selected-path': '/directory' === $route.path }"
            >
              <v-list-item-icon class="my-4 mr-6">
                <v-icon>mdi-card-account-details-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-1 font-weight-bold">
                Directory
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="hasCheckInFeature" :to="{ path: '/reports' }">
            <v-list-item
              link
              v-bind:class="{ 'selected-path': '/reports' === $route.path }"
            >
              <v-list-item-icon class="my-4 mr-6">
                <v-icon>mdi-folder-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-1 font-weight-bold">
                Reports
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isPlatformAdmin" :to="{ path: '/admin' }">
            <v-list-item
              link
              v-bind:class="{ 'selected-path': '/admin' === $route.path }"
            >
              <v-list-item-icon class="my-4 mr-6">
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-1 font-weight-bold">
                Admin
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
        <template v-if="hover" v-slot:append>
          <p class="mb-2 text-center grey--text">
            Skykit Turf - v{{ version }}
          </p>
        </template>
      </v-navigation-drawer>
    </v-hover>
    <v-main class="main-content" v-if="!getDisableApp">
      <user-avatar justify-end />
      <router-view></router-view>
    </v-main>
    <toast></toast>
    <v-dialog dark v-model="getDisableApp" persistent max-width="50%">
      <v-card>
        <v-card-text class="pa-10"
          >You do not have permissions to access Skykit Turf Console. Please
          contact your administrator.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="logOut"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
  
  <script>
import UserAvatar from "@/components/UserAvatar";
import Toast from "@/components/Toast";
import DisplaysSVG from "../assets/displays.svg";
import SettingsSVG from "../assets/settings.svg";
import CalendarSVG from "../assets/calendar.svg";
import SpacesSVG from "../assets/spaces.svg";
import TurfTypeSVG from "../assets/turf_logo_90.svg";
import TurfLogoSVG from "../assets/turf_icon_40.svg";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    drawer: true,
    items: [
      { icon: "spaces-svg", path: "/spaces", title: "Spaces" },
      { icon: "displays-svg", path: "/displays", title: "Displays" },
      { icon: "settings-svg", path: "/settings", title: "Settings" },
      { icon: "calendar-svg", path: "/calendars", title: "Calendars" },
    ],
  }),
  components: {
    "user-avatar": UserAvatar,
    "displays-svg": DisplaysSVG,
    "spaces-svg": SpacesSVG,
    "settings-svg": SettingsSVG,
    "calendar-svg": CalendarSVG,
    "turf-logo": TurfLogoSVG,
    "turf-type-logo": TurfTypeSVG,
    Toast,
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("LOG_OUT");
    },
  },
  computed: {
    ...mapGetters([
      "isPlatformAdmin",
      "getTenantCode",
      "isLoggedIn",
      "hasCheckInFeature",
      "getDisableApp"
    ]),
    version: () => process.env.VUE_APP_VERSION,
  },
  watch: {
    getTenantCode: function (cur) {
      if (cur && window && window.dataLayer) {
        window.dataLayer.push({
          tenantCode: cur,
        });
      }
    },
    isLoggedIn: function (cur) {
      if (cur) {
        if (window && window.dataLayer) {
          window.dataLayer.push({
            event: "login",
            userId: cur.uid,
          });
        }
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.selected-path {
  background-color: #6e2ed2;
}
.main-content {
  color: white;
}
.v-main {
  background: linear-gradient(180deg, #212121 0%, #000000 100%);
  text-align: center;
  height: 100%;
}
a {
  text-decoration: none;
}
.v-navigation-drawer {
  font-weight: medium;
}
.reduced-link {
  max-width: 40px;
}
</style>
  <style lang="scss">
.ui-link {
  color: #fff !important;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
}
.modal-header {
  position: relative;
  width: 100%;

  .close-button {
    position: absolute;
    right: -20px;
    top: -10px;
  }
}
</style>
  