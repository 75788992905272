<template>
  <v-card dark elevation="4" class="mb-9">
    <v-card-title class="px-4">
      <h5 class="headline font-weight-bold text-left">
        General Settings
      </h5>
    </v-card-title>

    <div class="pa-4">
      <v-row>
        <v-col cols="6">
          <v-text-field
            rows="3"
            :value="customerData.company_name"
            @input="e => $emit('update:customerData', {...customerData, ...{company_name: e}})"
            label="Company Name"
            class="font-weight-bold"
            dark
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            rows="3"
            :value="customerData.admin_contact_email"
            @input="e => $emit('update:customerData', {...customerData, ...{admin_contact_email: e}})"
            label="Admin Contact Email"
            class="font-weight-bold "
            dark
            outlined
            :rules="emailRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="deskBookingBetaV2Enabled">
        <v-col cols="12">
          <h6 class="text-left subtitle-1 font-weight-black">
            Turf Mobile web app setup
          </h6>
          <p class="text-left body-2">
            Download the QR and use with <a href="https://support.skykit.com/articles/241" target="_blank">these instructions</a> to onboard your users.
          </p>
          <SpaceQr :show-print-button="false" />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import featureFlags from '@/mixins/featureFlags';
import SpaceQr from './SpaceQr.vue';
import { isValidEmail } from '@/utils/coreUtils';

export default {
  name: 'GeneralSettings',
  props: {
    customerData: {
      default: () => ({}),
      type: Object
    }
  },
  components: {
    SpaceQr,
  },
  mixins: [featureFlags],
  data() {
    return {
      adHocOptions: [
        { value: true, text: 'Enable' }, 
        { value: false, text: 'Disable' }
      ]
    }
  },
  computed: {
    emailRules() {
      // const requireRule = v => !!v || 'E-mail is required';
      const syntaxRule = email => !email
        || isValidEmail(email) 
        || 'E-mail address is not valid';
      
      return [syntaxRule];
    },
    companyName: {
      get() {
        return this.customerData.company_name
      },
      set(v) {
        this.$emit('input',v);
      }
    }
  },
};
</script>

<style></style>
