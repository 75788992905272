import { firestoreAction } from 'vuexfire';
import dayjs from 'dayjs';
import get from 'lodash/get';
import firebase from 'firebase/app';
import 'firebase/firestore';

const customSerializer = (doc) => {
  const data = doc.data()
  Object.defineProperty(data, '_doc', { value: doc })
  Object.defineProperty(data, 'id', { value: doc.id, enumerable: true })
  return data
}

// temporary fix until active turf sprint
let nextDocCursor = null; // prevent circular structure to JSON error when saving to vuex store

const state = {
  employees: [],
  nextEmployeesBatch: [],
  nextActivitiesBatch: [],
  activityLog: [],
  // nextDocCursor: null,
  pageLoading: false,
  activityCount: 0
};
const getters = {
  getAllEmployees: state => state.employees,
  getAllActivities: state => state.activityLog,
  getActivitiesWithData: (state, _getters, rootState) => {
    return state.activityLog.map(log => {
      const userType = log.userType;
      const locationId = get(log, 'locationId') || '';
      let location = {};
      const host = userType === 'guest' && get(log, 'host.firstName') ? `${log.host.firstName} ${log.host.lastName}` : '';

      if (locationId) {
        location = rootState.checkInSpaces.find(x => x.id === locationId);
      }
      
      return {
        id: log.id,
        ...log,
        location,
        host,
        checkedInAt: log.checkedInAt && typeof log.checkedInAt.toDate === 'function'
          ? dayjs(log.checkedInAt.toDate()).format('MM/DD/YYYY - hh:mm A')
          : '',
        checkedOutAt: log.checkedOutAt && typeof log.checkedOutAt.toDate === 'function'
          ? dayjs(log.checkedOutAt.toDate()).format('MM/DD/YYYY - hh:mm A')
          : ''
      };
    });
  },
  pageLoading: state => state.pageLoading,
  getActivityCount: state => state.activityCount
};
const actions = {
  GET_MORE_EMPLOYEES: firestoreAction(({ bindFirestoreRef, rootGetters, commit }, {limit = 50, sortBy = 'email', desc = false}) => {
    const customerId = rootGetters.getCustomerId;

    commit('SET_PENDING', true);

    console.debug(`Fetching more data: ${!!nextDocCursor}`);
    
    return bindFirestoreRef(
      'nextEmployeesBatch',
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId)
        .collection('checkin_employees')
        .where('isActive', '==', true)
        .orderBy(sortBy, !desc ? 'asc' : 'desc')
        .limit(limit + 1)  // add 1 to enable next page cursor
        .startAfter(nextDocCursor || null),
      {
        serialize: customSerializer
      }
    ).then(employees => {
      if (employees.length) {
        commit('MERGE_EMPLOYEES_BATCH', {employees})
        commit('SET_LAST_DOC_CURSOR');
      }
      commit('SET_PENDING', false);
    })
  }),
  BIND_EMPLOYEES: firestoreAction(({ bindFirestoreRef, rootGetters, commit, dispatch }, {limit = 50, sortBy = 'email', desc = false}) => {
    const customerId = rootGetters.getCustomerId;

    commit('SET_PENDING', true);

    commit('RESET_EMPLOYEES');
    
    return bindFirestoreRef(
      'employees',
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId)
        .collection('checkin_employees')
        .where('isActive', '==', true)
        .orderBy(sortBy, !desc ? 'asc' : 'desc')
        .limit(limit + 1),  // add 1 to enable next page cursor
      {
        serialize: customSerializer
      }
    ).then(employees => {
      if (employees.length) {
        setTimeout(() => {
          commit('SET_LAST_DOC_CURSOR');
        }, 1000);
      }
      commit('SET_PENDING', false);
    }).catch(() => {
      console.error(`Get directory failed, retry in 10 sec`);
      setTimeout(() => {
        dispatch('BIND_EMPLOYEES', {limit, sortBy, desc});
      }, 10000)
    })
  }),
  BIND_ACTIVITIES: firestoreAction(({ bindFirestoreRef, rootGetters, commit, dispatch }, {limit = 50, sortBy = 'checkedInAt', desc = false}) => {
    const customerId = rootGetters.getCustomerId;

    commit('SET_PENDING', true);
    
    return bindFirestoreRef(
      'activityLog',
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId)
        .collection('checkin_logs')
        .orderBy(sortBy, !desc ? 'asc' : 'desc')
        .limit(limit + 1),  // add 1 to enable next page cursor
      {
        serialize: customSerializer
      }
    ).then(employees => {
      if (employees.length) {
        setTimeout(() => {
          commit('SET_LAST_ACTIVTY_DOC_CURSOR');
        }, 1000);
      }
      commit('SET_PENDING', false);
    }).catch((err) => {
      console.error(`err`, err);
      setTimeout(() => {
        dispatch('BIND_ACTIVITIES', {limit, sortBy, desc});
      }, 10000)
    })
  }),
  GET_MORE_ACTIVITIES: firestoreAction(({ bindFirestoreRef, rootGetters, commit }, {limit = 50, sortBy = 'checkedInAt', desc = false}) => {
    const customerId = rootGetters.getCustomerId;

    commit('SET_PENDING', true);

    console.debug(`Fetching more data: ${!!nextDocCursor}`);
    
    return bindFirestoreRef(
      'nextActivitiesBatch',
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId)
        .collection('checkin_logs')
        .orderBy(sortBy, !desc ? 'asc' : 'desc')
        .limit(limit + 1)  // add 1 to enable next page cursor
        .startAfter(nextDocCursor || null),
      {
        serialize: customSerializer
      }
    ).then(activityLog => {
      if (activityLog.length) {
        commit('MERGE_ACTIVITIES_BATCH', {activityLog})
        commit('SET_LAST_ACTIVTY_DOC_CURSOR');
      }
      commit('SET_PENDING', false);
    })
  }),
  GET_ACTIVITES_COUNT: firestoreAction(({ bindFirestoreRef, rootGetters, commit }) => {
    const customerId = rootGetters.getCustomerId;
    
    return bindFirestoreRef(
      'activityCount',
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId)
        .collection('checkin_logs')
    ).then(employees => {
      if (employees.length) {
        commit('SET_ACTIVITY_COUNT', employees.length);
      } else {
        commit('SET_ACTIVITY_COUNT', 0);
      }
    })
  }),
};
const mutations = {
  MERGE_EMPLOYEES_BATCH: (state, payload) => {
    state.employees = [...state.employees, ...payload.employees];
  },
  MERGE_ACTIVITIES_BATCH: (state, payload) => {
    state.activityLog = [...state.activityLog, ...payload.activityLog];
  },
  SET_LAST_DOC_CURSOR: (state) => {
    nextDocCursor = state.employees[state.employees.length - 1]._doc;
    state.nextEmployeesBatch = [];
  },
  SET_LAST_ACTIVTY_DOC_CURSOR: (state) => {
    nextDocCursor = state.activityLog[state.activityLog.length - 1]._doc;
    state.nextActivitiesBatch = [];
  },
  SET_PENDING: (state, isPending) => state.pageLoading = isPending,
  SET_ACTIVITY_COUNT: (state, count) => state.activityCount = count,
  RESET_EMPLOYEES: (state) => {
    state.employees = [];
    state.nextEmployeesBatch = [];
    nextDocCursor = null;
    state.pageLoading = true
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
