<template>
  <v-card elevation="4" dark class="pa-4">
    <h5 class="headline font-weight-bold text-left mb-6">Desk Booking Settings</h5>
    <v-row>
      <v-col cols="12">
        <!-- email required -->
        <span class="d-flex flex-column">
          <div>
            <h6 class="text-left subtitle-1 font-weight-black">
              Enable desk booking
            </h6>
            <p class="text-left body-2">
              Users will be able to book a desk directly from the app.
            </p>
            <BinarySelect 
                outlined
                dense
                :true-value="true"
                :false-value="false"
                true-label="Enable"
                false-label="Disable"
                :value="customerData.settings.booking.desk.is_enable"
                @input="update"
            />
          </div>
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import BinarySelect from './BinarySelect.vue';

export default {
  name: 'desk-booking-settings',
  props: {
    customerData: {
      default: () => ({}),
      type: Object
    }
  },
  components: {
    BinarySelect,
  },
  methods: {
    update(e) {
      const cloneObj = Object.assign({}, this.customerData);
      cloneObj.settings.booking.desk.is_enable = e;
      this.$emit('input', cloneObj);
    }
  }
};
</script>
<style></style>
