import firebase from 'firebase/app';
import 'firebase/auth';
import { LoginAuth } from '@skykit-dev/skykit_login_utils';
import { onRedirect } from '@/utils/redirect';
import router from "../router";

const defaultState = () => ({
  customerId: '',
  tenantCode: '',
  customToken: null,
  user: null,
  loginState: 'pending',
  isPlatformAdmin: false,
  hasCheckInFeature: false,
  availableProducts: []
});

const auth = {
  state: defaultState(),
  mutations: {
    SET_CUSTOMER_ID: (state, value) => {
      state.customerId = value;
    },
    UPDATE_CUSTOM_TOKEN_SUCCESS: (state, value) => {
      state.customToken = value;
    },
    UPDATE_TENANT_SUCCESS: (state, value) => {
      state.tenantCode = value;
    },
    UPDATE_USER_SUCCESS: async (state, data) => {
      state.user = data;
    },
    UPDATE_LOGIN_STATE_SUCCESS: (state, value) => {
      state.loginState = value;
    },
    SET_PLATFORM_ADMIN: (state, value) => {
      state.isPlatformAdmin = value
    },
    SET_CHECKIN_FLAG: (state, value) => {
      state.hasCheckInFeature = value
    },
    SET_AVAILABLE_PRODUCTS: (state, value) => state.availableProducts = value,
    RESET_STATE: state => {
      Object.assign(state, defaultState());
    }
  },
  actions: {
    async UPDATE_CUSTOM_TOKEN({ commit }, customToken) {
      commit('UPDATE_CUSTOM_TOKEN_SUCCESS', customToken);
    },
    async UPDATE_TENANT({ commit }, tenantCode) {
      commit('UPDATE_TENANT_SUCCESS', tenantCode);
    },
    async UPDATE_USER({ commit }, user) {
      commit('UPDATE_USER_SUCCESS', user);
    },
    async UPDATE_LOGIN_STATE({ commit }, state) {
      commit('UPDATE_LOGIN_STATE_SUCCESS', state);
    },
    async SET_PLATFORM_ADMIN({ commit }, isPlatformAdmin) {
      commit('SET_PLATFORM_ADMIN', isPlatformAdmin);
    },
    async SET_CHECKIN_FLAG({ commit }, hasCheckInFeature) {
      commit('SET_CHECKIN_FLAG', hasCheckInFeature);
    },
    async UPDATE_AVAILABLE_PRODUCTS({ commit }, dataArr) {
      commit('SET_AVAILABLE_PRODUCTS', dataArr);
    },
    LOG_OUT: async ({ commit }) => {
      try {
        await firebase.auth().signOut();
        await LoginAuth.destroySessionCookie();

        commit('RESET_STATE');

        router.push({name: 'LandingPage'}).catch(()=>{}).finally(onRedirect);
      } catch(err) {
        console.error(`session logout failed`, err);
      }
    }
  },
  getters: {
    isLoggedIn: state => state.user, // !isEmpty(state.firebaseUserData) && state.userData.uid,
    isPlatformAdmin: state => state.isPlatformAdmin,
    // TODO: upadte once Check-In permissions in place
    hasCheckInFeature: () => true,
    getLoginState: state => state.loginState,
    getTenantCode: state => state.tenantCode,
    getCustomerId: state => state.customerId,
    getAvailableProducts: state => state.availableProducts
  }
};

export default auth;
