import { mapState } from 'vuex';
import 'firebase/firestore';
import SortingControls from '../components/SortingControls.vue';
import GreenCheck from '../assets/green-check.svg'

export default {
  name: 'Spaces',
  components: {
    SortingControls,
    GreenCheck,
  },
  data: () => ({
    search: '',
    sortBy: 'name',
    sortDesc: false,
    selectedFilterOption: 'All Spaces',
    headers: [
      {
        text: 'Calendar Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Calendar ID',
        align: 'start',
        sortable: false,
        value: 'provider_id',
      },
      {
        text: 'Category',
        align: 'start',
        sortable: false,
        value: 'category',
      },
      {
        text: 'Building',
        align: 'start',
        sortable: false,
        value: 'building_name',
      },
      {
        text: 'Floor',
        align: 'start',
        sortable: false,
        value: 'floor',
      },
      {
        text: 'Zone',
        align: 'start',
        sortable: false,
        value: 'zone',
      },
      {
        text: 'Date Added',
        align: 'start',
        sortable: false,
        value: 'created',
      },
    ],
  }),
  computed: {
    ...mapState(['bookingSpaces']),
    resources() {
      return this.bookingSpaces.map(space => {
        return {
          ...space,
          created: new Date(space.created.seconds * 1000).toDateString(),
          provider_id: space.google_id || space.microsoft_id,
          isAssigned: space.turf_meta?.is_assigned || false,
        }
      });
    },
    sortOptions() {
      return this.headers.reduce((options, header) => {
        const { text, value } = header
        if (text && value) {
          options.push({ text, value })
        }
        return options
      }, [])
    },
  },
  methods: {
    setSortOption(name, value) {
      if (name === 'sortBy') {
        this.sortBy = value
      } else if (name === 'sortDesc') {
        this.sortDesc = !this.sortDesc
      }
    },
    gotoSpace(space) {
      // if resource is not paired to a space, there is no space.
      if (!space.turf_meta?.is_assigned) {
        return;
      }

      this.$router.push(`/space/${space.id}`);
    },
  }
};