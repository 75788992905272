<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h3 class="display-2 font-weight-bold">Calendars</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center justify-center"  xs="12" sm="12" md="12" lg="6">
        <v-row>
          <v-col xs="12" sm="12" md="12" lg="6">
            <v-text-field
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dark
              dense
              class="search"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" lg="6">
            <sorting-controls 
              :items="sortOptions" 
              :sortBy="sortBy" 
              :sortDesc="sortDesc" 
              @setSortOption="setSortOption" 
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dark
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :headers="tableHeaders"
          :items="resources"
          :items-per-page="10"
          :search="search"
          class="elevation-1 space-table"
          item-key="spaceId"
        >
          <template v-slot:no-data>
            <span class="white--text">There are no calendars to display.</span>
          </template>
          <template v-slot:item.isAssigned="{ item }">
            <router-link 
              v-if="item.isAssigned" 
              class="d-flex align-center ui-link"
              :to="`/space/${item.id}`"
            >
              <GreenCheck class="mr-3" /> Paired to {{ item.name }}
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GreenCheck from '../assets/green-check.svg'
import resourcesMixin from '../mixins/resources';

export default {
  name: 'SpacesComp',
  mixins: [resourcesMixin],
  components: {
    GreenCheck,
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headers,
        {
          text: 'Paired to Space',
          align: 'start',
          sortable: false,
          value: 'isAssigned',
        }
      ]
    },
  },
};
</script>

<style lang="scss" scoped>
.space-table {
  width: 100%;
  background-color: transparent !important;
}
.space-table > table > tr {
  font-size: 60px;
   background-color: none;
}
.left-align {
  padding-left: 50px;
  text-align: left;
}
.search-bar {
  display: flex;
  align-items: center;
  max-width: 70%;
}
.sort-button {
  margin-right: 15px;
}
.pointer {
  cursor: pointer;
}
.truncate {
  width: 45vw;
}
</style>
