<template>
  <v-container>
    <v-card dark flat color="rgb(0, 0, 0, 0)">
      <v-row>
        <v-col cols="12" class="d-flex">
          <turf-btn class="pl-2" @click="goBack" small dark>
            <v-icon dense dark>mdi-arrow-left</v-icon> Spaces
          </turf-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <!-- name edit -->
            <v-col cols="7">
              <div class="d-flex align-center">
                <input-inline-edit
                  placeholder="Enter space name..."
                  v-model="currentSpace.turf_meta.space_name"
                ></input-inline-edit>
              </div>
            </v-col>
            <!-- delete button -->
            <v-col cols="5" class="d-flex justify-end align-center">
              <turf-btn 
                @click="getSpace" 
                btn-large 
                text
                :disabled="!isFormDirty || loading"
                class="mr-2"
              >Cancel</turf-btn>
              <turf-btn 
                @click="saveSpace" 
                btn-large 
                :disabled="!isFormDirty || !isFormValid"
                :loading="loading"
                class="mr-7"
              >Save</turf-btn>
              <v-btn
                color="error"
                @click="deleteDialog = true"
                fab
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-8 pl-4">
        <!-- active flag -->
        <v-col md="2" sm="3" xs="12" class="text-left">
            <h3 class="mb-4">Active / Inactive</h3>
            <BinarySelect 
                outlined
                :true-value="true"
                :false-value="false"
                true-label="Active"
                false-label="Inactive"
                v-model="currentSpace.active"
                @change="handleActiveToggle"
            />
        </v-col>
        <!-- auto-accept flag -->
        <v-col md="2" sm="3" xs="12" class="text-left" v-if="currentSpace.space_type === 'room'">
          <h3 class="mb-4">Auto Accept</h3>
          <BinarySelect 
              outlined
              :true-value="true"
              :false-value="false"
              true-label="Allow Auto Accept"
              false-label="Don't Allow Auto Accept"
              v-model="currentSpace.is_auto_accept"
          />
        </v-col>
      </v-row>
      <v-row class="pl-4">
        <v-col sm="12">
          <v-card dark class="pa-4">
            <v-row class="pl-4">
              <v-col sm="12" class="text-left">
                <h2>Space Info</h2>
              </v-col>
            </v-row>
            <v-row class="pl-4">
              <v-col sm="12" md="6" lg="7">
                <v-row>
                  <!-- space type -->
                  <v-col sm="4" md="4" class="text-left">
                    <h3 class="mb-4">Space Type</h3>
                    <v-select 
                      outlined
                      :disabled="!isSpace"
                      :readonly="availableSpaceTypes.length < 2"
                      :items="availableSpaceTypes"
                      v-model="currentSpace.space_type"
                    />
                  </v-col>
                  <!-- calendar Id -->
                  <v-col sm="8" md="8" class="text-left" v-if="isSpace">
                    <h3 class="mb-4">Calendar</h3>
                    <v-select 
                      outlined
                      disabled
                      :items="[resourceName + ' - ' + (currentSpace.google_id || currentSpace.microsoft_id)]"
                      :value="resourceName + ' - ' + (currentSpace.google_id || currentSpace.microsoft_id)"
                    />
                  </v-col>
                </v-row>

                <v-row class="mt-n3">
                  <!-- building -->
                  <v-col sm="6" md="5" class="text-left">
                    <h3 class="mb-4">{{ turfMetaEditabled.building.label }}</h3>
                    <OpenSelect
                      :field-config="turfMetaEditabled.building"
                      :options="getFieldsOptions(turfMetaEditabled.building.key)"
                      v-model="currentSpace.turf_meta.building_name"
                      clearable
                    />
                  </v-col>
                  <!-- floor -->
                  <v-col sm="3" md="3" class="text-left">
                    <h3 class="mb-4">{{ turfMetaEditabled.floor.label }}</h3>
                    <OpenSelect
                      :field-config="turfMetaEditabled.floor"
                      :options="getFieldsOptions(turfMetaEditabled.floor.key)"
                      v-model="currentSpace.turf_meta.floor"
                      clearable
                    />
                  </v-col>
                  <!-- Zone -->
                  <v-col sm="3" md="4" class="text-left">
                    <h3 class="mb-4">{{ turfMetaEditabled.zone.label }}</h3>
                    <OpenSelect
                      :field-config="turfMetaEditabled.zone"
                      :options="getFieldsOptions(turfMetaEditabled.zone.key)"
                      v-model="currentSpace.turf_meta.zone"
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" class="mt-n8 text-left body-2">
                    <strong>Note</strong>: You can create new options for Building, Floor, and Zone by typing in the selection field.
                  </v-col>
                </v-row>
              </v-col>
              <!-- space description -->
              <v-col sm="12" md="6" lg="5" class="text-left">
                <h3>Space Description</h3>
                <v-textarea
                  solo
                  auto-grow
                  prepend-inner-icon="mdi-plus"
                  placeholder="Add a space description"
                  rows="5"
                  filled
                  hide-details
                  v-model="currentSpace.friendly_description"
                  class="mt-0"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pl-4" v-if="currentSpace.space_type !== 'check-in'">
        <v-col sm="12">
          <v-card dark class="pa-4">
            <v-row class="pl-4">
              <v-col sm="12" class="text-left">
                <h2>Map</h2>
              </v-col>
            </v-row>
            <v-row class="pl-4">
              <v-col sm="12" md="6" lg="7">
                <span class="d-flex flex-column">
                  <div>
                    <h3 class="text-left">
                      Upload an image of a map so users can locate this space.
                    </h3>
                    <p class="text-left body-2 mb-4">File size should be less than 2mb.</p>
                  </div>
                  <div class="d-flex align-center">
                    <v-file-input
                      dark
                      outlined
                      :placeholder="mapImageFile ? mapImageFile.name : 'No image selected'"
                      class="flex-grow-1"
                      ref="mapImageFile"
                      :error-messages="mapFileUploadError"
                      prepend-icon=""
                      v-model="mapImageFile"
                      truncate-length="40"
                    >
                      <template v-slot:prepend-inner>
                        <turf-btn small class="mr-2 mt-n1" @click="handleClickUploadImage">
                          Choose File
                        </turf-btn>
                      </template>
                    </v-file-input>
                    <turf-btn 
                      height="45px"
                      class="mb-8 ml-4"
                      btnLarge
                      :loading="uploadingImage"
                      :disabled="!mapImageFile"
                      @click="onMapImageChange"
                    >Upload</turf-btn>
                  </div>
                </span>
              </v-col>
              <!-- map image -->
              <v-col sm="12" md="6" lg="5">
                <div class="map-location-image-container d-flex flex-column align-center justify-center mb-3 pa-2">
                  <!-- map image delete button -->
                  <v-tooltip bottom v-if="currentSpace.location_map_image_url" >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        @click="deleteImageDialog = true"
                        x-small
                        fab
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete image</span>
                  </v-tooltip>
                  <img v-if="currentSpace.location_map_image_url" :src="currentSpace.location_map_image_url" alt="Loading image">
                  <div v-else>No image uploaded</div>
                  <div 
                    class="text-center body-2 mt-1 mb-n1" 
                    v-if="currentSpace.location_map_image_filename && currentSpace.location_map_image_url"
                    v-text="currentSpace.location_map_image_filename"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pl-4" v-if="currentSpace.space_type === 'desk' && deskBookingBetaV2Enabled /* TODO: deskBookingBetaV2 feature flag */">
        <v-col sm="12">
          <v-card dark class="pa-4">
            <v-row class="pl-4 pr-4">
              <v-col sm="12" class="text-left">
                <h2>Desk booking QR code</h2>
              </v-col>
            </v-row>
            <v-row class="pl-4">
              <v-col>
                  <div class="text-left body-2 mt-n5 mb-6">
                      Download and print a QR code that users can scan with the camera of their mobile device to lunch the turf app and book this desk.
                  </div>
                  <SpaceQr :space-id="currentSpace.id" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pl-4">
        <!-- displays list -->
        <v-col sm="12">
          <v-card dark>
            <v-card-title class="d-flex justify-space-between">
              <h2 class="headline font-weight-bold">Displays</h2>
              <div class="search-bar">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on }">
                    <turf-btn :disabled="!currentSpace.active" v-on="on" dark dense class="sort-button"
                      ><v-icon>mdi-plus</v-icon> Add Display</turf-btn
                    >
                  </template>

                  <v-card dark>
                    <v-card-title dark class="headline" primary-title>
                      Available Displays
                    </v-card-title>

                    <v-data-table
                      dark
                      show-select
                      v-model="selected"
                      hide-default-header
                      :headers="headers"
                      item-key="id"
                      :items="getAvailableDisplays"
                      class="available-devices"
                    ></v-data-table>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <turf-btn
                        class="mr-2"
                        color="primary"
                        text
                        @click="handleAddDisplay"
                      >
                        <v-icon>mdi-plus</v-icon> Add Display
                      </turf-btn>
                      <turf-btn color="primary" text @click="dialog = false">
                        Cancel
                      </turf-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-card-title>
            <v-data-table
              dark
              hide-default-header
              :headers="headers"
              :items="displays"
              :items-per-page="10"
              class="elevation-1"
              no-data-text="No Displays assigned to this space"
            >
              <template v-slot:item="{ item }">
                <tr @click="handleAssignedDeviceClick(item)" class="pointer">
                  <td class="title font-weight-medium text-start">
                    {{ item.displayId }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <!-- Remove Display Confirmation -->
      <v-dialog v-model="confirmDeleteDialog" width="500">
        <v-card dark class="pa-4">
          <v-card-title dark class="headline" primary-title>
            Confirm Unassign Display
          </v-card-title>

          <v-card-text>
            You will be unassigning
            <strong>{{ selectedDisplay.displayId }}</strong> from
            <strong>{{ spaceName }}</strong>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <turf-btn class="mr-2" color="error" @click="handleConfirmUnassign">
              Yes, unassign display
            </turf-btn>
            <turf-btn dark @click="confirmDeleteDialog = false">
              No, keep display
            </turf-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Deactive Space Confirmation -->
      <v-dialog v-model="confirmDeactivateDialog" width="700" persistent>
        <v-card dark class="px-6 pb-6 pt-2">
          <v-row>
            <v-col cols="1">
              <v-icon x-large color="error" class="pt-3"
                >mdi-alert-rhombus-outline</v-icon
              >
            </v-col>
            <v-col cols="11">
              <v-card-title dark class="headline" primary-title>
                Are you sure you want to deactivate this space?
              </v-card-title>

              <v-card-text>
                You will not be able to schedule any events for this space and all
                associated displays will be unassigned.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <turf-btn
                  class="mr-2 pa-3"
                  color="error"
                  @click="handleConfirmDeactivate(true)"
                >
                  Yes, Deactivate This Space
                </turf-btn>
                <turf-btn class="pa-3" dark @click="handleConfirmDeactivate(false)">
                  No, Keep This Space Active
                </turf-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <!-- Delete Space Confirmation dialog -->
      <v-dialog
        persistent
        v-model="deleteDialog"
        max-width="800"
      >
        <v-card dark class="pa-8 pt-3">
          <v-card-title>
            <h2 class="mb-3">Are you sure you want to delete this space?</h2>
          </v-card-title>
          <v-card-text>
            You will permanently delete <b>{{spaceName}}</b>. This will also unassign all the displays assigned to this space.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="deleteDialog = false, spaceToDelete = null"
            >
              Cancel
            </v-btn>
            <turf-btn
              color="error"
              @click="onDeleteSpace"
            >
              Delete
            </turf-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Space Image Confirmation dialog -->
      <v-dialog
        persistent
        v-model="deleteImageDialog"
        max-width="800"
      >
        <v-card dark class="pa-8 pt-3">
          <v-card-title>
            <h2 class="mb-3">Are you sure you want to delete the map?</h2>
          </v-card-title>
          <v-card-text>
            You will permanently delete image Map for this space. 
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="deleteImageDialog = false"
            >
              Cancel
            </v-btn>
            <turf-btn
              color="error"
              @click="onDeleteSpaceMapImage"
            >
              Delete Map
            </turf-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { bulkdUnassignDevices } from '@/utils/firestore'
import InputInlineEdit from '@/components/InputInlineEdit.vue';
import BinarySelect from '../components/BinarySelect.vue';
import OpenSelect from "../components/OpenSelect.vue";
import spaceMetaMixin from '../mixins/spaceMetaEdit';
import turfCore from '@skykit-dev/skykit-turf-core';
import { getSpaceTypesByType } from "../utils/spaces.util";
import SpaceQr from '@/components/SpaceQr.vue';
import featureFlags from '@/mixins/featureFlags';
import checkinUtils from '@skykit-dev/skykit-turf-core/utils/checkin.util';

export default {
  name: 'SpaceDetail',
  components: {
    InputInlineEdit,
    BinarySelect,
    OpenSelect,
    SpaceQr,
  },
  mixins: [spaceMetaMixin, featureFlags],
  data: () => ({
    mapImageFile: null,
    currentSpace: {},
    selectedDisplay: {},
    selected: [],
    headers: [
      {
        text: 'Device',
        align: 'start',
        sortable: true,
        value: 'displayId'
      }
    ],
    confirmDeleteDialog: false,
    confirmDeactivateDialog: false,
    deleteDeviceId: '',
    dialog: false,
    rules: [v => !!v || 'Space Name Cannot Be Empty'],
    deleteDialog: false,
    mapFileUploadError: '',
    uploadingImage: false,
    deleteImageDialog: false,
    loading: false,
  }),
  computed: {
    ...mapGetters([
      'getAvailableDisplays',
      'getCustomerId',
      'getCustomerData',
      'getSpaceById'
    ]),
    spaceTypes() {
      return getSpaceTypesByType(this.currentSpace.space_type);
    },
    // return true if the space is tied to a calendar resource or false if is a checkin location
    isSpace() {
      return this.currentSpace.space_type !== 'check-in';
    },
    availableSpaceTypes() {
      return this.isSpace 
        ? this.spaceTypes.filter(s => s.value !== 'check-in')
        : this.spaceTypes.filter(s => s.value === 'check-in')
    },
    displays() {
      return this.$store.getters.getDisplaysBySpaceId(this.$route.params.id);
    },
    spaceName() {
      return this.currentSpace.turf_meta?.space_name 
        || this.currentSpace.name 
        || this.currentSpace.friendly_name;
    },
    resourceName() {
        return this.currentSpace.name || this.currentSpace.display_name || this.currentSpace.friendly_name;
    },
    isFormValid() {
      return !!get(this.currentSpace, 'turf_meta.space_name');
    },
    isFormDirty() {
      if (!isEqual(this.currentSpace, this.getSpaceById(this.spaceId))) {
        return true;
      }
      return false;
    },
  },
  created: function() {
    this.spaceId = this.$route.params.id;
    this.getSpace();
  },
  methods: {
    isEqual,
    getSpace() {
       this.currentSpace = cloneDeep(this.getSpaceById(this.spaceId));
    },
    /**
     * @description Determines which app to use. Turf kiosk relies on this value to switch between apps
     * @param {string} spaceType the space type
     * @returns {string} the app type 
     */
    getAppType(spaceType) {
      if (spaceType === 'check-in') {
        return 'check-in';
      }
      if (spaceType === 'desk' || spaceType === 'room') {
        return 'meeting-manager';
      }
      return '';
    },
    handleAddDisplay() {
      this.selected.forEach(async display => {
        await firebase
          .firestore()
          .collection('customers')
          .doc(this.getCustomerId)
          .collection('displays')
          .doc(display.id)
          .update({
            space_id: this.spaceId,
            appType: this.getAppType(this.currentSpace.space_type)
          });

          this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
            message: `Added display (${display.id}) to space (${this.getSpaceById(this.spaceId).name} | ${this.spaceId})`,
            createdAt: new Date()
          })
      });
      this.dialog = false;
    },
    handleAssignedDeviceClick(display) {
      this.selectedDisplay = display;
      this.confirmDeleteDialog = true;
    },
    handleConfirmUnassign() {
      firebase
        .firestore()
        .collection('customers')
        .doc(this.getCustomerId)
        .collection('displays')
        .doc(this.selectedDisplay.id)
        .update({ space_id: '', appType: '' });
      
      this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
        message: `Unassigned display (${this.selectedDisplay.id}) from space (${this.getSpaceById(this.spaceId).name} | ${this.spaceId})`,
        createdAt: new Date()
      })

      this.selectedDisplay = {};
      this.confirmDeleteDialog = false;
    },
    onMapImageChange() {
      if (this.mapImageFile) {
        this.uploadingImage = true;
        turfCore.customer.space.setMapImage(this.spaceId, this.mapImageFile)
          .then(({ imageUrl }) => {
            console.log('Image uploaded to ' + imageUrl);
            this.currentSpace.location_map_image_url = imageUrl;
            this.getSpace();
            this.mapImageFile = null;
          })
          .catch(error => {
            this.mapFileUploadError = error.message;
          })
          .finally(() => {
            this.uploadingImage = false;
          });
      }
    },
    onDeleteSpaceMapImage() {
      this.currentSpace.location_map_image_url = '';
      this.currentSpace.location_map_image_filename = '';
      turfCore.customer.space.deleteImageMap(this.spaceId);
      this.deleteImageDialog = false;
    },
    handleClickUploadImage() {
      const input = get(this, '$refs.mapImageFile.$refs.input', null)
      input?.click()
    },
    async saveSpace() {
      this.loading = true;
      const updatedData = {
        name: this.currentSpace.name,
        friendly_description: this.currentSpace.friendly_description,
        location_map_image_url: this.currentSpace.location_map_image_url || '',
        active: this.currentSpace.active,
        turf_meta: {
          building_name: get(this.currentSpace, 'turf_meta.building_name') || '',
          floor: get(this.currentSpace, 'turf_meta.floor') || '',
          zone: get(this.currentSpace, 'turf_meta.zone') || '',
          space_name: get(this.currentSpace, 'turf_meta.space_name') || '',
        }
      };

      try {
        if (this.currentSpace.space_type === 'check-in') {
          const locationId = get(this.currentSpace, 'id');

          updatedData.shortLink = await checkinUtils.getShortUrl({
              locationId,
              customerId: this.getCustomerId,
              contactEmail: get(this.getCustomerData, 'admin_contact_email') || '',
          }).catch(() => '');

        } else {
          updatedData.turf_meta.is_assigned = get(this.currentSpace, 'turf_meta.is_assigned') || false;
          updatedData.is_auto_accept = this.currentSpace.is_auto_accept;
          updatedData.space_type = this.currentSpace.space_type;
        }

        await turfCore.customer.space.update(this.spaceId, updatedData, this.currentSpace.space_type);
        this.getSpace();
      } catch(err) {
        console.error(err);
        this.$store.dispatch('TOAST_ERROR', { message: 'Unable to update this space' })
      } finally {
        this.loading = false;
      }
    },
    handleActiveToggle() {
      if (this.currentSpace.active) {
        this.confirmDeactivateDialog = true;
      } else {
        // Timeout prevents app from opening the confirmation on activate.
        setTimeout(async () => {
          const collectionName = this.currentSpace.space_type === 'check-in' ? 'checkin_locations' : 'rooms'
          try {
            await firebase
            .firestore()
            .collection('customers')
            .doc(this.getCustomerId)
            .collection(collectionName)
            .doc(this.spaceId)
            .update({ active: true });
            this.currentSpace.active = true

            this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
              message: `Activated space (${this.getSpaceById(this.spaceId).name} | ${this.spaceId})`,
              createdAt: new Date()
            })
          } catch (error) {
            console.error(error.message)
          }
        }, 200);
      }

      
    },
    async handleConfirmDeactivate(confirm) {
      if (confirm) {
        // Unassign all assigned displays
        const displayIds = this.displays.map(x => x.id)
        await bulkdUnassignDevices(displayIds, this.getCustomerId)

        const collectionName = this.currentSpace.space_type === 'check-in' ? 'checkin_locations' : 'rooms'
        await firebase
        .firestore()
        .collection('customers')
        .doc(this.getCustomerId)
        .collection(collectionName)
        .doc(this.spaceId)
        .update({ active: false });
        this.confirmDeactivateDialog = false;

        this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
          message: `Deactivated space (${this.getSpaceById(this.spaceId).name} | ${this.spaceId})`,
          createdAt: new Date()
        })
      } else {
        this.currentSpace.active = true;
        this.confirmDeactivateDialog = false;
      }

      this.spaceId = this.$route.params.id;
      this.currentSpace = { ...this.getSpaceById(this.spaceId) }
    },
    dismissNameChange() {
      this.currentSpace.friendly_name = this.getSpaceById(
        this.spaceId
      ).friendly_name;
    },
    async onDeleteSpace() {
      try {
        const spaceName = this.spaceName;

        if (this.currentSpace.space_type === 'check-in') {
          await turfCore.customer.space.deleteCheckinLocation(this.currentSpace.id);
        } else {
          await turfCore.customer.space.deleteSpace(this.currentSpace.id);
        }

        this.$store.dispatch('ADD_CUSTOMER_ACTIVITY', {
          message: `Deleted space (${this.getSpaceById(this.currentSpace.id).name} | ${this.currentSpace.id})`,
          createdAt: new Date()
        })

        this.$store.dispatch('TOAST_SUCCESS', {
          message: `${spaceName} has been deleted`
        });

        this.$router.go(-1);
      } catch(err) {
        this.$store.dispatch('TOAST_ERROR', {
          message: 'Unable to delete space'
        });
      } finally {
        this.deleteDialog = false;
      }
    },
    goBack() {
      this.$router.push({path: '/spaces'})
    }
  }
};
</script>

<style lang="scss">
.available-devices {
  width: 90%;
  margin-left: 5%;
}
.left-align {
  padding-left: 50px;
  text-align: left;
}
.search-bar {
  display: flex;
  align-items: baseline;
}
.sort-button {
  margin-right: 15px;
}
.short-height {
  height: 30px;
}
.pointer {
  cursor: pointer;
}
.map-location-image-container {
  background: rgba(0, 0, 0, .4);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.3);
  min-height: 200px;
  position: relative;

  .body-2 {
    color: rgba(255, 255, 255, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-btn {
    position: absolute;
    z-index: 2;
    top: -10px;
    right: -5px;
  }

  img {
    max-height: 300px;
    max-width: 100%;
  }

}
</style>
