import { getSpaceTypesByType as _getSpaceTypesByType } from '@skykit-dev/skykit-turf-core/utils/space.util'
import get from 'lodash/get';
import store from '../store/index';

export { getFieldUniqueValues, spaceMetadata } from '@skykit-dev/skykit-turf-core/utils/space.util'

export function getSpaceTypesByType(type) {
    const hasDeskBookingEnabled = get(store, 'getters.getCustomerData.settings.booking.desk.is_enable');
    const hasSpaceBookingEnabled = get(store, 'getters.getCustomerData.settings.booking.space.is_enable');
    const hasCheckinEnabled = get(store, 'getters.getCustomerData.settings.check_in.is_enable');

    const availableSpaceTypes = _getSpaceTypesByType(type);

    return availableSpaceTypes.filter(({ value: type }) =>
        (type === 'check-in' && hasCheckinEnabled) ||
        (type === 'room' && hasSpaceBookingEnabled) ||
        (type === 'desk' && hasDeskBookingEnabled)
    )
}
