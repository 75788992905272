import get from "lodash.get";
import axios from "axios";

const permissionsUrl = () => {
  switch (process.env.VUE_APP_ENV) {
    case "integration":
      return "https://api.skykit-int.app/auth/v2/graphql";
    case "qa":
      return "https://api.skykit-qa.app/auth/v2/graphql";
    case "stage":
      return "https://api.skykit-stage.app/auth/v2/graphql";
    case "production":
      return "https://api.skykit.app/auth/v2/graphql";
    default:
      return "https://api.skykit-int.app/auth/v2/graphql";
  }
};

const getIdentity = async (authToken, tenantLid) => {
  try {
    const gqlEndpoint = permissionsUrl();
    const response = await axios({
      url: gqlEndpoint,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        query: `
          query Identity($tenantLid: ID!) {
            identity {
              permissions(tenantKey: {lid: $tenantLid}) {
                lid
              }
              user {
                id
                permits(after: null, first: 10) { # added a quick check if user has one or more tenants
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                  }
                  edges {
                    cursor
                    node {
                      lid
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          tenantLid,
        },
      },
    });

    return get(response, 'data.data');
  } catch (err) {
    console.error(`Permission service failed, ${err.message}`);
    throw new Error(err.message);
  }
};

export { getIdentity };
